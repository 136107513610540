namespace eh {
  
  export class CookieDisclaimer {

    static init($base: JQuery<HTMLElement>): void {

      const _FOOTER_SELECTOR: string = '.eh-footer';
      const $component: JQuery<HTMLElement> = $('.marker-cookie-disclaimer', $base);
      var cookieVal = eh.getCookie('euhcookiedisclaimer');
      if(cookieVal == '1') {
        eh.deleteCookie('euhcookiedisclaimer');
      }
      const cookieFound = eh.getCookie('euhcookiedisclaimer') === '2';
      // 4693696
      var $consentMgmt = $('.eh-navigation, .eh-footer', $base);
      $('.marker-disclaimer-close', $consentMgmt).on('click', ($event) => {
        if (!cookieFound) {
          eh.setGlobalCookie('euhcookiedisclaimer', '2', 365, true, 'None');
          $component.replaceWith('');
          const $rep = $('.js-disclaimer-close', $component).not('.js-trigger-consent');
          $rep.addClass('marker-disclaimer-close');
          eh.Tracking.injectTrackingEvent({}, $rep);
          $rep.removeClass('marker-disclaimer-close');
        }
        if ($($event.currentTarget).hasClass('js-trigger-consent')) {
          window.utag?.gdpr?.showConsentPreferences();
        }
      });
      if (cookieFound) {
        $component.replaceWith('');
        return;
      }
      $('.js-disclaimer-close', $component).on('click', (ev) => {
        eh.setGlobalCookie('euhcookiedisclaimer', '2', 365, true, 'None');
        $component.replaceWith('');
        if ($(ev.currentTarget).hasClass('js-trigger-consent')) {
          window.utag?.gdpr?.showConsentPreferences();
        }
        ev.preventDefault();
        //@ts-ignore
        if (typeof STICKY_EVENTS !== 'undefined' && STICKY_EVENTS.DOM_MUTATION) {
          //@ts-ignore
          $(':root').trigger(STICKY_EVENTS.DOM_MUTATION);
        }
      });
      $component.selfOrFind('.disabled').removeClass('disabled');
    }

  }

}
