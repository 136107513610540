namespace eh {

    class EH_LOGIN_BUTTON_COMMANDS {
        static LOGIN: string = 'login';
        static LOGOUT: string = 'logout';
    }

    export class EhApi implements EhExternalApi {

        private static _instance: EhApi;

        private constructor() {}

        public static getInstance(): EhApi {
            if (!EhApi._instance) {
                EhApi._instance = new EhApi();
            }
            return EhApi._instance;
        }

        public setLogin(name?: string): void {
            $('.eh-login-button').ehLoginButton(EH_LOGIN_BUTTON_COMMANDS.LOGIN, name);
        }

        public clearLogin(): void {
            $('.eh-login-button').ehLoginButton(EH_LOGIN_BUTTON_COMMANDS.LOGOUT);
        }

        public getScrollRoot(): JQuery<Element> {
            return eh.ScrollPage.getScrollRoot();
        }

        public myDocumentsReady(): void {
            eh.MyDocumentsProxy.getInstance().markReady();
        }

        public nebpReady(): void {
            eh.NebpProxy.getInstance().markReady();
        }

        // public productGuideReady(): void {
        //     eh.ProductGuideProxy.getInstance().markReady();
        // }

    }

    export interface EhExternalApi {
        setLogin: (name?: string) => void;
        clearLogin: () => void;
        getScrollRoot: () => JQuery<Element>;
        myDocumentsReady: () => void;
        nebpReady: () => void;
        //productGuideReady: () => void;
    }

}