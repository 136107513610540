namespace eh {
  
  export class NebpProxy {
    
    private static readonly API_CALL_TIMEOUT_MS = 20_000;
    private static readonly logger = log.getLogger('eh.NebpProxy');
    private static readonly _instance: NebpProxy = new NebpProxy();
    private readonly awaitingReady: eh.DeferredPromise<any>[] = [];
    private stateReady = false;
    
    private constructor() {
    }
    
    public static getInstance(): NebpProxy {
      return NebpProxy._instance;
    }
    
    public isReady() {
      return this.stateReady;
    }
    
    public markReady() {
      this.stateReady = true;
      this.awaitingReady
          .splice(0, this.awaitingReady.length)
          .forEach(p => p.execute());
      return this;
    }

    private wrapCall<CallResult>(boundFnSupplier: () => () => Promise<CallResult>): Promise<CallResult> {
      if (!this.isReady()) {
        const deferredPromise = new eh.DeferredPromise<CallResult>(
            function() {
              try {
                const boundFn = boundFnSupplier();
                NebpProxy.logger.debug('resolving deferred promise', boundFnSupplier);
                return this.resolve(boundFn());
              }
              catch (e) {
                NebpProxy.logger.warn('rejecting deferred promise', e, boundFnSupplier);
                return this.reject(e);
              }
            }
        );
        this.awaitingReady.push(deferredPromise);
        return Promise.race<CallResult>([
          deferredPromise,
          new Promise<CallResult>((_resolve, reject) => 
            setTimeout(reject, NebpProxy.API_CALL_TIMEOUT_MS, 'nebpApi timed out'))
        ]);
      }
      try {
        const boundFn = boundFnSupplier();
        NebpProxy.logger.debug('executing promise', boundFn);
        return boundFn();
      }
      catch (e) {
        NebpProxy.logger.warn('rejecting promise', e, boundFnSupplier);
        return Promise.reject(e);
      }
    }
    
    public getToken(): Promise<JWToken> {
      return this.wrapCall(() => window.nebpApi.getToken.bind(window.nebpApi));
    }
  
    public isLoggedIn(): Promise<boolean> {
      return this.wrapCall(() => window.nebpApi.isLoggedIn.bind(window.nebpApi));
    }
    
    public isLoggedInUserRegistrationStateActivationInProcess(): Promise<boolean> {
      return this.wrapCall(() => window.nebpApi.isLoggedInUserRegistrationStateActivationInProcess.bind(window.nebpApi));
    }
    
    public isLoggedInUserRegistrationStateInformationMissing(): Promise<boolean> {
      return this.wrapCall(() => window.nebpApi.isLoggedInUserRegistrationStateInformationMissing.bind(window.nebpApi));
    }
    
    public getRegistrationState(): Promise<RegistrationState> {
      return this.isLoggedIn()
        .then(isLoggedIn => {
          if (isLoggedIn) {
            return this.isLoggedInUserRegistrationStateInformationMissing()
              .then(isInformationMissing => {
                if (isInformationMissing) {
                  return RegistrationState.INFORMATION_MISSING;
                }
                else {
                  return this.isLoggedInUserRegistrationStateActivationInProcess()
                    .then(isActivationInProgress => {
                      return isActivationInProgress ? RegistrationState.IN_PROGRESS : RegistrationState.FINISHED;
                    })
                  ;
                }
              })
            ;
          }
          else {
            return RegistrationState.UNKNOWN;
          }
        })
        .catch((err) => {
          NebpProxy.logger.error('getRegistrationState/catch', err);
          return RegistrationState.UNKNOWN;
        })
      ;
    }
    
  }
  
  export type JWToken = string | null;
  
  export enum RegistrationState {
    UNKNOWN,
    IN_PROGRESS,
    INFORMATION_MISSING,
    FINISHED
  }
  
}

interface Window {
  nebpApi: {
    getToken: () => Promise<eh.JWToken>;
    isLoggedIn: () => Promise<boolean>;
    isLoggedInUserRegistrationStateActivationInProcess: () => Promise<boolean>;
    isLoggedInUserRegistrationStateInformationMissing: () => Promise<boolean>;
  }
}