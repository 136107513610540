namespace eh {

  export class ScrollPage {
    public static mobileHeaderHight = 60;
    public static lastScrollPosY: number;

    private static scrollRoot: Element | null;
    private static setupScrollRoot: () => Element | null = (): Element | null => {
      // requires polyfill scrolling-element.js
      return document.scrollingElement;
    };
    private static scrollListeners: {(): void}[] = [];
    private static isWatching: boolean = false;

    static getScrollRoot(): JQuery<Element> {
      if (!ScrollPage.scrollRoot) {
        ScrollPage.scrollRoot = ScrollPage.setupScrollRoot();
      }
      return $(ScrollPage.scrollRoot as Element);
    }
    
    static getScrollPosition(): number {
      return ScrollPage.getScrollRoot()?.scrollTop() || 0;
    }

    static hasScrollChanged(): boolean {
      return ScrollPage.getScrollPosition() !== ScrollPage.lastScrollPosY;
    }

    static scrollTo(position: number) {
      ScrollPage.getScrollRoot()?.scrollTop(position);
    }

    static scrollLeft(position: number) {
      ScrollPage.getScrollRoot()?.scrollLeft(position);
    }

    static scrollToAnimated(position: number, callback?: () => void) {
      ScrollPage.lastScrollPosY = ScrollPage.getScrollPosition();
      ScrollPage.getScrollRoot()?.animate({
        scrollTop: position
      },400, 'easeInSine', callback);
    }

    static scrollToLastPosAnimated(callback?: () => void) {
      ScrollPage.getScrollRoot()?.animate({
        scrollTop: ScrollPage.lastScrollPosY
      },400, 'easeInSine', callback);
    }

    static onScrollChange(): void {
      ScrollPage.scrollListeners.forEach((_l: () => void): void => _l());
    }

    static registerChangeListener(listener: () => void): void {
      if (!ScrollPage.isWatching) {
        ScrollPage.watch();
        ScrollPage.isWatching = true;
      }
      if (ScrollPage.scrollListeners.indexOf(listener) === -1) {
        ScrollPage.scrollListeners.push(listener);
      }
    }

    static unregisterChangeListener(listener: () => void): void {
      if (ScrollPage.scrollListeners.indexOf(listener) !== -1) {
        ScrollPage.scrollListeners.splice(ScrollPage.scrollListeners.indexOf(listener), 1);
      }
      if (ScrollPage.isWatching && !ScrollPage.scrollListeners.length) {
        ScrollPage.unwatch();
        ScrollPage.isWatching = false;
      }
    }

    static watch(): void {
      const eventParams: any = eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
      document.addEventListener('scroll', ScrollPage.onScrollChange, eventParams);
    }

    static unwatch(): void {
      document.removeEventListener('scroll', ScrollPage.onScrollChange);
    }

      /**
       * crossbrowser convinience method to prevent
       * scrolling the page while it is covered by window-sized covering
       * elements like flyouts | overlays | lightboxes...
       *
       * @param enabled
       *
       * @param bodyFixClassName - optional className we like to apply for sealing the page in the background.
       *                           default is 'eh-no-scroll--lt-large eh-full-height--lt-large'.
       *                           by convention <code>bodyFixClassName</code> has the following pattern:
       *
       *                           /\w\s(\w*)/ where
       *                                        - first \w: disablement of page scrolling e.g. eh-no-scroll
       *                                        - second \w*: optional classes to support ios scrolling e.g. eh-full-height
       *
       * @param transitionEl     - optional element we need to wait for its transition to be finished.
       */
    static setScrollEnabled(
        enabled: boolean,
        bodyFixClassName?: string,
        transitionEl?: JQuery<HTMLElement>
      ): void {
      const classNameToSealPage: string = bodyFixClassName ?? 'eh-no-scroll--lt-large eh-full-height--lt-large';
      const setEnabled: (enabled: boolean) => void = (_e: boolean): void => {
        /*
        // locking height of html and body is needed to support mobile safari
        const browser = window.browserDetect();
        const mobileSafari: boolean = browser.name === 'safari' && browser.mobile;
        if (mobileSafari) {
          $('html, body').toggleClass(classNameToSealPage, !_e);
        } else {
          ScrollPage.getScrollRoot().toggleClass(classNameToSealPage.split(' ')[0], !_e);
        }
        if (_e && mobileSafari) {
          ScrollPage.scrollTo(ScrollPage.lastScrollPosY);
        }
        */

        ScrollPage.getScrollRoot().toggleClass(classNameToSealPage.split(' ')[0], !_e);
      };
      if (transitionEl) {
        transitionEl.one('transitionend', (): void => {
          setEnabled(enabled);
        });
      } else {
        setEnabled(enabled);
      }
    }

  }


}
