namespace eh {
  
  export class SearchFiltersLegacy {
    
    private static readonly classFilterActive = 'eh-filter-active';
    private static readonly SELECTOR__SEARCH_FILTERS_LEGACY = '.eh-search-filter.is-legacy';
    
    static init($base: JQuery<HTMLElement>, isSnippetReq:boolean): void {
      $base = $base.selfOrFind(SearchFiltersLegacy.SELECTOR__SEARCH_FILTERS_LEGACY);
      if ($base.length === 0) {
        return;
      }
      SearchFiltersLegacy.initFilterSelects($base, true);

      if(!isSnippetReq) {
        $base.on(cs.Snippet.EventIdPreReplace, ($event: cs.SnippetEventPreReplace) => {
            const $select = $('select.marker-filter-select', $event.oldElement);
            if ($select.closest(SearchFiltersLegacy.SELECTOR__SEARCH_FILTERS_LEGACY).length === 0) {
              return;
            }
            $select.select2('destroy');

            let oldReset = $('.eh-search-bar--clear-icon', $event.oldElement).first();
            if(oldReset && oldReset.data('used') === 'yes') {
              $('.eh-search-bar--clear-icon', $event.newElement).data('used', 'yes');
            }
            eh.ScrollPage.setScrollEnabled(true, 'eh-no-scroll--lt-medium eh-full-height--lt-medium');
        });
      }

      /*
      @FIXME: reflow issues should be obsolete since removal of page-height-restrictions (ios address-bar autohide)
      const userAgent = window.navigator.userAgent;
      let isMobileSafari = (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) !== null;
      if(isMobileSafari && isSnippetReq && !eh.ScrollPage.getScrollRoot().hasClass('eh-no-scroll')) {
        eh.ScrollPage.getScrollRoot().addClass('eh-no-scroll');
        window.requestAnimationFrame(() => {
          eh.ScrollPage.getScrollRoot().removeClass('eh-no-scroll');
        });
      }
      */

      const searchBar = $('.filterSidebar .eh-search-bar--inputs', $base).first();
      if (searchBar.length !== 0) {
        const input = searchBar.find('input[type="text"]');
        const $clearBt = searchBar.find('.eh-search-bar--clear-icon');
        if (input.length !== 0) {
          if (!input.val()) {
            $clearBt.hide();
            if($clearBt.data('used') === 'yes') {
              $clearBt.data('used', '');
              window.requestAnimationFrame(() => {
                input.trigger('focus');
              });
            }
          }
          input.on('input', ($event: JQuery.TriggeredEvent) => {
            if (!input.val()) {
              $clearBt.hide();
            } else {
              $clearBt.show();
            }
          });
          if (!input.val()) {
            $clearBt.hide();
          }
          $clearBt.on('click', ($event: JQuery.TriggeredEvent) => {
            $event.preventDefault();
            $clearBt.data('used', 'yes');
            input.val('');
            input.trigger("change");
            input.trigger('focus');
          });
        }
      }

    }

    private static initSelectTwo($select: JQuery<HTMLElement>) {
      const opts: Select2.Options = {
        allowClear: !$select.data('disallowResultClear'),
        theme: 'endress',
        width: '100%',
        minimumResultsForSearch: $select.data('minResultsForSearch') || 6,
        closeOnSelect: false,
        placeholder: $select.data('placeholder') || 'PLACEHOLDER'
      };
  
      if ($select.prop('multiple')) {
        $.fn.select2.amd.require(
            ['cs.FilterMultiSelectResultDecorator', 'cs.FilterMultiSelectionDecorator', 'cs.FilterMultiSelectCloseOnSelectDecorator', 'cs.FilterMultiSelectHierarchicalDecorator',
              'cs.NoOpSelectionAdapter', 
              'select2/utils', 'select2/results', 'select2/selection/multiple', 'select2/selection/eventRelay', 'select2/selection/placeholder',
              'select2/selection/allowClear', 'select2/dropdown', 'select2/dropdown/attachContainer', 'select2/dropdown/search', 'select2/dropdown/hidePlaceholder',
              'select2/dropdown/minimumResultsForSearch'
            ],
            (FilterMultiSelectResultDecorator: object, FilterMultiSelectionDecorator: object, FilterMultiSelectCloseOnSelectDecorator: object, FilterMultiSelectHierarchicalDecorator: object,
            NoOpSelectionAdapter: object, 
            S2Utils: S2.Utils, S2Results: object, S2MultipleSelection: object, S2EventRelay: object, S2Placeholder: object,
            S2AllowClear: object, S2Dropdown: object, S2AttachContainer: object, S2DropdownSearch: object, S2HidePlaceholder: object,
            S2MinimumResultsForSearch: object) => {
              
              const ResultAdapter = S2Utils.Decorate(S2Results, FilterMultiSelectResultDecorator);
              
              const SelectionAdapter = S2Utils.Decorate(
                NoOpSelectionAdapter,
                S2EventRelay
              );
          
              let DropdownAdapter = S2Utils.Decorate(
                  S2Utils.Decorate(
                      S2Utils.Decorate(
                          //S2Utils.Decorate(
                              //S2Utils.Decorate(
                                  S2Utils.Decorate(
                                    S2Dropdown,
                                    S2DropdownSearch
                                  ),
                               //   DropDownSearchPromptDecorator // see tracker#4146465 (IE11) before enable
                              //),
                          //    S2HidePlaceholder
                          //),
                          S2MinimumResultsForSearch
                      ),
                      FilterMultiSelectCloseOnSelectDecorator
                  ),
                  S2AttachContainer
              );

              if ($select.data('isHierarchical')) {
                opts.minimumResultsForSearch = Infinity;
                DropdownAdapter = S2Utils.Decorate(DropdownAdapter, FilterMultiSelectHierarchicalDecorator);
              }
          
              $.extend(opts, {
                closeOnSelect: false,
                selectionAdapter: SelectionAdapter,
                dropdownAdapter: DropdownAdapter,
                resultsAdapter: ResultAdapter,
                templateResult: function (result: Select2.OptionData): JQuery<Element> | string | null {
                  if (result.id === undefined) {
                    return '<span class="label clear">' + result.text + '</span>'; // FIXME for what is this?
                  } else if (result.id !== '') {
                    return $(
                        (($select.data('isHierarchical') && $(result.element).data('hasChild')) ? '<span class="caret">▼</span>' : '')
                        + '<div class="eh-product-filter--item-content eh-layout--h-box eh-layout--justify-s-between eh-layout--align-s-start eh-p-r">\n'
                        +   '<div class="eh-layout--h-box eh-layout--align-s-start">'
                        +     '<label class="eh-product-filter--filter-checkbox eh-checkbox eh-checkbox--checkbox">'
                        +       `<input type="checkbox" value="${ result.id }" id="${ result.element.id }"${ result.selected ? ' checked="checked"' : '' }>`
                        +       '<div class="eh-checkbox--indicator"></div>'
                        +       `${ result.text }`
                        +     '</label>'
                        +   '</div>'
                        +   ($(result.element).data('count') ? `<label class="eh-label eh-p-v-s-2
                      eh-p-r-xxlg-2--small eh-p-r-xxlg-2--x-small">${ $(result.element).data('count') }</label>` : '')
                        + '</div>'
                    );
                  }
                  return null;
                },
                templateSelection: function (selection: { text: string } | undefined): string {
                  return selection ? selection.text : 'templateSelection(data.text)';
                }
              });
              $select.select2(opts);
              if($select.data('select2').$container && $select.data('filterPlaceholder')) {
                $('.select2-search__field', $select.data('select2').$container).attr('placeholder', $select.data('filterPlaceholder'));
              }
          });
        }
        else {
          $.fn.select2.amd.require(
            ['cs.FilterMultiSelectResultDecorator', 'cs.FilterMultiSelectionDecorator', 'cs.FilterMultiSelectCloseOnSelectDecorator', 'cs.FilterMultiSelectHierarchicalDecorator',
              'cs.NoOpSelectionAdapter', 'cs.DropDownSearchPromptDecorator',
              'select2/utils', 'select2/results', 'select2/selection/multiple', 'select2/selection/eventRelay', 'select2/selection/placeholder',
              'select2/selection/allowClear', 'select2/dropdown', 'select2/dropdown/attachContainer', 'select2/dropdown/search', 'select2/dropdown/hidePlaceholder',
              'select2/dropdown/minimumResultsForSearch'
            ],
            (FilterMultiSelectResultDecorator: object, FilterMultiSelectionDecorator: object, FilterMultiSelectCloseOnSelectDecorator: object, FilterMultiSelectHierarchicalDecorator: object,
            NoOpSelectionAdapter: object, DropDownSearchPromptDecorator: object,
            S2Utils: S2.Utils, S2Results: object, S2MultipleSelection: object, S2EventRelay: object, S2Placeholder: object,
            S2AllowClear: object, S2Dropdown: object, S2AttachContainer: object, S2DropdownSearch: object, S2HidePlaceholder: object, S2MinimumResultsForSearch: object) => {

              let DropdownAdapter = S2Utils.Decorate(
                S2Utils.Decorate(
                  S2Utils.Decorate(
                    S2Utils.Decorate(
                      S2Utils.Decorate(
                        S2Dropdown,
                        S2DropdownSearch
                      ),
                      DropDownSearchPromptDecorator
                    ),
                    S2MinimumResultsForSearch
                  ),
                  FilterMultiSelectCloseOnSelectDecorator
                ),
                S2AttachContainer
              );


              $.extend(opts, {
                closeOnSelect: true,
                dropdownAdapter: DropdownAdapter,
              });
              $select.select2(opts);
              if($select.data('select2').$container && $select.data('filterPlaceholder')) {
                $('.select2-search__field', $select.data('select2').$container).attr('placeholder', $select.data('filterPlaceholder'));
              }
            });
        }
    }
    private static initFilterSelects($base: JQuery<HTMLElement>, isInitial: boolean) {
      $('select.marker-filter-select', $base).each((_index, elem) => {
        this.initSelectTwo($(elem));
      })
      .on('select2:opening', ($event) => {
      })
      .on('select2:open', ($event) => {
        $('.select2-search__field').prop('focus', false);
      })
      .on('select2:closing', ($event) => {
      })
      .on('select2:close', ($event) => {
      })
      .on('select2:csReinit', ($event) => {
        const select = $($event.target);
        if(select && select.hasClass('marker-filter-select')) {
          if(select.hasClass('select2-hidden-accessible')) {
            select.select2('destroy');
          }
          if(!select.hasClass('eh--hide')) {
            this.initSelectTwo(select); 
          }
        }
      });
    
      $('.tag-remove', $base).on('click', ($event) => {
        $event.preventDefault();
        const $el = $($event.currentTarget),
            isSelect = $el.hasClass('rem-select'),
            filterId = $el.data('id');
        let $filter = $('[id="filter.' + filterId + (isSelect ? '[]' : '') + '"]', $base);
        
        if ($filter.length === 0) {
          $filter = $('[id="filter1.' + filterId + (isSelect ? '[]' : '') + '"]', $base);
        }
        if (isSelect) {
          $('option[value="' + $el.data('val') + '"]', $filter).prop('selected', false);
        }
        else {
          $filter.val('');
        }
        $filter.trigger('change');
        eh.LoadingIndicator.showLoadingByEv($event);
      });

      
      $('.trigger-filter-display', $base).on('click', ($event) => {
        $event.preventDefault();
        const filterControl: JQuery<HTMLElement> = $($event.currentTarget).closest('.marker-search-container').find('.eh-product-filter');
        const isOpen: boolean = filterControl.hasClass(SearchFiltersLegacy.classFilterActive);
        filterControl.toggleClass(SearchFiltersLegacy.classFilterActive, !isOpen);
        eh.ScrollPage.setScrollEnabled(isOpen, 'eh-no-scroll--lt-medium eh-full-height--lt-medium');
      });
      
      
      $('.eh-product-filter--group-content', $base).on('click', ($event) => {
        const $filter = $($event.currentTarget).closest('.eh-product-filter--group:not(.disabled)');
        if ($filter.length === 0) {
          return;
        }

        // inline boolean checkbox special case - no accordion effect
        const $checkbox = $($event.currentTarget).find('.marker-inline-checkbox:visible');
        if ($checkbox.length > 0) {
          $checkbox.prop("checked", !$checkbox.prop("checked"));
          $checkbox.trigger("change");
           return;
          // $checkbox.prop("checked", !$checkbox.prop("checked"));
          // cs.Snippet.ajaxSubmitForm($checkbox, $event);
          // window.setTimeout(() => {
            // SearchFiltersLegacy.makeActive($('.eh-product-filter--group:not(.disabled):first', $base));
          //}, 500);
          //return;
        }
        $event.preventDefault();

        if ($filter.hasClass('active')) {
          SearchFiltersLegacy.makeInActive($filter)
        }
        else {
          SearchFiltersLegacy.makeActive($filter);
        }
      });

        // inline boolean checkbox special case - no accordion effect
        $('.eh-product-filter--item-content .marker-inline-checkbox:visible', $base).on('click', ($event) => {
          const $checkbox = $($event.currentTarget).find('');
          if ($checkbox.length > 0) {
            $checkbox.prop("checked", !$checkbox.prop("checked"));
            $checkbox.trigger("change");
            return;
          }
        });

      
      if (isInitial) {
        window.setTimeout(() => {
          const toOpen = $('[data-restore-open]', $base);
          if (toOpen.length !== 0) {
            SearchFiltersLegacy.makeActive(toOpen.first().closest('.eh-product-filter--group'));
          } else {
            SearchFiltersLegacy.makeActive($('.eh-product-filter--group:not(.disabled):first', $base));
          }
        }, 200);
      }
    };
    
    private static makeActive($filter: JQuery<HTMLElement>) {
      if ($filter.find('.disabled').length > 0) {
        return;
      }
      $filter.toggleClass('active', true);
      $filter.find('select.marker-filter-select').select2('open');
      $filter.find(`.${eh.DatePicker.CLS_MARKER_DATEPICKER}`).show();
      $filter.find(`.${eh.DatePicker.CLS_MARKER_DATEPICKER_CONTROL}`).show();
  
      $filter.siblings('.active').each((_index, el) => {
        SearchFiltersLegacy.makeInActive($(el));
      });
    }
  
    private static makeInActive($filter: JQuery<HTMLElement>) {
      $filter.toggleClass('active', false);
      $filter.find('select.marker-filter-select').select2('close');
      $filter.find(`.${eh.DatePicker.CLS_MARKER_DATEPICKER}`).hide();
      $filter.find(`.${eh.DatePicker.CLS_MARKER_DATEPICKER_CONTROL}`).hide();
    }
    
  }
  
}
