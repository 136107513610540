namespace eh {


    export class Breakpoint {

        /*
        VARIABLE DEFINITIONS:
        =====================
        @breakpoint-large: 1280px;    // --
        @breakpoint-medium: 1025px;   // large      expression: min
        @breakpoint-small: 769px;     // medium     expression: max -1
        @breakpoint-x-small: 481px;   // small      expression: max -1
        @breakpoint-ultra-small: 350px; // x-small  expression: max -1
        */

        public isMobile: boolean;
        static MOBILE_THRESHOLD: number = 1025;

        constructor (
            public id: string,
            // active max-bound
            public threshold: number
        ) {
            this.isMobile = threshold < Breakpoint.MOBILE_THRESHOLD;
        }

        public isInRange(range: number): boolean {
            return range > this.threshold
        }
    }

    export enum BreakPointViewType {
        MOBILE,
        DESKTOP
    }

    export class Breakpoints {

        private _listener: {(old: Breakpoint, current: Breakpoint): void;}[] = [];
        private _viewTypeListener: {(viewType: BreakPointViewType): void;}[] = [];
        private _resizeListener: {(): void;}[] = [];
        private _currentBreakpoint: Breakpoint;
        private _isMobile: boolean;
        private _ticking: boolean = false;
        private _initialized: boolean = false;
        private _currentViewType: BreakPointViewType;

        // list of available breakpoints
        private _breakpoints: Breakpoint[] = [
            new Breakpoint('x-large', 1440),
            new Breakpoint('large', 1025),
            new Breakpoint('medium', 769),
            new Breakpoint('small', 481),
            new Breakpoint('x-small', 350)
        ];

        private static _instance: Breakpoints;

        private constructor() {
            window.addEventListener('load', this.invalidate);
            window.addEventListener('resize', this.invalidate);
            this.invalidate();
        }

        public static getInstance(): Breakpoints {
            if (!Breakpoints._instance) {
                Breakpoints._instance = new Breakpoints();
            }
            return Breakpoints._instance;
        }

        public get initialized(): boolean {
            return this._initialized;
        }

        public get isMobile(): boolean {
            return this._isMobile;
        }

        public get viewType(): BreakPointViewType {
            return this._currentViewType;
        }

        public registerChangeListener(listener: (old: Breakpoint, current: Breakpoint) => void): void {
            this._listener.push(listener);
            if (this._initialized && !!this._currentBreakpoint) {
                listener(this._currentBreakpoint, this._currentBreakpoint);
            }
        }

        public unregisterChangeListener(listener: (old: Breakpoint, current: Breakpoint) => void): void {
            const idx: number = this._listener.indexOf(listener);
            if (idx > -1) {
                this._listener.splice(idx, 1);
            }
        }

        public registerViewTypeListener(listener: (viewType: BreakPointViewType) => void): void {
            this._viewTypeListener.push(listener);
            if (this._initialized && !isNaN(this._currentViewType)) {
                this.dispatchViewTypeChanged(this._currentViewType);
            }
        }

        public unregisterViewTypeListener(listener: (viewType: BreakPointViewType) => void): void {
            const idx: number = this._viewTypeListener.indexOf(listener);
            if (idx > -1) {
                this._viewTypeListener.splice(idx, 1);
            }
        }

        public registerResizeListener(listener: () => void): void {
            this._resizeListener.push(listener);
        }

        public unregisterResizeListener(listener: () => void): void {
            const idx: number = this._resizeListener.indexOf(listener);
            if (idx > -1) {
                this._resizeListener.splice(idx, 1);
            }
        }

        private invalidate: () => void = (): void => {
            if (!this._ticking) {
                window.requestAnimationFrame((): void => {
                    this.invalidateNow();
                    this._ticking = false;
                });
                this._ticking = true;
            }
        };

        private invalidateNow(): void {

            const windowWidth: number = window.innerWidth;
            let lastValidBreakpoint: Breakpoint = this._breakpoints[this._breakpoints.length - 1];

            for (let i: number = 0; i < this._breakpoints.length; i++) {
                const _p: Breakpoint = this._breakpoints[i];
                if (windowWidth >= _p.threshold) {
                    lastValidBreakpoint = _p;
                    break;
                }
            }

            if (lastValidBreakpoint !== this._currentBreakpoint) {
                this.dispatchBreakpointChanged(this._currentBreakpoint, lastValidBreakpoint);
                this._currentBreakpoint = lastValidBreakpoint;
            }

            if (lastValidBreakpoint.isMobile !== this._isMobile) {
                this._currentViewType = lastValidBreakpoint.isMobile ? BreakPointViewType.MOBILE : BreakPointViewType.DESKTOP;
                this._isMobile = lastValidBreakpoint.isMobile;
                this.dispatchViewTypeChanged(this._currentViewType);
            }

            if (this._resizeListener.length) {
                this.dispatchSizeChanged();
            }

            if (!this._initialized) {
                this._initialized = true;
            }

        }

        private dispatchBreakpointChanged(old: Breakpoint, current: Breakpoint): void {
            this._listener.forEach(_l => _l(old, current));
        }

        private dispatchViewTypeChanged(viewType: BreakPointViewType): void {
            this._viewTypeListener.forEach(_l => _l(viewType));
        }

        private dispatchSizeChanged(): void {
            this._resizeListener.forEach(_l => _l());
        }

    }

}

