namespace cs {

  export class Snippet {
  
    static readonly EventIdErrorAjaxCall = 'csSnippet:errorAjaxCall';
    static readonly EventIdFormReset = 'csSnippet:formReset';
    static readonly EventIdFormSubmit = 'csSnippet:formSubmit';
    static readonly EventIdPostReplace = 'csSnippet:postReplace';
    static readonly EventIdPreAjaxCall = 'csSnippet:preAjaxCall';
    static readonly EventIdPreReplace = 'csSnippet:preReplace';

    /* Grouping - merge results received by AJAX into last shown cluster on demand: #replaceClustered()
    * mark the outer element of cluster: detected in response and removed if empty after merge */
   static readonly CLASS_CLUSTER_CONTAINER = "snippet-cluster-container";
    /* Grouping - merge results received by AJAX into last shown cluster on demand: #replaceClustered()
    * mark the parent of result-elements: detected in response and merge children() if cluster is already present */
   static readonly CLASS_CLUSTER_PARENT = "snippet-cluster-elements-parent";
    /* Grouping - merge results received by AJAX into last shown cluster on demand: #replaceClustered()
    * info on CLASS_CLUSTER_PARENT: provide page-unique ID for a cluster in data attr  */
   static readonly DATA_CLUSTER_ID = "snippetClusterId";
    
    static init($base: JQuery<HTMLElement>): void {
      $base.on(Snippet.EventIdPreAjaxCall, (event: SnippetEventPreAjax): void => {
        event.targetElement.find('.forward-params-link').data('defer', true);
      });
      
      $base.on(Snippet.EventIdPreReplace, () => {
        // console.log('eventIdpreReplace running js for : ' + event.oldElement.attr('id'));
        Snippet.hideLoadingIndicator();
      });
  
      $base.on(Snippet.EventIdErrorAjaxCall, () => {
        //console.log('eventIderrorAjaxCall running js for : ' + event.oldElement.attr('id'));
        Snippet.hideLoadingIndicator();
      });
      
      Snippet.initConditionalVisibility($base);
      Snippet.registerForm($base);
      Snippet.registerAjaxLinks($base);
      Snippet.registerAjaxTabs($base);
    }
  
    public static preAjax(target: JQuery<HTMLElement>, activator: HTMLElement | null | undefined) {
      const event = jQuery.Event(Snippet.EventIdPreAjaxCall) as SnippetEventPreAjax;
      event.targetElement = target;
      if(activator) {
        Snippet.showLoadingIndicator($(activator));
      }
      $(':root').trigger(event);
    }
    
    private static errorAjax(jqXHR: JQuery.jqXHR, textStatus: JQuery.Ajax.ErrorTextStatus, oldElement: JQuery<HTMLElement>) {
      const event = jQuery.Event(Snippet.EventIdErrorAjaxCall) as SnippetEventAjaxError;
      event.oldElement = oldElement;
      event.status = jqXHR.status;
      event.textStatus = textStatus;
      $(':root').trigger(event);
    }
    
    private static preReplace(oldElement: JQuery<HTMLElement>, newElement: JQuery<HTMLElement>) {
      const event = jQuery.Event(Snippet.EventIdPreReplace) as SnippetEventPreReplace;
      event.oldElement = oldElement;
      event.newElement = newElement;
      $(':root').trigger(event);
    }
    
    private static registerAjaxLinks($base: JQuery<HTMLElement>) {
      $('a.csSnippet', $base).each((_index, el) => {
        const $clickedElement = $(el);
        //console.log('registering ajax link', $clickedElement, $clickedElement.text().trim());
        $clickedElement.on('click', ($event) => {
          $event.preventDefault();
          const $el = $($event.currentTarget);
          if ($el.closest('li').hasClass('active')) {
            $el.closest('.trigger-tracking-click').data('csTrackingNoTrack', true);
            return;
          }
          let $targetDiv = $el.closest('div.csSnippet');
          if ($clickedElement.selfOrClosest('.increaseResult').length === 1) {
            $targetDiv = $clickedElement.selfOrClosest('.result_bottom');
          }
          const successFunction = (data: any) => {
            Snippet.replaceClustered(data, $targetDiv, $clickedElement);
          };
          Snippet.preAjax($targetDiv, $el.get(0));
  
          const href = $el.attr('href');
          if (href && !$el.data('noHistoryState')) {
            history.replaceState(history.state, '', href);
          }
          
          $.ajax($el.data('snippetUrl'), {
            dataType: 'xml',
            success: successFunction
          });
        });
      });
    }
  
    private static registerAjaxTabs($base: JQuery<HTMLElement>) {
      const showTabContent = ($tabContent: JQuery<HTMLElement>, $tabContents: JQuery<HTMLElement>) => {
        $tabContents.not($tabContent).each((_idx, el) => {
          $(el).prop('hidden', true)
            .toggleClass('eh--hide', true);
        });
        $tabContent.prop('hidden', false)
          .toggleClass('eh--hide', false);

        $(':root').trigger(eh.TAB_EVENTS.TAB_CONTENT_SHOWN, $tabContent);
      };
      $base.on(eh.TAB_EVENTS.TAB_CHANGE, ($event: JQuery.TriggeredEvent, tab: eh.ITab) => {
        const $tabContent = eh.Tabs.getTabContent(tab);
        const $tabContents = eh.Tabs.getTabContents(tab);
        
        const $snippet = $('.csSnippet', $tabContent).first();
        const $snippetTabContent = $('.marker-snippet-tab-content', $tabContent);
        const snippetUrl = $snippetTabContent.data('snippetUrl');
        if ($snippet.length > 0 && snippetUrl) {
          $tabContent.parent().toggleClass('is-loading', true);
          $.ajax({
            url: snippetUrl
          }).then((data: any) => {
            Snippet.replace(data, $snippet);
            showTabContent($tabContent, $tabContents);
            $tabContent.parent().toggleClass('is-loading', false);
            
            const href = tab.tab ? $(tab.tab).attr('href') : undefined;
            if (href) {
              history.replaceState(history.state, '', href);
            }
          }, () => {
            $tabContent.parent().toggleClass('is-loading', false);
          });
        }
        else {
          if (!$tabContent.prop('hidden')) {
            return;
          }
          showTabContent($tabContent, $tabContents);
          
          const href = $('.marker-tab-content', $tabContent).last().data('href');
          if (href) {
            history.replaceState(history.state, '', href);
          }
        }
      });
    }
    
    private static replaceSelectorsInt(replaceSelectors: string[], data: any, targetDiv: JQuery<HTMLElement>) {
      const $data = $(data);
      const newElementData = $data.find('data').text();
      const $newElement = newElementData.length > 0 ? $(newElementData) : $data;
      const replaced: {replacement: JQuery<HTMLElement>, replaced: JQuery<HTMLElement>}[] = [];
      Snippet.preReplace(targetDiv, targetDiv);
      replaceSelectors.forEach((i) => {
        const replacement = $newElement.find(i);
        const toReplace = targetDiv.selfOrFind(i);
        replaced.push({'replacement': replacement, 'replaced': toReplace});
        Snippet.preReplace(toReplace, $newElement);
        toReplace.replaceWith(replacement);
      });
      replaced.forEach((x) => {
        const event = jQuery.Event(Snippet.EventIdPostReplace) as SnippetEventPostReplace;
        event.replacedTarget = x.replacement;
        event.removedTarget = x.replaced;
        $(':root').trigger(event);
        
      });
    }
  
    public static replace(data: any, targetDiv: JQuery<HTMLElement>, source?: JQuery<HTMLElement>) {
      if (source) {
        var replaceSelectors = source.data('snippetReplace');
        source.closest('ul').find('li').removeClass('active');
        source.closest('li').addClass('active');
        if (replaceSelectors) {
          replaceSelectors = replaceSelectors.split(' ');
          Snippet.replaceSelectorsInt(replaceSelectors, data, targetDiv);
          return;
        }
      }
  
      const $data = $(data);
      const newElementData = $data.find('data').text();
      const $newElement = newElementData.length > 0 ? $(newElementData) : $data;

      Snippet.preReplace(targetDiv, $newElement);
      targetDiv.replaceWith($newElement);
      const event = jQuery.Event(Snippet.EventIdPostReplace) as SnippetEventPostReplace;
      event.replacedTarget = $newElement;
      event.removedTarget = targetDiv;
      $(':root').trigger(event);
    }
    
    /**
     * @desc Check 'data' for CLASS_CLUSTER_PARENT and merge incoming data on demand; otherwise delegate to {@link replace(data, $targetDiv, source)}
     * @param {any} data content received by ajax
     * @param {JQuery<HTMLElement>} $targetDiv element to be replaced by 'data' or 'data's remnants after merge
     * @param {JQuery<HTMLElement>} source not used local; handed on to fallback
     */
    public static replaceClustered(data:any, $targetDiv: JQuery<HTMLElement>, source?: JQuery<HTMLElement>): void {
      if (source && $(source).data('snippetReplace')) {
        Snippet.replace(data, $targetDiv, source);
        return;
      }

      const $data = $(data);
      const newElementData = $data.find('data').text();
      const $newElement = newElementData.length > 0 ? $(newElementData) : $data;
      const selParent = '.' + Snippet.CLASS_CLUSTER_PARENT;
      const $groupParent = $newElement.find(selParent).first();
      if ($groupParent.length === 1) {
        const id = $groupParent.data(Snippet.DATA_CLUSTER_ID);
        const $groupTarget = $(selParent).filter((_idx, s) => $(s).data(Snippet.DATA_CLUSTER_ID) === id).first();
        
        const replaced: {replacement: JQuery<HTMLElement>, replaced: JQuery<HTMLElement>}[] = [];
        if ($groupTarget.length === 1) {
          // merge gsrc childs into gtarget
          const $replacement = $groupParent.children();
          const $toReplace = $('<div></div>');
          $groupTarget.append($toReplace);
          Snippet.preReplace($replacement, $toReplace);
          $toReplace.replaceWith($replacement);
          replaced.push({'replacement': $replacement, 'replaced': $toReplace});
          // remove remnants from newElement
          $groupParent.selfOrClosest('.'+Snippet.CLASS_CLUSTER_CONTAINER).remove();
        }
        Snippet.preReplace($newElement, $targetDiv);
        $targetDiv.replaceWith($newElement);
        replaced.push({'replacement': $newElement, 'replaced': $targetDiv});
        replaced.forEach((x) => {
          const event = jQuery.Event(Snippet.EventIdPostReplace) as SnippetEventPostReplace;
          event.replacedTarget = x.replacement;
          event.removedTarget = x.replaced;
          $(':root').trigger(event);
        });
        return;
      }
      // always call fallback if not solved local
      cs.Snippet.replace(data, $targetDiv, source);
    }

    public static ajaxSubmitForm($eventProducer: JQuery<HTMLElement>, $event: JQuery.TriggeredEvent<HTMLElement>, callbackFunction?: Function) {
      const $form = $eventProducer.closest('form.csSnippetForm'), snippetUrl = $form.data('snippetUrl');
      const clearExpression = $eventProducer.data('clear');
      $event.preventDefault();
      
      if (clearExpression) {
        $form.find(clearExpression).each((i, e) => {
          if (e.type === 'checkbox' || e.type === 'radio') {
            if (e.checked) {
              e.checked = false;
            }
          } else {
            e.value = '';
          }
        });
      }
		
      if (!snippetUrl || $form.data('formValid') === false) {
        return;
      }
  
      let submitEventCoalescingTimeout = $form.data('submitEventCoalescingTimeout');
      if (submitEventCoalescingTimeout) {
        return;
      }
      if($event.target instanceof HTMLElement) {
        Snippet.showLoadingIndicator($(<HTMLElement> $event.target));
      }
      submitEventCoalescingTimeout = window.setTimeout(function () {
        const $targetDiv = $form.closest('div.csSnippet');
        const removeFields = $form.data('ignoreFieldnames');
        if (removeFields !== undefined) {
          $form.find('input[name^=\'' + removeFields + '\']').remove();
        }
        const errorFunction = function (jqXHR: JQuery.jqXHR, textStatus: JQuery.Ajax.ErrorTextStatus) {
          Snippet.errorAjax(jqXHR, textStatus, $targetDiv);
          $form.removeData('submitEventCoalescingTimeout');
          $form.removeClass('eh-opacity-7');
        };
        const successFunction = (data: any) => {
          if($form.data('snippetReplace')) {
            Snippet.replace(data, $targetDiv, $form);
          } else {
            Snippet.replace(data, $targetDiv);
          }
          if (typeof callbackFunction === 'function') {
            callbackFunction.call(null);
          }
          $form.removeData('submitEventCoalescingTimeout');
          $form.removeClass('eh-opacity-7');
          
          if ($form.data('stateHandling') !== 'ignore' && !$form.data('noHistoryState')) {
            const action = $form.attr('action'),
                url = (!action || action.substring(0, 1) === '#') ? location.pathname : action,
                newParams = eh.URLHelper.buildQueryParamMap($form.formSerialize()),
                originalParams = eh.URLHelper.buildQueryParamMap(eh.URLHelper.getQueryString(url))
            ;
            const newState = eh.URLHelper.buildUrl(url, eh.URLHelper.buildQueryString(eh.URLHelper.mergeQueryParams(originalParams, newParams, eh.URLHelper.latterWins)));
            history.replaceState(history.state, '', newState);
          }
        };
        Snippet.preAjax($targetDiv, null);
        $form.addClass('eh-opacity-7');
        $form.ajaxSubmit({
          beforeSend: (): boolean => {
            const $submitEvent = jQuery.Event(Snippet.EventIdFormSubmit) as SnippetEventFormSubmit;
            $submitEvent.$form = $form as JQuery<HTMLFormElement>;
            $(':root').trigger($submitEvent);
            return !$submitEvent.isDefaultPrevented();
          },
          dataType: 'xml',
          error: errorFunction,
          success: successFunction,
          timeout: 20 * 1000,
          type: $form.prop('method') || 'get',
          url: snippetUrl
        });
      }, 0);
      $form.data('submitEventCoalescingTimeout', submitEventCoalescingTimeout);
    }
    
    private static registerForm($base: JQuery<HTMLElement>) {
      $('form.csSnippetForm', $base).each((_index, el) => {
        const $form = $(el);
        const prepareForegroundSubmit = ($form: JQuery<HTMLElement>) => {
          $form.find('*').filter(':input:enabled').each((_index, el) => {
            const name = $(el).attr('name');
            if (name) {
              $(el).attr('name', name.replace('np.', 'filter.'));
            }
          });
        };
        $('.onEnterForegroundSubmit', $form).on('keydown', function ($event) {
          if ($event.key === 'Enter') { // 13 Enter
            $event.preventDefault();
            $form.trigger('submit');
          }
        });
        $('.onChangeAjaxSubmit', $form).on('change', function ($event) {
          Snippet.ajaxSubmitForm($(this), $event);
        });
        
        $('.triggerSearch, .ajaxSubmitButton', $form).on('click', ($event) => {
          const $target = $($event.currentTarget);
          if (!$target.hasClass('disabled')) {
            Snippet.ajaxSubmitForm($target, $event);
          }
        });

        $form.not('.foreground-submit').on('submit', ($event) => {
          Snippet.ajaxSubmitForm($($event.currentTarget), $event);
        });
        $form.filter('.foreground-submit').on('submit', () => {
          prepareForegroundSubmit($form);
        });
        
        $('.trigger-form-reset', $form).on('click', function ($event) {
          $event.preventDefault();
          if (!$(this).hasClass('disabled')) {
            eh.LoadingIndicator.showLoadingByEv($event);
            Snippet.resetForm($form);
          }
        });
        $('.csProdSearch__reset, .trigger-reset-search').on('click', function ($event) {
          $event.preventDefault();
          if (!$(this).hasClass('disabled')) {
            const $form = $(this).closest('.marker-search-container').find('form.csSnippetForm');
            eh.LoadingIndicator.showLoadingByEv($event);
            Snippet.resetForm($form);
          }
        });
        $('.trigger-foreground-submit', $form).on('click', function ($event) {
          $event.preventDefault();
          $form.trigger('submit');
        });
      });
    }
    
    private static resetForm($form: JQuery<HTMLElement>) {
      $('input, select, textarea', $form).each(function () {
        $(this).val('');
      });
      const $event = jQuery.Event(Snippet.EventIdFormReset) as SnippetEventFormReset;
      $event.$form = $form as JQuery<HTMLFormElement>;
      Snippet.ajaxSubmitForm($form, $event);
    }
  
    private static showLoadingIndicator(target: JQuery<HTMLElement>) {
      eh.LoadingIndicator.showLoading(target);
    }
  
    private static hideLoadingIndicator() {
      eh.LoadingIndicator.hideLoading();
    }
    
    private static initConditionalVisibility($base: JQuery<HTMLElement>) {
      $('.conditional_visibility', $base).each(function () {
        const $formRow = $(this), $form = $formRow.closest('form'), $formField = $(':input', $formRow),
            fieldName: string = $formRow.data('conditionFieldName'),
            form: HTMLFormElement = $form.get(0) as HTMLFormElement,
            $watchedField: JQuery<HTMLElement> = $(form[fieldName]),
            targetValue = $formRow.data('conditionFieldValue');
        
        if ($watchedField.length > 0 && targetValue !== undefined) {
          $watchedField.on('change', ($event) => {
            let currentValue, valuesMatching, $elem = $($event.currentTarget);
            const type = $elem.attr('type');
            if (type === 'checkbox') {
              $elem = $elem.filter(':checked');
              currentValue = $elem.val();
            }
            else if (type === 'radio') {
              currentValue = $('input[type="radio"][name="' + $elem.attr('name') + '"]:checked').val();
            }
            else {
              currentValue = $elem.val();
            }
            valuesMatching = currentValue === targetValue;
            $formRow.toggle(valuesMatching);
            $formField.prop('disabled', !valuesMatching);
          });
        } else {
          $formRow.hide();
        }
      });
      
    }
    
  }
  
  export interface SnippetEventPreAjax extends JQuery.TriggeredEvent<HTMLElement> {
    targetElement: JQuery<HTMLElement>;
  }
  
  export interface SnippetEventAjaxError extends JQuery.TriggeredEvent<HTMLElement> {
    oldElement: JQuery<HTMLElement>;
    status: number;
    textStatus: string;
  }
  
  export interface SnippetEventPreReplace extends JQuery.TriggeredEvent<HTMLElement> {
    oldElement?: JQuery<HTMLElement>;
    newElement: JQuery<HTMLElement>;
  }
  
  export interface SnippetEventPostReplace extends JQuery.TriggeredEvent<HTMLElement> {
    replacedTarget: JQuery<HTMLElement>;
    removedTarget?: JQuery<HTMLElement>;
  }
  
  export interface SnippetEventFormReset extends JQuery.TriggeredEvent<HTMLElement> {
    $form: JQuery<HTMLFormElement>;
  }
  
  export interface SnippetEventFormSubmit extends JQuery.TriggeredEvent<HTMLElement> {
    $form: JQuery<HTMLFormElement>;
  }

}