namespace eh {
  
  export class Slider {
    
    private readonly navigator: SliderNavigator;
    private readonly slides: Slide[];
    private destroyed: boolean = false;
    private slideGroups: SlideGroup[];
    private slidesPerGroup: number;
    private currentSlide: Slide;
    private currentGroup: SlideGroup;
    private transforming: boolean;
    private itemWidth: number;
    private autoPlay: boolean;
    private autoPlayTimeout = 5000;
    private autoPlayStack: number;
    private autoPlayTimeoutHandle: number | null;
    private isReInitializing: number = 0;

    private static clsCaptionSource = 'eh-multimedia--figcaption-01';
    private static clsCaptionTarget = 'eh-multimedia--figcaption-02';
    /* outer bound of component */
    private static clsSlider = 'eh-slider';
    /* card stack container */
    private static clsSlideContainer = 'eh-slider--items';
    /* a card on stack */
    private static clsSlideItem = 'eh-slider--item';
    private static clsHasPending = 'has-pending';
    private static clsActiveMarker = 'eh-slider--active';
    private static clsFlowPlayerMarker = 'flowplayer';
    private static clsAutoPlayMarker = 'eh-slider--autoplay';

    static init($base: JQuery<HTMLElement>): void {
      $(`.${ Slider.clsSlider }:not(.wrap-items)`, $base).each((_index, element) => {
        new Slider($(element));
      });
      
    }
    
    constructor(private $elem: JQuery<HTMLElement>) {
      this.slides = [];
      $(`.${ Slider.clsSlideItem }`, $elem).each((_index, element) => {
        this.slides.push(new Slide($(element)));
      });

      if (this.slides.length === 0) {
        return;
      }
      else if (this.slides.length > 1) {
        this.slides.forEach((slide) => {
          const $slide = slide.$elem;
          $slide.swipe(
            {
              'swipeLeft': () => {
                this.slideNext();
              },
              'swipeRight': () => {
                this.slidePrevious();
              },
              'preventDefaultEvents': false,
              'threshold': 55
            }
          );
          $slide.on('dragstart', (e: JQuery.TriggeredEvent) => {
            e.preventDefault();
          });
        });
        this.autoPlay = $elem.hasClass(Slider.clsAutoPlayMarker);
        if (this.autoPlay) {
          this.startAutoPlay();
        }
      }
      
      this.currentSlide = this.slides.filter(slide => {
        return slide.$elem.hasClass(Slider.clsActiveMarker);
      })[0] || this.slides[0];
      
      this.navigator = new SliderNavigator($elem, this);

      const $firstContentImage = $('img:first', this.currentSlide.$elem);
      if ($firstContentImage.prop('complete') === false) {
        $firstContentImage.one('load', () => {
          this.doCalculations();
          this.navigator.notifyStatusChanged(this.getStatus());
        });
      }
      else {
        window.setTimeout(() => {
          this.doCalculations();
          this.navigator.notifyStatusChanged(this.getStatus());
        }, 600);
      }

      $(':root').on(eh.TAB_EVENTS.TAB_CHANGE, this.tabChangeHandler);
      $(':root').on(eh.Constants.VISIBILITY_CHANGE, this.visibilityChangeHandler);

      $(window).on('resize', this.reInit);

      $(`.${ Slider.clsCaptionTarget }`, this.$elem).empty().append($(`.${ Slider.clsCaptionSource }`, this.currentSlide.$elem).children().clone());
      
      $elem.data('eh.Slider', this);
    }
    private visibilityChangeHandler: ($event: JQuery.TriggeredEvent) => void = ($event: JQuery.TriggeredEvent): void => {
      this.reInit();
    }
    private tabChangeHandler: ($event: JQuery.TriggeredEvent, tab: ITab) => void = ($event: JQuery.TriggeredEvent, tab: ITab): void => {
      if (Tabs.getTabContent(tab).selfOrFind(this.$elem).length > 0) { // slider is inside displayed tab content
        this.reInit();
      }
    }
    
    private reInit: () => void = (): void => {
      if (this.isReInitializing !== 0) {
        return;
      }
      this.isReInitializing = window.setTimeout(() => {
        if (this.destroyed) {
          return;
        }
        this.isReInitializing = 0;
        this.doCalculations();
        this.navigator.notifyStatusChanged(this.getStatus());
      }, 300);
  }

    doCalculations() {
      const
          $container: JQuery<HTMLElement> = $(`.${ Slider.clsSlideContainer }`, this.$elem),
          $firstItem: JQuery<HTMLElement> = $container.children(`.${ Slider.clsSlideItem }:first`);
      let si = SliderCalculations.measureSliderInfo($container.innerWidth(), $firstItem.outerWidth(true), this.slides.length);
      if ((si.numSlideGroups > 1 && !this.$elem.hasClass(Slider.clsHasPending)) || (si.numSlideGroups <= 1 && this.$elem.hasClass(Slider.clsHasPending))) {
        this.$elem.toggleClass(Slider.clsHasPending, si.numSlideGroups > 1);
        si = SliderCalculations.measureSliderInfo($container.innerWidth(), $firstItem.outerWidth(true), this.slides.length);
      }
      this.itemWidth = si.itemWidth;
      
      if (this.slidesPerGroup !== si.slidesPerGroup) {
        this.slidesPerGroup = si.slidesPerGroup;
        this.slideGroups = [];
        for (let i = 0; i < si.numSlideGroups; i++) {
          this.slideGroups.push(new SlideGroup(this.slides.slice(i * si.slidesPerGroup, Math.min((i + 1) * si.slidesPerGroup, this.slides.length))));
        }
      }
      
      const group = this.findSlideGroup(this.currentSlide);
      if (group !== this.currentGroup) {
        this.currentGroup = group;
      }
      this.slideTo(this.slides.indexOf(this.currentSlide), true);
    }
    
    findSlideGroup(slide: Slide): SlideGroup {
      return this.slideGroups.filter(group => group.containsSlide(slide))[0];
    }
    
    public slideTo(slideIndex: number, immediate: boolean = false) {
      const futureSlide = this.slides[slideIndex];
      const futureGroup = this.findSlideGroup(futureSlide);
      if (!futureGroup || this.transforming) {
        return;
      }
      //if (futureGroup === this.currentGroup) {
      //  this.currentSlide = futureSlide;
      //  return;
      //}
      const currentGroupIndex = this.slideGroups.indexOf(this.currentGroup);
      const futureGroupIndex = this.slideGroups.indexOf(futureGroup);
      //const direction = currentGroupIndex === futureGroupIndex ? 0 : currentGroupIndex > futureGroupIndex ? -1 : 1;
      this.transforming = true;
      
      //console.log('slideTo', slideIndex, currentGroupIndex, '->', futureGroupIndex);
      
      if (futureGroup !== this.currentGroup) {
        const flowplayer: Flowplayer | undefined = $(`.${ Slider.clsFlowPlayerMarker }`, this.currentSlide.$elem).data('flowplayer');
        if (flowplayer) {
          flowplayer.pause();
        }
      }
      
      const transforms: JQuery.Promise<HTMLElement>[] = [];
      this.slideGroups.forEach((group, groupIndex) => {
        const variance = $(`.${ Slider.clsSlideContainer }`, this.$elem).css('overflow') === 'hidden' ? 1 : 2;
        const isCurrent = eh.Slider.checkInBounds(groupIndex, currentGroupIndex, variance - 1);
        const isFuture = eh.Slider.checkInBounds(groupIndex, futureGroupIndex, variance - 1);
        
        const visible = isCurrent || isFuture;
        const startX = (groupIndex > currentGroupIndex + variance) ? futureGroupIndex - variance : (groupIndex < currentGroupIndex - variance) ? futureGroupIndex + variance : currentGroupIndex;
        const endX = (groupIndex > futureGroupIndex + variance) ? currentGroupIndex - variance : (groupIndex < futureGroupIndex - variance) ? currentGroupIndex + variance : futureGroupIndex;
        
        //console.log('slide', groupIndex, startX, endX);
        
        transforms.push.apply(transforms, group.getTransform(
          0 - startX * this.slidesPerGroup * this.itemWidth,
          0 - endX * this.slidesPerGroup * this.itemWidth,
          immediate,
          visible
        ));
      });
      $.when(transforms)
        .then(() => {
          this.transforming = false;
          this.currentGroup = futureGroup;
          this.currentSlide = futureSlide;
          
          this.navigator.notifyStatusChanged(this.getStatus());
          $(`.${ Slider.clsCaptionTarget }`, this.$elem).empty().append($(`.${ Slider.clsCaptionSource }`, this.currentSlide.$elem).children().clone());
        });
    }
    
    private static checkInBounds(x: number, y: number, variance: number): boolean {
      return x >= y - variance && x <= y + variance;
    }
    
    public slidePrevious() {
      const currentGroupIndex = this.slideGroups.indexOf(this.currentGroup);
      if (currentGroupIndex === 0) {
        return;
      }
      this.slideTo((currentGroupIndex - 1) * this.slidesPerGroup);
      if (this.autoPlay) {
        this.stopAutoPlay();
        this.startAutoPlay();
      }
    }
    
    public slideNext() {
      const currentGroupIndex = this.slideGroups.indexOf(this.currentGroup);
      const moveTo = (currentGroupIndex < this.slideGroups.length - 1) ? currentGroupIndex + 1 : 0;
      this.slideTo((moveTo) * this.slidesPerGroup);
      if (this.autoPlay) {
        this.stopAutoPlay();
        this.startAutoPlay();
      }
    }
    
    public getStatus(): Status {
      const currentSlideIndex = this.slides.indexOf(this.currentSlide);
      const currentGroupIndex = this.slideGroups.indexOf(this.currentGroup);
      return new Status(currentGroupIndex + 1, currentSlideIndex + 1, this.slideGroups.length,
          this.slidesPerGroup, currentGroupIndex > 0, currentGroupIndex < this.slideGroups.length - 1);
    }

    public startAutoPlay() {
      this.autoPlayStack += 1;
      if (this.autoPlayTimeoutHandle || this.autoPlayStack <= 0) {
        return;
      }
      this.autoPlayTimeoutHandle = window.setTimeout(() => {
        this.slideNext();
      }, this.autoPlayTimeout);

    }

    public stopAutoPlay() {
      this.autoPlayStack -= 1;
      if (!this.autoPlayTimeoutHandle || this.autoPlayStack > 0) {
        return;
      }
      window.clearTimeout(this.autoPlayTimeoutHandle);
      this.autoPlayTimeoutHandle = null;
    }

    getHelperHeight() {
      return this.slides[0].$elem.find('.eh-layout--pos-rel').innerHeight();
    }

    destroy: () => void = (): void => {
      this.destroyed = true;
      this.stopAutoPlay();
      $(':root').off(eh.TAB_EVENTS.TAB_CHANGE, this.tabChangeHandler);
      $(':root').off(eh.Constants.VISIBILITY_CHANGE, this.visibilityChangeHandler);
      $(window).off('resize', this.reInit);
      this.slides.forEach((s: Slide): void => {
        s.$elem.swipe('destroy');
        s.$elem.get(0)?.removeAttribute('style');
      });
    }

  }
  
  class Status {
    
    constructor(public readonly currentSlideGroup: number, public readonly currentSlide: number, public readonly slidesTotal: number,
                public readonly slidesPerGroup: number, public readonly hasPrevious: boolean, public readonly hasNext: boolean) {
    }
    
  }
  
  class Transform {
    
    private done: JQuery.Deferred<HTMLElement>;
    
    constructor(private $subject: JQuery<HTMLElement>, private steps: ((this: Transform, $elem: JQuery<HTMLElement>, done: JQuery.Deferred<HTMLElement>) => void)[]) {
    }
    
    public execute(): JQuery.Promise<HTMLElement> {
      this.done = $.Deferred();
      this.run();
      return $.when(this.done);
    }
    
    run() {
      const step = this.steps.shift();
      if (step) {
        window.requestAnimationFrame(() => {
          step.call(this, this.$subject, this.done);
          this.run();
        });
      }
    }
    
  }
  
  class SlideGroup {
    
    private readonly slides: Slide[];
    
    constructor(slides: Slide[]) {
      this.slides = slides;
    }
    
    public containsSlide(slide: Slide): boolean {
      return this.slides.indexOf(slide) !== -1;
    }
    
    public getTransform(x1: number, x2: number, immediate: boolean, visible: boolean): JQuery.Promise<HTMLElement>[] {
      return this.slides.map((value) => {
        const steps = [];
        if (!immediate) {
          steps.push(
            function (this: Transform, $elem: JQuery<HTMLElement>) {
              $elem.css('visibility', visible ? 'visible' : 'hidden');
              $elem.css('transition', 'transform 0s');
              $elem.css('transform', 'translate3d(' + x1 + 'px, 0, 0)');
            });
        }
        steps.push(
          function (this: Transform, $elem: JQuery<HTMLElement>, done: JQuery.Deferred<HTMLElement>) {
            $elem.css('visibility', visible ? 'visible' : 'hidden');
            $elem.css('transition', 'transform ' + (immediate ? '0s' : '500ms'));
            $elem.css('transform', 'translate3d(' + x2 + 'px, 0, 0)');
            $elem.one('transitionend', () => {
              const el = $elem.get(0);
              if (el) {
                done.resolve(el);
              }
              else {
                done.reject($elem);
              }
            });
          }
        );
        return new Transform(value.$elem, steps).execute();
      });
    }
    
  }
  
  class Slide {
    
    constructor(public readonly $elem: JQuery<HTMLElement>) {
    }
    
  }
  
  class SliderNavigator {
    
    private readonly $elem: JQuery<HTMLElement>;
    private readonly $items: JQuery<HTMLElement>;
    private readonly $pagerForward: JQuery<HTMLElement>;
    private readonly $pagerBackward: JQuery<HTMLElement>;
    //noinspection JSMismatchedCollectionQueryUpdate
    private readonly $itemNext: JQuery<HTMLElement>;
    //noinspection JSMismatchedCollectionQueryUpdate
    private readonly $itemPrevious: JQuery<HTMLElement>;
    private itemsPerPage = 0;
    private currentPage = 0;
    private hideOnRedundant = false;
    
    private static clsDisplayNone = 'eh-display-none';
    private static clsNavigatorDisplayHidden = 'eh-slider-navigator-hide-redundant';
    private static clsNavigatorBackward = 'eh-slider-navigator--backward';
    private static clsNavigatorForward = 'eh-slider-navigator--forward';
    private static clsNavigatorItem = 'eh-slider-navigator--item';
    private static clsNavigatorNext = 'eh-slider-navigator--next';
    private static clsNavigatorPrevious = 'eh-slider-navigator--previous';
    private static clsSliderNavigator = 'eh-slider-navigator';
    
    constructor($base: JQuery<HTMLElement>, private slider: Slider) {
      this.$elem = $(`.${ SliderNavigator.clsSliderNavigator }`, $base);
      this.hideOnRedundant = $base.hasClass(`${ SliderNavigator.clsNavigatorDisplayHidden }`);
      
      this.$pagerBackward = $(`.${ SliderNavigator.clsNavigatorBackward }`, this.$elem).on('click', ($event) => {
        $event.preventDefault();
        this.showPage(this.currentPage - 1);
      });
      this.$pagerForward = $(`.${ SliderNavigator.clsNavigatorForward }`, this.$elem).on('click', ($event) => {
        $event.preventDefault();
        this.showPage(this.currentPage + 1);
      });
      
      this.$itemPrevious = $(`.${ SliderNavigator.clsNavigatorPrevious }`, this.$elem).on('click', ($event) => {
        $event.preventDefault();
        if(this.$itemPrevious.hasClass("disabled")) {
          $event.stopPropagation();
          return;
        }
        (document?.activeElement as HTMLElement).blur();
        this.slider.slidePrevious();
      });
      
      this.$itemNext = $(`.${ SliderNavigator.clsNavigatorNext }`, this.$elem).on('click', ($event) => {
        $event.preventDefault();
        if(this.$itemNext.hasClass("disabled")) {
          $event.stopPropagation();
          return;
        }
        (document?.activeElement as HTMLElement).blur();
        this.slider.slideNext();
      });
      
      this.$items = $(`.${ SliderNavigator.clsNavigatorItem }`, this.$elem)
          .not(this.$pagerBackward.add(this.$pagerForward));
      
      this.$items.on('click', ($event) => {
        $event.preventDefault();
        let $item = $($event.currentTarget);
        $item.toggleClass('active', true);
        this.$items.not($item).toggleClass('active', false);
        this.slider.slideTo(this.$items.index($item));
      });
    }
    
    notifyStatusChanged(status: Status) {
      //console.log('notifyStatusChanged', status);
      this.calculateDirectItems();
      
      this.$items.each((index, element) => {
        $(element).toggleClass('active', index === status.currentSlide - 1);
        if (status.slidesPerGroup > 1) {
            $(element).toggleClass(SliderNavigator.clsDisplayNone, (index % status.slidesPerGroup) !== 0);
        }
      });
      
      this.showPage(this.getPageForItem(status.currentSlide - 1));
      this.$itemPrevious.toggleClass(SliderNavigator.clsDisplayNone, !status.hasPrevious);
      this.$itemNext.toggleClass(SliderNavigator.clsDisplayNone, !status.hasNext);
      this.handleRedundant(!status.hasPrevious && !status.hasNext);
    }
    
    getPageForItem(itemIndex: number): number {
      return SliderCalculations.getPageForItem(itemIndex, this.$items.length, this.itemsPerPage);
    }
    
    calculateDirectItems() {
      //console.log('calculateDirectItems');
      const roundingCorrection = 0.001;
      
      const $firstItem = this.$items.first();
      const $container = this.$elem;
      const cw = $container.innerWidth() || 0;
      const ch = $container.innerHeight() || 0;
      const iw = $firstItem.outerWidth(true) || 0;
      const ih = $firstItem.outerHeight(true) || 0;
      const isVertical = ch > cw;
      
      const containerSize = isVertical ? (this.slider.getHelperHeight() || ch) : cw;
      const itemSize = isVertical ? ih : iw;
      
      //const oldItemsPerPage = this.itemsPerPage;
      this.itemsPerPage = Math.max(Math.floor(containerSize / itemSize + roundingCorrection), 1);
      //console.log('itemsPerPage', containerSize, '/', itemSize, '=>', this.itemsPerPage);
      //if (this.itemsPerPage != oldItemsPerPage) {
        this.showPage(this.getPageForItem(this.slider.getStatus().currentSlide - 1));
      //}
    }
    
    showPage(pageIndex: number) {
      //console.log('showPage', pageIndex);
      const status = this.slider.getStatus();
      const pi = SliderCalculations.getInfoForPage(pageIndex, this.$items.length, this.itemsPerPage);
      this.$pagerBackward.parent().toggleClass(SliderNavigator.clsDisplayNone, !pi.showPagerBackward);
      this.$pagerForward.parent().toggleClass(SliderNavigator.clsDisplayNone, !pi.showPagerForward);
      this.$items.each((index, element) => {
        $(element).toggleClass(SliderNavigator.clsDisplayNone, index < pi.fromIndex || index > pi.toIndex);
        if (status.slidesPerGroup > 1) {
          $(element).toggleClass(SliderNavigator.clsDisplayNone, (index % status.slidesPerGroup) !== 0);
        }
      });
      this.handleRedundant(this.$items.length <= pi.toIndex + pi.fromIndex +1);
      this.currentPage = pageIndex;
    }

    handleRedundant(redundant: boolean) {
      if(this.hideOnRedundant) {
        if(redundant) {
          this.$itemPrevious.toggleClass(SliderNavigator.clsDisplayNone, false);
          this.$itemNext.toggleClass(SliderNavigator.clsDisplayNone, false);
          this.$pagerBackward.toggleClass(SliderNavigator.clsDisplayNone, false);
          this.$pagerForward.toggleClass(SliderNavigator.clsDisplayNone, false);
//          this.$items.each((index, element) => {
//            $(element).toggleClass(SliderNavigator.clsDisplayNone, false);
//          });
        }
        this.$itemPrevious.toggleClass('eh--hide', redundant);
        this.$itemNext.toggleClass('eh--hide', redundant);
        this.$pagerBackward.toggleClass('eh--hide', redundant);
        this.$pagerForward.toggleClass('eh--hide', redundant);
        this.$items.each((index, element) => {
          $(element).toggleClass('eh--hide', redundant);
        });
      }
      // COM-2025
      this.$items.closest('.ehel-product-teaser--indicators').toggleClass('eh--hide', redundant);
    }
  }
  
  export class SliderCalculations {
    
    static getPageForItem(itemIndex: number, itemsTotal: number, itemsPerPage: number): number {
      const itemsVisible = Math.max(itemsPerPage - 2, 1);
      const numPages = Math.ceil(Math.max(itemsTotal - 2, 1) / itemsVisible);
      return Math.min(Math.max(Math.ceil(itemIndex / itemsVisible), 1), numPages) - 1;
    }
    
    static getInfoForPage(pageIndex: number, itemsTotal: number, itemsPerPage: number): SliderPageInfo {
      const itemsVisible = itemsPerPage - 2;
      const showPagerBackward = pageIndex > 0;
      const showPagerForward = Math.ceil((itemsTotal - 2) / itemsVisible) > pageIndex + 1;
      const fromIndex = pageIndex * itemsVisible + (showPagerBackward ? 1 : 0);
      const toIndex = Math.min(fromIndex + (itemsVisible - 1) + (showPagerBackward ? 0 : 1) + (showPagerForward ? 0 : 1), itemsTotal - 1);
      return {
        fromIndex: fromIndex,
        toIndex: toIndex,
        showPagerBackward: showPagerBackward,
        showPagerForward: showPagerForward
      };
    }
    
    static measureSliderInfo(cw: number | undefined = 0, iw: number | undefined = 0, numSlides: number): SliderInfo {
      const roundingCorrection = 0.001;
      const slidesPerGroup = Math.floor(cw / iw + roundingCorrection);
      const numSlideGroups = Math.ceil(numSlides / slidesPerGroup);
      
      return {
        itemWidth: iw,
        numSlideGroups: numSlideGroups,
        slidesPerGroup: slidesPerGroup
      };
    }
    
  }
  
  type SliderPageInfo = {
    fromIndex: number,
    toIndex: number,
    showPagerBackward: boolean,
    showPagerForward: boolean
  };
  
  type SliderInfo = {
    itemWidth: number,
    slidesPerGroup: number,
    numSlideGroups: number
  };
  
}
