interface JQuery {
  emptyToOptional(): JQuery<HTMLElement> | undefined;
  selfOrClosest(selector: JQuery.Selector | Element | JQuery): JQuery<HTMLElement>;
  selfOrFind(selector: JQuery.Selector | Element | JQuery): JQuery<HTMLElement>;
}

$.fn.emptyToOptional = function (): JQuery<HTMLElement> | undefined {
  return this.length === 0 ? undefined : this;
};

$.fn.selfOrClosest = function (selector: JQuery.Selector | HTMLElement | JQuery<HTMLElement>): JQuery<HTMLElement> {
  const $result = this.filter(selector);
  return $result.length === 0 ? this.closest(selector) : $result;
};

$.fn.selfOrFind = function (selector: JQuery.Selector | HTMLElement | JQuery<HTMLElement>): JQuery<HTMLElement> {
  const $result = this.filter(selector);
  return $result.length === 0 ? this.find(selector) : $result;
};
  
namespace eh {
  
  export class URLHelper {
    
    public static parse(url: string): HTMLHyperlinkElementUtils {
      const a:HTMLAnchorElement = document.createElement('a');
      a.setAttribute('href', url);
      return a as HTMLHyperlinkElementUtils;
    }
    
    public static getQueryString(url: string) {
      const qi = url.indexOf('?');
      const hi = url.indexOf('#');
      return (qi === -1) ? '' : url.substring(qi + 1, (hi === -1) ? url.length : hi);
    }
    
    public static buildUrl(url: string, queryString: string, fragment?: string) {
      const qi = url.indexOf('?');
      const hi = url.indexOf('#');
      const h = fragment ?? ((hi === -1) ? '' : url.substring(hi + 1, url.length));
      
      return url.substring(0, (qi === -1) ? ((hi === -1) ? url.length : hi) : qi) + (queryString ? '?' + queryString : '') + (h ? '#' + h : '');
    }
    
    public static buildQueryString(params: QueryParams): string {
      const qs = [];
      for (const k in params) {
        if (!params.hasOwnProperty(k)) {
          continue;
        }
        const v = params[k];
        if (v instanceof Array) {
          v.forEach((vv) => {
            qs.push([encodeURIComponent(k), encodeURIComponent(vv)].join('='));
          });
        }
        else {
          qs.push([encodeURIComponent(k), encodeURIComponent(v)].join('='));
        }
      }
      return qs.join('&');
    }
    
    public static buildQueryParamMap(queryString: string): QueryParams {
      return URLHelper.buildNormalizedQueryParamMap(queryString, false);
    }

    public static buildNormalizedQueryParamMap(queryString: string, omitIndex: boolean): QueryParams {
      const params: QueryParams = {};
      if (queryString) {
        if (queryString[0] === '?') {
          queryString = queryString.substring(1);
        }
        const regex = /(\S+)(\[\d*\])/s;
        let m;
        queryString.split('&').forEach((kv) => {
          const p = kv.split('=');
          let k = decodeURIComponent(p[0]);
          if(omitIndex && ((m = regex.exec(k)) !== null)) {
            k = m[1]+'[]';
          }
          let v: string | string[] = decodeURIComponent(p[1]) || '';
          if (k in params) {
            const x = params[k];
            if (x instanceof Array) {
              x.push(v);
              v = x;
            } else {
              v = [x, v];
            }
          }
          params[k] = v;
        });
      }
      return params;
    }
    
    //noinspection JSUnusedGlobalSymbols
    public static mergeQueryParams(a: QueryParams, b: QueryParams, mergeCallBack?: (name: string, valueA: string | string[], valueB: string | string[]) => string | string[] | null): QueryParams {
      const params: QueryParams = {};
      for (let k in a) {
        if (!a.hasOwnProperty(k)) {
          continue;
        }
        if (k in b) {
          if (mergeCallBack) {
            const v = mergeCallBack(k, a[k], b[k]);
            if (v !== null) {
              params[k] = v;
            }
          }
        }
        else {
          params[k] = a[k];
        }
      }
      for (let k in b) {
        if (!b.hasOwnProperty(k) || k in a) {
          continue;
        }
        params[k] = b[k];
      }
      return params;
    }
    
    //noinspection JSUnusedGlobalSymbols
    public static firstWins(name: string, valueA: string | string[], _valueB: string | string[]): string | string[] | null {
      return valueA;
    }
    
    //noinspection JSUnusedGlobalSymbols
    public static latterWins(name: string, _valueA: string | string[], valueB: string | string[]): string | string[] | null {
      return valueB;
    }
    
    public static combine(name: string, valueA: string | string[], valueB: string | string[]): string | string[] | null {
      const combined: string[] = [];
      if (valueA instanceof Array) {
        combined.concat(valueA);
      }
      else {
        combined.push(valueA);
      }
      if (valueB instanceof Array) {
        combined.concat(valueB);
      }
      else {
        combined.push(valueB);
      }
      return Array.from(new Set(combined));
    }
    
    public static join(name: string, valueA: string | string[], valueB: string | string[]): string | string[] | null {
      return (URLHelper.combine(name, valueA, valueB) as string[])?.join(',');
    }
    
  }
  
  export type QueryParams = {[key: string]: string | string[]};

  export class DeferredPromise<T> {

    // not supported IE && Node
    // [Symbol.toStringTag] = 'Promise';
    
    private readonly _promise: Promise<T>;
    public execute: (this: DeferredPromise<T>) => void;
    public resolve: (value: T | PromiseLike<T>) => void;
    public reject: (e?: Error) => void;
    public then;
    public catch;
    public finally;
    
    constructor(execute: (this: DeferredPromise<T>) => void) {
      this.execute = execute.bind(this);
      this._promise = new Promise<T>((_resolve, _reject) => {
        this.resolve = _resolve;
        this.reject = _reject;
      });
      this.then = this._promise.then.bind(this._promise);
      this.catch = this._promise.catch.bind(this._promise);
      this.finally = this._promise.finally.bind(this._promise);
    }
  }
  
  
  export function debounce<T extends Function>(cb: T, delay: number = 20) {
    let h: number;
    const callable = (...args: any) => {
      window.clearTimeout(h);
      h = window.setTimeout(() => cb(...args), delay);
    };
    return <T>(<any>callable);
  }
  
  export function formatString(str: string, ...varargs: any[]) {
    const args = arguments;
    let i = 0;
    return str.replace(/(%%)|(%(?:\d\$)?s)|(%(?:\d\$)?d)|(%(?:\d\$)?(\.\d)?f)/g,
         (match, escapeSequence, stringParam, intParam, floatParam, precision) => {
      if (escapeSequence) {
        return '%';
      }
      const argPos = /%(\d+)\$/.exec(match);
      const arg = argPos === null ? args[++i] : args[parseInt(argPos[1])];
      if (arg === undefined) {
        return '';
      }
      if (stringParam) {
        return arg;
      }
      if (intParam) {
        return parseInt(arg);
      }
      if (floatParam) {
        const dec = precision ? parseInt(precision.substring(1)) : 0;
        const mult = Math.pow(10, dec);
        let val = '' + (Math.round(parseFloat(arg) * mult) / mult);
        const idx = val.indexOf('.');
        let missing = idx === -1 ? dec : dec - (val.length - idx) + 1;
        if (missing > 0) {
          if (idx === -1) {
            val += '.';
          }
          for (; missing > 0; missing--) {
            val += '0';
          }
        }
        return val;
      }
      return '';
    });
  }

  /**
   * getter to retrieve iOs or android mobile platform
   */
  export function isMobileOS(): boolean {
    const win:IWin = window as IWin,
        browserInfo: any = win.browserDetect();
      return !(!browserInfo.mobile && !(browserInfo.os.toLowerCase().indexOf('android') !== -1));
  }

  export function isIOSSafari(): boolean {
    const win:IWin = window as IWin,
        browserInfo: any = win.browserDetect();
    return browserInfo.mobile;
  }

  export function iosDebugConsole(msg: string): void {
    let iosConsole: JQuery<HTMLElement> = $('.ios-debug-console', document.body);
    if (!iosConsole.length) {
      iosConsole = $(`<p onclick="$(this).text('');" ondblclick="$(this).remove();" class="ios-debug-console eh-p eh-block-theme--02-shadow eh-scroll-vertical" style="
                    position: fixed;
                    font-size: 10px;
                    line-height: 12px;
                    width: 200px;
                    height: 200px;
                    z-index: 9999999;
                    top: 50%;
                    transform: translate(0, -50%); 
                    left: 50px;"></p>`);
      $(document.body).prepend(iosConsole);
    }
    iosConsole.text(`${iosConsole.text()} \n ${msg}`);
  }

  type offsetDirections = 'offsetTop' | 'offsetLeft';

  export function offsetToTarget(el: HTMLElement, dir: offsetDirections, target: Element): number {
    if (!target.contains(el)) {
      return NaN;
    }
    let offset: number = 0;
    const isDirectParent: (parent: Element) => boolean = (parent: Element): boolean => parent === target;
    const addRecurse: (el: HTMLElement) => void = (el: HTMLElement): void => {
      offset += el[dir];
      if (!!el.offsetParent && !isDirectParent(el.offsetParent)) {
        addRecurse(el.offsetParent as HTMLElement);
      }
    };
    addRecurse(el);
    return offset;
  }

  export function offsetTopToBody(el: HTMLElement): number {
    return offsetToTarget(el, 'offsetTop', document.body);
  }

  export function offsetLeftToBody(el: HTMLElement): number {
    return offsetToTarget(el, 'offsetLeft', document.body);
  }

  export function nodelistToArray(_l: NodeListOf<Element>): HTMLElement[] {
    const result: HTMLElement[] = [];
    for (let i = _l.length >>> 0; i--;) {
      result[i] = _l[i] as HTMLElement;
    }
    return result;
  }

  export const NOOP: () => void = (): void => {};

}
