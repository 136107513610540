namespace eh {

  export class Constants {
    public static readonly VISIBILITY_CHANGE: string = 'eh-visibility-change';
  }

  export class TAB_EVENTS {
    public static readonly TAB_CHANGE: string = 'eh-tab-on-change';
    public static readonly TAB_CONTENT_SHOWN: string = 'eh-tab-on-content-shown';
    public static readonly TAB_ACTIVATE_REQUEST: string = 'eh-tab-on-activate-request';
  }

  export class FILTER_BUTTON_EVENTS {
    public static readonly STICKY_CHANGE: string = 'eh-filter-button-sticky-change';
  }

  export class NAV_FLYOUT_EVENTS {
    public static readonly FLYOUT_EXPANDED: string = 'eh-flyout-expanded';
  }

  
  
  export enum UIStateValue {
    INIT = "ui-init", 
    OPENED = "ui-opened",
    CLOSED = "ui-closed",
    ENABLED = "ui-enabled",
    DISABLED = "ui-disabled"
  }

  export interface UIStateProperty {
    readonly property: string;
    readonly maxDispatch: number;
  }

  export class UIStateChanged {
    public static readonly uiStateMarketHeader: Readonly<UIStateProperty>  =  {property: 'marketHeader', maxDispatch: 1 };
    public static readonly uiStateMainNav: Readonly<UIStateProperty>  =  {property: 'mainNav', maxDispatch: 1 };
    public static readonly uiStateSearchPanel: Readonly<UIStateProperty>  =  {property: 'searchPanel', maxDispatch: 1 };
    private dispatchCount: number = 0;
    constructor(
      public context: HTMLElement,
      public property: UIStateProperty,
      public state: UIStateValue
    ) {
    }

    public getDispatchCount():number {
      return this.dispatchCount;
    }

    /* Adding counter to dispatch loop; allows to have sequential reactions to changes */
    nextDispatch():boolean {
      this.dispatchCount += 1;
      return this.property.maxDispatch >= this.dispatchCount;
    }
  }

  export class UIObservableSupport {
    private _listener: {(last: UIStateChanged, current: UIStateChanged): void;}[] = [];
    private lastState: UIStateChanged | undefined; 
    constructor(
      public readonly stateProp: UIStateProperty
    ) {
    }

    init(context: HTMLElement) {
      if(!this.lastState && !!context) {
        this.lastState = new UIStateChanged(context, this.stateProp, UIStateValue.INIT);
        //console.log('uiSupport / ', this.stateProp.property, ': ', this.lastState.state);
      }
    }

    dispatch(value: UIStateValue, ctx?: HTMLElement): void {
      if(this.lastState && value != this.lastState.state) {
        let last: UIStateChanged = this.lastState;
        let chg = new UIStateChanged(ctx || this.lastState.context, this.stateProp, value);
        while(chg.nextDispatch()) {
          //console.log('uiSupport / ', this.stateProp, '[',chg.getDispatchCount(), ']: ', last.state, ' -> ', value);
          this._listener.forEach(_l => _l(last, chg));
        }
        this.lastState = chg;
      }
    }

    public registerUIStateListener(listener: (last: UIStateChanged, current: UIStateChanged) => void): void {
      this._listener.push(listener);
      if (this.lastState) {
        listener(this.lastState, this.lastState);
      }
    }

    public unregisterUIStateListener(listener: (last: UIStateChanged, current: UIStateChanged) => void): void {
        const idx: number = this._listener.indexOf(listener);
        if (idx > -1) {
            this._listener.splice(idx, 1);
        }
    }

  }
}
