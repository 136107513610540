
interface EhLoginButtonOptions {}

interface JQuery {
    ehLoginButton(optionsOrKey?: Partial<EhLoginButtonOptions> | ehLoginButtonCommands,
                     value?: string): JQuery;
}

type ehLoginButtonCommands = 'login' | 'logout';

/* e.g.:
  <a href="#" class="eh-login-button eh-link eh-icon-button eh-icon-button--large eh-button--02"
     data-label="Login"
     onclick="this.classList.add('is-logged-in'); this.classList.add('has-label'); this.querySelector('.eh-label').innerText = 'FP'; return false;">
    <i class="eh-icon icon-eh-user"></i>
    <span class="eh-label">Login 2</span>
  </a>
*/

$.fn.ehLoginButton = function (optionsOrCommand?: Partial<EhLoginButtonOptions> | ehLoginButtonCommands,
                                  value?: string | number | boolean): JQuery {

    const config: EhLoginButtonOptions = {};

    if (typeof optionsOrCommand === 'string') {
        if (optionsOrCommand === 'login') {
            this.each((_index, el) => {
                const $original: JQuery<HTMLElement> = $(el);
                const $label: JQuery<HTMLElement> = $('.eh-label', $original);
                if (!!value) {
                    // set abbreviation
                    $original.addClass('is-logged-in');
                    $original.addClass('has-label');
                    $label.text(value);
                } else {
                    // show icon
                    $original.addClass('is-logged-in');
                    $original.removeClass('has-label');
                }
            });
        }
        if (optionsOrCommand === 'logout') {
            this.each((_index, el) => {
                const $original: JQuery<HTMLElement> = $(el);
                const $label: JQuery<HTMLElement> = $('.eh-label', $original);
                // reset
                $original.removeClass('is-logged-in');
                $original.removeClass('has-label');
                $label.text($original.data('label'));
            });
        }
    } else {
        $.extend(config, optionsOrCommand);
        this.each((_index, el) => {
            const $original: JQuery<HTMLElement> = $(el);
            const $label: JQuery<HTMLElement> = $('.eh-label', $original);

            if (!$original.data('label')) {
                throw new Error('default label is missing');
            }

            $label.text($original.data('label'));
        });
    }

    return this;
};
