namespace eh {
  
  export class IframeScale {

    static init($base: JQuery<HTMLElement>): void {
        $('.eh-iframe-scaled').each((_index, element) => {
            const iframe = $(element);
            const baseline = parseInt(iframe.data('original-width') || '1270');
            const width = iframe.width() || 0;
            const scaled = Math.min((width / baseline), 1);
            iframe.css({'transform': 'scale(' + scaled  + ')', 'width': baseline + 'px'});
            if(iframe.hasClass('eh-iframe-scale-height')) {
                const parent = iframe.parent();
                iframe.height((parent.innerHeight() || 0) * (1 / scaled));
            }

            const resizeFrame = this.debounce(function() {
                const parentWidth = iframe.parent().width() || 0;
                const scaledResized = Math.min((parentWidth / baseline), 1);
                iframe.css({'transform': 'scale(' + scaledResized  + ')'});
                if(iframe.hasClass('eh-iframe-scale-height')) {
                    const parent = iframe.parent();
                    iframe.height((parent.innerHeight() || 0) * (1 / scaledResized));
                }
            }, 250);
            
            window.addEventListener('resize', resizeFrame);
        });
    }

    private static debounce<F extends Function>(func: F, wait: number):F {
        let timeout: number;
      
        return <F><any>function(this: any, ...args: any[]) {
            clearTimeout(timeout);
            const context = this;
      
            timeout = window.setTimeout(function() {
                  func.apply(context, args);
            }, wait);
         };
      };

  }

}
