namespace eh {
    /**
     * The nexus represents a global registration on messaging through {@link window.postMessage}.
     * Each window is represented by a {@link MessageNode}.
     * A {@link MessageNode} handles all {@link IMessageHandler} registered for that window.
     * The messages payload must be of format {@link Message}.
     * The nexus listens for messages on the top level window. 
     * Nodes send messages to listeners by top level window method.
     * Senders are identified by their event.source Window
     */
    export class MessageNexus {
        static instance : MessageNexus = new MessageNexus;

        static init() {

        }
        private _msgNodes: MessageNode[] = [];

        /** Pass through to message node */
        public static setListener(source: Window, callback: IMessageHandler) {
            eh.MessageNexus.instance.getMessageNode(source).setListener(callback);
        }

        /** Pass through to message node */
        public static removeListener(source: Window, callback: IMessageHandler) {
            eh.MessageNexus.instance.getMessageNode(source).removeListener(callback);
        }

        /** get or create node for provided window */
        public getMessageNode(target: Window): MessageNode {
            // exists?
            for (let msgNode of this._msgNodes) {
                if (msgNode.getTarget() === target)
                    return msgNode;
            }
            // else create
            let msgNode = new MessageNode(target);
            this._msgNodes.push(msgNode);
            return msgNode;
        }
    
        static _onMessage(e: MessageEvent) {
            // message event received
            let isAllowed = false;
            try {
                isAllowed = (e && e.source && (<Window>e.source).parent === window) === true;
              } catch (e) {}
            if(!isAllowed) {
                return;
            }
            let msg : Message | null = null;
            try {
                if(typeof e.data === 'string') {
                    msg = Message.fromLegacyString(e.data);
                } else {
                    msg = e.data;
                }
            } catch(e) {
                return;
            }

            if(msg) {
                msg.params = msg.params ? msg.params : {};
                eh.MessageNexus.instance
                    .getMessageNode((<Window>e.source))
                    .messageReceived(msg, e);
            }
        }
    }
    window.addEventListener('message', eh.MessageNexus._onMessage);

    /** 
     * The MessageNode represents one window's messaging service. 
     * IMessageHandlers register here with an unique id.
     */
    export class MessageNode {
        private _listeners: { [index: string]: IMessageHandler } = {};

        private readonly _target: Window;

        constructor (target: Window) {
            this._target = target;
        }
        
        getTarget(): Window {
            return this._target;
        }

        setListener(callback: IMessageHandler) {
            this._listeners[callback.id] = callback;
        }

        removeListener(callback: IMessageHandler) {
            // stop listening
            delete this._listeners[callback.id];
        }

        messageReceived(data: Message, _event: MessageEvent) {
            // message received
            for (let key in this._listeners) {
                this._listeners[key].onMessage(data);
            }
        }
    }

    /**
     * A Message is send by window.postMessage()
     * Message has following fields:
     * @param method the requested actions name; 
     * Use 'params' object to add key | value strings 
     */
    export class Message {
        params: { [index: string]: string } = {};

        static fromLegacyString(data: string) : Message | null {
            let m = data.match(/^eho-iframe:(seamless\d):(height|refresh):(\d+)?:([a-z0-9\-]+)?$/);
            let msg : Message | null = null;
            if (m) {
                msg = new Message(m[2].substring(0, 4) !== 'eho:' ? 'eho:'+m[2] : m[2]);
                msg.putParam('$$legacy', 'eho-iframe:');
                if(m[1]) {
                    msg.putParam('seamlessClass',m[1]);
                }
                if(m[3]) {
                    msg.putParam('height', m[3]);
                }
                if(m[4]) {
                    msg.putParam("id", m[4]);
                 }
            }
            return msg;  
        }
        constructor (public readonly method: string) { }

        public putParam(k:string,v:string) {
             this.params[k] = v;
        }

        public asLegacyParam() :string {
            let me = this.method.substring(0, 4) === 'eho:' ? this.method.substring(4) : this.method;
            let res = 'eho-iframe:'+me;
            res = res + (this.params['seamlessClass']?this.params[':seamlessClass']:'');
            res = res + (this.params['param']?this.params[':param']:'')+':';
            res = res + (this.params['id']?this.params['id']:'');
            return res;
        }
    }
    
    export interface IMessageHandler {
        id: string;
        onMessage(message: Message): void;
    }
    
}