namespace eh {

  export class PreventRubberbandScroller {

    public readonly _scrollContainerSelector: string[] = ['.eh-no-rubberband-scroll-trigger'];
    public scroller: JQuery<HTMLElement>;
    public static instance: PreventRubberbandScroller;

    static init($base: JQuery<HTMLElement>): void {

      if (!PreventRubberbandScroller.instance) {
        PreventRubberbandScroller.instance = new PreventRubberbandScroller();
      }

      const instance: PreventRubberbandScroller = PreventRubberbandScroller.instance;
      if (instance.scroller && instance.scroller.length) {
        instance.scroller.off();
      }
      instance.scroller = $(instance._scrollContainerSelector.join(','));

      if (!instance.scroller || !instance.scroller.length) {
        return;
      }

      instance.scroller.each(
        (idx: number, el: HTMLElement): any => new NoRubberbandScroller(el)
      );

    }

  }

  class NoRubberbandScroller {

    private _clientY: number = -1;

    constructor(private _el: HTMLElement) {
      this.register(_el);
    }

    register(el: HTMLElement): void {
      const hel: HTMLElement = el;
      const eventParams: any = eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
      hel.addEventListener('touchstart', this.onTouchStart, eventParams);
      hel.addEventListener('touchmove', this.onTouchMove, eventParams);
    }

    public onTouchStart: any = (e: any): void => {
      if (e.targetTouches.length === 1) {
        this._clientY = e.targetTouches[0].clientY;
      }
    };

    public onTouchMove: any = (e: any): void => {
      if (e.targetTouches.length === 1) {
        this.disableRubberBand(e);
      }
    };

    private disableRubberBand(e: any): void {
      const clientY: number = e.targetTouches[0].clientY - this._clientY,
            __el: HTMLElement = this._el;

      if (__el.scrollHeight === __el.clientHeight) {
        e.stopImmediatePropagation();
        e.preventDefault();
      }

      if (__el.scrollTop === 0 && clientY > 0) {
        e.stopImmediatePropagation();
        e.preventDefault();
      }

      if (this.isOverlayTotallyScrolled() && clientY < 0) {
        e.stopImmediatePropagation();
        e.preventDefault();
      }
    }

    private isOverlayTotallyScrolled(): boolean {
      const __el: HTMLElement = this._el;
      return __el.scrollHeight - __el.scrollTop <= __el.clientHeight;
    }

  }

}