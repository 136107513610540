namespace eh {
  
    export class FlexInfo {

        static init(base:JQuery<HTMLElement>):void {
            //console.log("base:", base);
            base.find('.cs-flex-info').each(function (_index, element) {
                var segm = $(element).data('flexSegment');
                if(segm) {
                    $(element).on('click', (event: JQuery.TriggeredEvent) => {
                        event.preventDefault();
                        FlexInfo.showId('flex-info-'+segm, $(element));
                    });
                }
            });
            base.find('.cs-flex-desc').each(function (_index, element) {
                $(element).on('click', (event: JQuery.TriggeredEvent) => {
                    event.preventDefault();
                    FlexInfo.showDesc($(element));
                });
            });
        }
        static showId(id:string, elem:JQuery<HTMLElement>) {
            const msg = new Message('*:open-static2-id');
            msg.putParam('title', $('#'+id).data('heading'));
            msg.putParam('id', id);
            window.postMessage(msg, '*');
        }
        static showDesc(elem:JQuery<HTMLElement>) {
            const msg = new Message('*:open-static2-wide-id');
            msg.putParam('title', $('#flex-info-desc').data('heading'));
            msg.putParam('id', 'flex-info-desc');
            window.postMessage(msg, '*');
        }
    }

}