namespace eh {
  
  /*
   - show or hide language selector
   - update button link with target url
   - execute link and close overlay
   - execute close / goto-www link and close overlay
   - open overlay on startup
   - set cookie before execution
   */
  export class MarketSelector {
    
    public static readonly SELECTOR = '.marker-market-selector ';
    public static readonly DATA_KEY = 'ehMarketSelector';
    public static readonly HOLDER_ID = 'marketChooserHolder';
    
    private static readonly CLASS_HIDDEN = 'eh--hide';
    public static uiStateSupport: eh.UIObservableSupport = new UIObservableSupport(UIStateChanged.uiStateMarketHeader);
    
    static init($base: JQuery<HTMLElement>): void {

      $(MarketSelector.SELECTOR, $base).each((_index, el) => {
        const $marketChooserRoot = $(el);
 
        const mc = new MarketSelector($marketChooserRoot);
        $marketChooserRoot.data(MarketSelector.DATA_KEY, mc);
        $marketChooserRoot.one(eh.Overlay.EventIdPreClose, () => {
          mc.rememberCountryChooserShown();
        });
        if(el.classList.contains('marker-market-selector-header')) {
          MarketSelector.uiStateSupport.init(el);
        }
 
      });
      
    }
    
    private readonly $buttonChoose: JQuery<HTMLElement>;
    private readonly $selectCountryAndLanguage: JQuery<HTMLElement>;

    constructor(public readonly $elem: JQuery<HTMLElement>) {
      this.$selectCountryAndLanguage = $('.ehel-language-selector-select', this.$elem);
      this.$buttonChoose = $('.trigger-choose-market', this.$elem);
      this.$selectCountryAndLanguage.on('change', this.handleCountryChange);
      this.$buttonChoose.on('click', this.handleChooseButtonClick);
      this.handleCountryChange();
      $('.js-market-chooser--close-button', this.$elem).on('click', () => this.closeBanner());
      if(!this.$selectCountryAndLanguage.hasClass('select2-hidden-accessible')) {
        this.$selectCountryAndLanguage.select2({
          theme: 'pc-endress-inline',
          containerCssClass: 'eh-link--dropdown-arrow', // add custom flavor
          dropdownAutoWidth: true,
          dropdownCssClass: 'ehel-language-selector-select--dropdown',
          width: 'auto',
          minimumResultsForSearch: -1,
          templateResult: this.goToLanguageChooseLinkFormatter,
          templateSelection: this.goToLanguageChooseLinkFormatter
        })
        .show()
      }
      $elem.on('mousedown', 'textarea,.select2', function(event) {
        event.currentTarget.classList.add('focus-click');
      });

      $elem.on('blur', '.select2', function(event) {
        event.currentTarget.classList.remove('focus-click');
        event.currentTarget.classList.remove('select2-container--focus');
      });

      $elem.on("focus.select2", 'select', function(event) {
        setTimeout(() => {
          $(event.target).select2('open');
          $(event.target).siblings('.select2').addClass('focus-click');
        }, 10);
      });
      Breakpoints.getInstance().registerChangeListener((_old: Breakpoint | null, _current: Breakpoint): void => {
        if ($('.ehel-language-selector-select').select2) {
            $('.ehel-language-selector-select').select2('close');
        }
      });

      document.addEventListener('scroll', function() {
        if ($('.ehel-language-selector-select').select2) {
          $('.ehel-language-selector-select').select2('close');
        }
      }, {
        passive: true,
      });
    }
    
    private goToLanguageChooseLinkFormatter(optionEl: ISelectOptionWrapper) {
      if (!optionEl.id
          || optionEl.element && $(optionEl.element).data('eh-key') !== 'LANGUAGE_CHOOSER_LINK_OPTION'
      ) {
          return optionEl.text;
      }
      const $optionEl = $('<span class="eh-select--language-selector-link ehel-language-link">' + optionEl.text + '</span>');
      return $optionEl;
    };

    private handleCountryChange = () => {
      const $selected = $('option:selected', this.$selectCountryAndLanguage);
      const val = $selected.val();
      if($selected.data('eh-key') !== 'LANGUAGE_CHOOSER_LINK_OPTION') {
        this.$buttonChoose.attr('href', val as string).data('chosenTarget', $selected.data('chosenTarget'));
      } else {
        this.rememberCountryChooserShown();
        eh.Tracking.injectTrackingEvent({'event_leaving_website': 'exit_other_application', 'event_outbound_link': ''+val }, $selected, 'content_link');
        window.location.href = '' + val;
      }
    };
    
    private handleChooseButtonClick = () => {
      const marketChooserTarget = this.$buttonChoose.data('chosenTarget');
      eh.setCookie('ii-country-redirect', marketChooserTarget, 90, false, 'Lax');
      this.rememberCountryChooserShown();
    };
  
    public rememberCountryChooserShown() {
      eh.setGlobalCookie('country-chooser-used', '1', 0, false, 'Lax');
    }

    public closeBanner() {
      this.rememberCountryChooserShown();
      this.$elem.remove();
      if(this.$elem.hasClass('marker-market-selector-header')) {
        MarketSelector.uiStateSupport.dispatch(UIStateValue.CLOSED);
      }
    }

  }
}