namespace eh {
  
  export class LoadingIndicator {
    
    private static spinner: JQuery<HTMLElement> | null;

    static init(): void {
    }

    /** find closest indication target and activate */
    public static showLoadingByEv(event: JQuery.TriggeredEvent):void {
      if(event && event.target instanceof HTMLElement) {
        let evTarget = <HTMLElement> event.target;
        this.showLoading($(evTarget));
      };
    }

    /** find closest indication target and activate */
    public static showLoading(target: JQuery<HTMLElement> | null):void {
      if(target) {
        LoadingIndicator.showSpinner(LoadingIndicator.findLoadCtrl(target));
      }
    }

    /** hide current indicator */
    public static hideLoading():void {
      LoadingIndicator.hideSpinner();
    }

    /** find closest elem to add indicator, null if 'no indication' is closer */
    private static findLoadCtrl(target: JQuery<HTMLElement>): JQuery<HTMLElement> | null {
      if(target) {
        let ctrl = target.closest('.eh-load-wrapper, .eh-noload-wrapper');
        if(ctrl && ctrl.hasClass('eh-load-wrapper')){
          if(ctrl.hasClass('eh-load-ctrl')) {
            return ctrl;
          }
          return ctrl.find('.eh-load-ctrl');        
        }
      }
      return null;
    }

    private static showSpinner(loadCtrl: JQuery<HTMLElement> | null): void {
      if(loadCtrl && !loadCtrl.hasClass('is-loading')) {
        LoadingIndicator.hideSpinner();
        loadCtrl.addClass('is-loading');
        LoadingIndicator.spinner = loadCtrl;
      }
    }

    private static hideSpinner(): boolean {
      if(LoadingIndicator.spinner)  {
        LoadingIndicator.spinner.removeClass('is-loading');
        LoadingIndicator.spinner = null;
        return true;
      }
      return false;
    }
  }
}
