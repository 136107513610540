namespace eh {
  
  export class SiteSearch {
  
    private static readonly CLASS__SEARCH_SHOW_DROPDOWN = 'eh-header-search-bar--has-focus';
    private static readonly CLASS__ACTIVE = 'focus';
    private static readonly CLASS__DISABLED = 'disabled';
    private static readonly CLASS__HIDDEN = 'eh--hide';
    
    private static readonly SELECTOR__BACK_ICON = '.eh-header-search-bar--back-button .ehel-icon';
    private static readonly SELECTOR__HELP = '.marker-searchbar-dropdown-help';
    private static readonly SELECTOR__HISTORY_CONTAINER = '.marker-dropdown-list-history';
    private static readonly SELECTOR__HISTORY_LIST = '.marker-searchbar-dropdown-history-list';
    private static readonly SELECTOR__HISTORY_LIST_ITEM_TEMPLATE = '.marker-searchbar-dropdown-historyitem-template';
    private static readonly SELECTOR__CLUE_CONTAINER = '.marker-history-dropdown-clue';
    private static readonly SELECTOR__LIST_CONTAINER = '.marker-history-result-container';
    private static readonly SELECTOR__PROGRESS_INDICATOR = '.eh-header-search-bar--progress-indicator';
    private static readonly SELECTOR__QUICKRESULTS_CONTAINER = '.marker-quicksearch-container';
    private static readonly SELECTOR__QUICKRESULTS = '.marker-quicksearch-results';
    private static readonly SELECTOR__RESULT_LIST_ITEM = '.eh-result-list--item';
    private static readonly SELECTOR__RESULT_LIST_ITEM_CONTENT = '.eh-result-list--item-content';
    private static readonly SELECTOR__SEARCH_FORM = 'form';
    private static readonly SELECTOR__SEARCH_INDICATOR = '.eh-header-search-bar--search-indicator';
    private static readonly SELECTOR__SEARCH_INPUT = '.eh-header-search-bar--filter-input';
    private static readonly SELECTOR__SEARCH_RESET_BUTTON = '.eh-header-search-bar--clear-search-button';
    private static readonly SELECTOR__SEARCH_RESULT_CONTAINER = '.marker-search-result-container';
    private static readonly SELECTOR__SEARCH_RESULTS = '.eh-header-search-results';
    private static readonly SELECTOR__SEARCH_SUBMIT_BUTTON = '.eh-SearchSubmit';
    private static readonly SELECTOR__SEARCH_SUBMIT_BUTTON_MOBILE = '.eh-header-search-bar--submit-search-button-mobile';
    private static readonly SELECTOR__SEARCH_TERM = '.marker-text-term-content';
    private static readonly SELECTOR__SEARCH_TERM_ITEM = '.marker-item-text';
    private static readonly SELECTOR__SITE_SEARCH = '.eh-siteSearch';

    private static readonly SELECTOR__DROPDOWN_WRAPPER = '.eh-header-search-results';
    private static readonly SELECTOR__DROPDOWN_CONTENT = '.eh-search-bar-results--transform-content';

    public static uiStateSupport: eh.UIObservableSupport = new UIObservableSupport(UIStateChanged.uiStateSearchPanel);

    public static init($base: JQuery<HTMLElement>, _isSnippetRequest: boolean = false): void {
      //console.log('SiteSearch.init', _isSnippetRequest);
      const $siteSearchElem = $base.selfOrFind(SiteSearch.SELECTOR__SITE_SEARCH).emptyToOptional();
      if (!$siteSearchElem) {
        return;
      }
      new SiteSearch($siteSearchElem);
  
      const globalSearchBar: JQuery<HTMLElement> = $('.eh-header-search-bar', $base);
      const viewTargetContainerMobile: JQuery<HTMLElement> = $('.eh-mobile-search-target-container', $base);
      const viewTargetContainerDesktop: JQuery<HTMLElement> = $('.eh-desktop-search-target-container', $base);
  
      eh.Breakpoints.getInstance().registerViewTypeListener((viewType: BreakPointViewType): void => {
        // transplant the search component into view-context.
        // we need to place the search inside the page when viewtype is mobile
        // because the header is removed from the page.
        switch (true) {
          case viewType === BreakPointViewType.MOBILE:
            globalSearchBar.appendTo(viewTargetContainerMobile);
            break;
          case viewType === BreakPointViewType.DESKTOP:
            globalSearchBar.appendTo(viewTargetContainerDesktop);
            break;
        }
      });
    }
    
    private readonly history: HistoryStorage;
  
    private readonly $backIcon: JQuery<HTMLElement>;
    private readonly $document: JQuery<HTMLElement>;
    private readonly $form: JQuery<HTMLFormElement>;
    private readonly $input: JQuery<HTMLInputElement>;
    private readonly $progressIndicator: JQuery<HTMLElement>;
    private readonly $searchButton: JQuery<HTMLElement>;
    private readonly $searchButtonMobile: JQuery<HTMLElement>;
    private readonly $searchIndicator: JQuery<HTMLElement>;
    private readonly $resetButton: JQuery<HTMLElement>;
    private readonly $searchResultContainer?: JQuery<HTMLElement>;
    
    private readonly $helpContainer: JQuery<HTMLElement>;
    private readonly $termItem: JQuery<HTMLElement>;
    private readonly $listContainer: JQuery<HTMLElement>;
    private readonly $historyContainer: JQuery<HTMLElement>;
    private readonly $historyClueContainer: JQuery<HTMLElement>;
    private readonly $quickResultsContainer: JQuery<HTMLElement>;
    private readonly $historyList: JQuery<HTMLElement>;
    private readonly $historyListItemTemplate: JQuery<HTMLElement>;
    private readonly $searchResults: JQuery<HTMLElement>;

    private readonly $dropdownWrapper: JQuery<HTMLElement>;
    private readonly $dropdownContent: JQuery<HTMLElement>;

    private readonly quickSearchUrl: string;
    private readonly snippetUrl?: string;
  
    private $activeItemContainer: JQuery<HTMLElement> = $('.eh-header-search-results');
    private open: boolean = false;
    private inputRegistered: boolean;
    private quickSearchTimeoutId: number = -1;
    private jqXHR: JQuery.jqXHR | null;
    private isInputMouseDown: boolean = false;
  
    private _valid: boolean = false;
    private setValid(value: boolean) {
      this._valid = value;
      this.$form.data('formValid', this._valid);
    }
  
    public isValid(): boolean {
      return this._valid;
    }
    
    public constructor(private readonly $elem: JQuery<HTMLElement>) {
      //console.log('new eh.SiteSearch');
      this.history = new HistoryStorage();
      this.$document = $(document.body);
      this.$form = $elem.selfOrFind(SiteSearch.SELECTOR__SEARCH_FORM) as JQuery<HTMLFormElement>;
      this.$backIcon = $(SiteSearch.SELECTOR__BACK_ICON, $elem);
      this.$input = $(SiteSearch.SELECTOR__SEARCH_INPUT, $elem);
      this.$progressIndicator = $(SiteSearch.SELECTOR__PROGRESS_INDICATOR, $elem);
      this.$searchButton = $(SiteSearch.SELECTOR__SEARCH_SUBMIT_BUTTON, $elem);
      this.$searchButtonMobile = $(SiteSearch.SELECTOR__SEARCH_SUBMIT_BUTTON_MOBILE, $elem);
      this.$searchIndicator = $(SiteSearch.SELECTOR__SEARCH_INDICATOR, $elem);
      this.$resetButton = $(SiteSearch.SELECTOR__SEARCH_RESET_BUTTON, $elem);
      this.$searchResultContainer = $(SiteSearch.SELECTOR__SEARCH_RESULT_CONTAINER, ':root').emptyToOptional();
      
      this.$searchResults = $(SiteSearch.SELECTOR__SEARCH_RESULTS, ':root');
      this.$helpContainer = $(SiteSearch.SELECTOR__HELP, this.$searchResults);
      this.$termItem = $(SiteSearch.SELECTOR__SEARCH_TERM_ITEM, $elem);
      this.$listContainer = $(SiteSearch.SELECTOR__LIST_CONTAINER, ':root');
      this.$quickResultsContainer = $(SiteSearch.SELECTOR__QUICKRESULTS_CONTAINER, this.$searchResults);
      this.$historyContainer = $(SiteSearch.SELECTOR__HISTORY_CONTAINER, this.$searchResults);
      this.$historyClueContainer = $(SiteSearch.SELECTOR__CLUE_CONTAINER, this.$searchResults);
      this.$historyList = $(SiteSearch.SELECTOR__HISTORY_LIST, this.$historyContainer);
      this.$historyListItemTemplate = $(SiteSearch.SELECTOR__HISTORY_LIST_ITEM_TEMPLATE, this.$historyList).remove();

      this.$dropdownWrapper = $(SiteSearch.SELECTOR__DROPDOWN_WRAPPER, ':root');
      this.$dropdownContent = $(SiteSearch.SELECTOR__DROPDOWN_CONTENT, ':root');

      this.quickSearchUrl = this.$form.data('qsUrl');
      this.snippetUrl = this.$searchResultContainer?.data('snippetUrl');

      
      this.setTerm(this.getTerm());
      this.setValid(SiteSearch.isTermValid(this.getTerm()));
      this.prepareHistoryTerms();
      this.registerControls();
      this.updateState();
      //Breakpoints.getInstance().registerChangeListener(this.updatePageBehaviour);
      SiteSearch.uiStateSupport.init($elem[0])
      NavigationController.uiStateSupport.registerUIStateListener(this.onMainNavChanged);
    }
    
    private updateState() {
      const termIsEmpty = this.isTermEmpty();
      const historyNotEmpty = this.history.getTerms().length > 0;
      //console.log('updateState', 'isValid', this.isValid(), 'termIsEmpty', termIsEmpty, 'historyNotEmpty', historyNotEmpty);
      this.$resetButton.toggleClass(SiteSearch.CLASS__HIDDEN, termIsEmpty);
      this.$searchButtonMobile.toggleClass(SiteSearch.CLASS__HIDDEN, !termIsEmpty);
      if (this.open) {
        const showHelp = !termIsEmpty && !this.isValid();
        this.$helpContainer.toggleClass(SiteSearch.CLASS__HIDDEN, !showHelp);
        const showHistory = termIsEmpty && historyNotEmpty;
        const showQuickResults = this.isValid();
        this.$historyContainer.toggleClass(SiteSearch.CLASS__HIDDEN, !showHistory);
        this.$historyClueContainer.toggleClass(SiteSearch.CLASS__HIDDEN, !showHistory);
        const showListContainer = showHistory || showQuickResults;
        this.$listContainer.toggleClass(SiteSearch.CLASS__HIDDEN, !showListContainer);
        this.$progressIndicator.toggleClass(SiteSearch.CLASS__HIDDEN, true);
        this.$quickResultsContainer.toggleClass(SiteSearch.CLASS__HIDDEN, !showQuickResults);
        this.$searchButton.toggleClass(SiteSearch.CLASS__DISABLED, !this.isValid());//toggleClass(SiteSearch.CLASS__HIDDEN, false).
        this.$searchIndicator.toggleClass(SiteSearch.CLASS__HIDDEN, true);
        this.$termItem.toggleClass(SiteSearch.CLASS__HIDDEN, !this.isValid());
        this.$termItem.selfOrFind(SiteSearch.SELECTOR__SEARCH_TERM).text(this.getTerm());
      }
      else {
          this.$helpContainer.toggleClass(SiteSearch.CLASS__HIDDEN, true);
          this.$historyContainer.toggleClass(SiteSearch.CLASS__HIDDEN, true);
          this.$historyClueContainer.toggleClass(SiteSearch.CLASS__HIDDEN, true);
          this.$listContainer.toggleClass(SiteSearch.CLASS__HIDDEN, true);
          this.$progressIndicator.toggleClass(SiteSearch.CLASS__HIDDEN, true);
          this.$quickResultsContainer.toggleClass(SiteSearch.CLASS__HIDDEN, true);
          this.$searchButton.toggleClass(SiteSearch.CLASS__DISABLED, true);//.toggleClass(SiteSearch.CLASS__HIDDEN, termIsEmpty)
          this.$searchIndicator.toggleClass(SiteSearch.CLASS__HIDDEN, !termIsEmpty);
          this.$termItem.toggleClass(SiteSearch.CLASS__HIDDEN, true);
      }
    }
    
    private isTermEmpty() {
      return this.getTerm().length === 0;
    }
    
    private registerControls() {
      //console.log('registerControls');
      this.$form.on('submit', ($event) => {
        //console.log('handle $form submit');
        if (!this.isValid()) {
          $event.preventDefault();
          return;
        }
        else {
          this.history.addTerm(this.getTerm());
          window.setTimeout(() => { // separate event flow
            this.prepareHistoryTerms();
          }, 0);
        }
        /*
        TODO: Find better solution to get rid of unwanted automatically added parameters
        */
        const unwantedParameterScopes = ['filter.', 'd.', 'e.', 's.', 'sa.', 'w.', 'leadsource'];
        this.$form.find('input[type="hidden"]').filter((_index, el) => {
          return unwantedParameterScopes.filter(v => $(el).attr('name')?.indexOf(v) === 0).length > 0;
        }).remove();
        if (this.$searchResultContainer) {
          $event.preventDefault();
          this.doSearch();
          this.closePanel();
        }
      }).data('formValid', this.isValid());
  
      this.$resetButton.on('click', ($event) => {
        //console.log('handle $resetButton click');
        $event.preventDefault();
        this.setTerm('');
        this.activateInput();
        this.updateState();
      });
      
      this.$elem.on('focusin', this.handleSearchFocusIn);
      this.$input.on('focusout', this.handleSearchFocusOut);
      
      this.addItemHandlers(this.$termItem);
      
      this.$backIcon.on('click', ($event) => {
        //console.log('handle $backIcon click');
        $event.preventDefault();
        this.closePanel();
      });

      this.$input.on('mousedown', this.onInputMouseDown);

      $(':root').on(HEADER_EVENTS.DOM_MUTATION, this.syncDropdownPosition);

    }

    private syncDropdownPosition: (e: JQuery.TriggeredEvent, metrics: IHeaderMetrics) => void = (e: JQuery.TriggeredEvent, metrics: IHeaderMetrics): void => {
      this.$dropdownWrapper.css('top', `${metrics.visibleHeaderHeight}px`);
      //this.$dropdownContent.css('padding-top', `${metrics.safetyGap}px`);
    };
    
    private handleSearchFocusIn = () => {
      //console.log('handleSearchFocusIn');
      if (this.getTerm()) {
        const len = this.getTerm().length;
        this.$input.get(0)?.setSelectionRange(len, len);
        if (this.$termItem.length) {
          SiteSearch.setListItemActive(this.$termItem);
        }
      }
      if(!this.$input.parent().hasClass('focus-click')) {
        this.$input.parent().toggleClass('focus', true);
      }
      this.openPanel();
    };

    private handleSearchFocusOut = () => {
      this.$input.parent().toggleClass('focus', false);
      this.$input.parent().toggleClass('focus-click', false);
    }

    
    private handleInputInput = () => {
      const term = this.getTerm();
      //console.log('handleInputInput', term);
      this.setValid(SiteSearch.isTermValid(term));
      this.updateState();
      if (this.isValid()) {
        this.queueQuickSearch(term);
      }
    };
  
    private addInputHandlers() : void {
      //console.log('addInputHandlers');
      if (this.inputRegistered) {
        //console.log('addInputHandlers called though inputRegistered');
        return;
      }
      this.inputRegistered = true;
      this.$document.on('click', this.handleDocumentClick);
      this.$document.on('keydown', this.handleDocumentKeyDown);
    }
  
    private removeInputHandlers() : void {
      //console.log('removeInputHandlers');
      if (!this.inputRegistered) {
        //console.log('removeInputHandlers called though !inputRegistered');
        return;
      }
      this.inputRegistered = false;
      this.$document.off('click', this.handleDocumentClick);
      this.$document.off('keydown', this.handleDocumentKeyDown);
    }
    
    private doSearch() {
      //console.log('doSearch', this.getTerm(), '$searchResultContainer', this.$searchResultContainer);
      this.cancelSearch();
      this.showLoadingIndicator();
      if (!this.$searchResultContainer) {
        this.$form.trigger('submit');
        return;
      }
      const formData = this.$form.formSerialize();
      const url = document.location.href;
      const params = $.extend({}, eh.URLHelper.buildQueryParamMap(eh.URLHelper.getQueryString(url)), eh.URLHelper.buildQueryParamMap(formData));
      history.pushState(history.state, '', eh.URLHelper.buildUrl(url, eh.URLHelper.buildQueryString(params)));
      this.jqXHR = $.ajax({
        'url': this.snippetUrl,
        'method': 'get',
        'data': formData,
        'beforeSend': ()  => {
        }
      }).done((data) => {
        //console.log('search result');
        const resultData = $(data).find('data').text();
        const $resultElem = $(resultData).selfOrFind(SiteSearch.SELECTOR__SEARCH_RESULT_CONTAINER).children();
        this.$searchResultContainer?.empty().append($resultElem);
        SiteSearch.notifyReplaced($resultElem);
      })
      .fail(function(/*jqXHR, textStatus, errorThrown*/) {
        //console.log('search failure');
      })
      .always(() => {
        this.hideLoadingIndicator();
      });
      //console.log('started jqXHR search');
    }
    
    private openPanel() {
      //console.log('openPanel');
      if (this.open) {
        //console.log('aborting openPanel');
        return;
      }

      this.open = true;
      this.$elem.off('focusin', this.handleSearchFocusIn);
      this.$input.on('input', this.handleInputInput);


      this.addInputHandlers();
      this.updateState();
      this.activateInput();
      this.$document.toggleClass(SiteSearch.CLASS__SEARCH_SHOW_DROPDOWN, true);
      /*
      if (!this.getTerm()) {
        SiteSearch.setListItemActive(this.$historyContainer.find(SiteSearch.SELECTOR__RESULT_LIST_ITEM).first(), true);
      }
      */

      if (this.isValid()) {
        this.queueQuickSearch(this.getTerm());
      }
      this.initPageBehaviour();
      SiteSearch.uiStateSupport.dispatch(UIStateValue.OPENED);

     }

     // viewmode mobile:
     // when search gets triggered while the search-slot isnt sticky
     // we force the sticky state by animation.
     private initPageBehaviour() {
       const inputTop: number = this.$input.get(0)?.getBoundingClientRect().top ?? 0;
       if (inputTop > 0 && Breakpoints.getInstance().isMobile) {
         ScrollPage.setScrollEnabled(false, 'eh-no-scroll--lt-large eh-layout--pos-fixed--lt-large eh-full-height--lt-large');
         //debounce((): void => { ScrollPage.scrollToAnimated(inputTop + ScrollPage.getScrollPosition(); }, 500)();
       } else {
         // mobile views are sealed
         ScrollPage.setScrollEnabled(false, 'eh-no-scroll--lt-large eh-layout--pos-fixed--lt-large eh-full-height--lt-large');
       }
     }

     private onMainNavChanged: (last: UIStateChanged, current: UIStateChanged) => void = (last: UIStateChanged, current: UIStateChanged): void => {
      if(eh.Breakpoints.getInstance().isMobile) {
        return;
      }
      //console.log('search rec:', current.state);
      if(current.state == UIStateValue.OPENED) {
        this.closePanel();
      }
     }

     private updatePageBehaviour: (old: Breakpoint, current: Breakpoint) => void = (_old: Breakpoint, current: Breakpoint): void => {
      if (this.open && current.isMobile) {
        debounce(() => {
          const inputTop: number = this.$input.get(0)?.getBoundingClientRect().top ?? 0;
          if (inputTop > 0) {
            ScrollPage.scrollToAnimated(inputTop + ScrollPage.getScrollPosition());
          }
        }, 100)();
      }
     };
    
    private closePanel() {
      //console.log('closePanel');
      if (!this.open) {
        //console.log('aborting closePanel');
        return;
      }
      this.open = false;
      this.$input.off('input', this.handleInputInput);
      this.$elem.on('focusin', this.handleSearchFocusIn);
      this.removeInputHandlers();
      this.$input.trigger('blur');
      window.setTimeout(() => {  // don't hide immediately, otherwise ie11 will not follow click
        if (this.open) {
          //console.log('conflicting closePanel');
        }
        this.updateState();
        ScrollPage.setScrollEnabled(true, 'eh-no-scroll--lt-large eh-layout--pos-fixed--lt-large eh-full-height--lt-large');
        this.$document.toggleClass(SiteSearch.CLASS__SEARCH_SHOW_DROPDOWN, false);
        SiteSearch.uiStateSupport.dispatch(UIStateValue.CLOSED);
      }, 200);
    }
  
    private showLoadingIndicator() {
      // @TODO: NYI
      // eh.LoadingIndicator.showLoading(this.$searchButton);
      this.$progressIndicator.toggleClass(SiteSearch.CLASS__HIDDEN, false);
      this.$resetButton.toggleClass(SiteSearch.CLASS__HIDDEN, true);
    }
  
    private hideLoadingIndicator() {
      // @TODO: NYI
      // eh.LoadingIndicator.hideLoading();
      this.$progressIndicator.toggleClass(SiteSearch.CLASS__HIDDEN, true);
      this.$resetButton.toggleClass(SiteSearch.CLASS__HIDDEN, this.isTermEmpty());
    }
    
    private getTerm(): string {
      return '' + this.$input.val();
    }
  
    private setTerm(term: string) {
      this.$input.val(term);
      this.setValid(SiteSearch.isTermValid(term));
    }
    
    private cancelSearch() {
      if (this.quickSearchTimeoutId !== -1) {
        //console.log('cancelling timeout', this.quickSearchTimeoutId);
        window.clearTimeout(this.quickSearchTimeoutId);
        this.quickSearchTimeoutId = -1;
      }
      if (this.jqXHR) {
        //console.log('aborting jqXHR');
        this.jqXHR.abort();
        this.jqXHR = null;
      }
    }
  
    private queueQuickSearch(term: string): void {
      const delay = 400;
      this.cancelSearch();
      this.quickSearchTimeoutId = window.setTimeout(() => {
        this.quickSearchTimeoutId = -1;
        //console.log('executing quicksearch');
        this.showLoadingIndicator();
        this.jqXHR = $.ajax({
          'url': this.quickSearchUrl,
          'method': 'get',
          'data': {
            'filter.quicksearch': term
          },
          'beforeSend': ()  => {
          }
        }).done((data) => {
          //console.log('quicksearch result');
          const $resultData = $(data).selfOrFind(SiteSearch.SELECTOR__QUICKRESULTS);
          $('.eh-result-list--item', $resultData).each((_i, el) => {
            const $el = $(el);
            this.addItemHandlers($el);
          });
          this.$quickResultsContainer.children().remove();
          this.$quickResultsContainer.append($resultData);
          SiteSearch.notifyReplaced($resultData);
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          //console.log('quicksearch failure', errorThrown);
          if (errorThrown !== 'abort') {
            this.$quickResultsContainer.children().remove();
          }
        })
        .always(() => {
          this.hideLoadingIndicator();
        });
        //console.log('started jqXHR quicksearch');
      }, delay);
      //console.log('queueing quicksearch', this.quickSearchTimeoutId);
    }
    
    private activateInput() {
      //console.log('activateInput');
      this.$input.trigger('focus');
    }
    
    private handleDocumentKeyDown = ($event: JQuery.TriggeredEvent) => {
      //console.log('handleDocumentKeyDown', $event.key);
      switch ($event.key) {
        case 'Enter':// 13 enter
          const term = this.invokeActive(true);
          if (term === true) {
            // default link behaviour
          }
          else {
            $event.preventDefault();
            let $trackingTarget;
            if (term) {
              this.setTerm(term);
              $trackingTarget = this.getActiveItem();
            }
            else {
              $trackingTarget = this.$termItem;
            }
            Tracking.injectTrackingEvent({}, $trackingTarget);
            this.$form.trigger('submit');
          }
          break;
        case 'Escape': // 27 ESC
          // fallthrough
        case 'Tab': // 9 TAB
          this.cancelSearch();
          $event.preventDefault();
          this.closePanel();
          // COM-1779 evil hack 
          $('.eh-login-button:visible').focus();
          break;
        case 'ArrowUp': // 38 arrow UP
          $event.preventDefault();
          this.selectAboveItem();
          break;
        case 'ArrowDown': // 40 arrow DOWN
          $event.preventDefault();
          this.selectBelowItem();
          break;
        case 'ArrowLeft':
          if (document.activeElement !== this.$input.get(0)) {
            $event.preventDefault();
            this.selectLeftItem();
          }
          break;
        case 'ArrowRight':
          if (document.activeElement !== this.$input.get(0)) {
            $event.preventDefault();
            this.selectRightItem();
          }
          break;
        case 'Backspace':
          if (!this.$input.is(':focus')) { // prevent history-back
            $event.preventDefault();
          }
          break;
      }
    };

    private onInputMouseDown = ($event: JQuery.TriggeredEvent) => {
      this.isInputMouseDown = true;
      this.$input.parent().toggleClass('focus-click', true);
      this.$input.parent().toggleClass('focus', false);
    };

    private handleDocumentClick = ($event: JQuery.TriggeredEvent) => {
      //console.log('handleDocumentClick');
      if (this.isInputMouseDown) {
        this.isInputMouseDown = false;
        return;
      }
      if (eh.Breakpoints.getInstance().isMobile) {
        return;
      }
      if (!$($event.target).parents().is(this.$elem)) {
        this.closePanel();
      }
    };

    private getItems(): JQuery<HTMLElement> {
      return $(SiteSearch.SELECTOR__RESULT_LIST_ITEM, this.$activeItemContainer).filter(':visible');
    }
    
    private selectBelowItem(): void {
      const $items = this.getItems();

      const $currentActive = $items.filter((_index, el) => SiteSearch.isListItemActive($(el)));
      const lastIndex = $items.index($currentActive);
      if (lastIndex === $items.length - 1) {
        return;
      }

      SiteSearch.setListItemInactive($currentActive);
      const newIndex = lastIndex + 1;
      SiteSearch.setListItemActive($items.eq(newIndex));

      this.updateState();
    }
  
    private selectAboveItem(): void {
      const $items = this.getItems();
      //console.log('selectAboveItem', $items.length);

      const $currentActive = $items.filter((_index, el) => SiteSearch.isListItemActive($(el)));
      const lastIndex = $items.index($currentActive);

      if (lastIndex < 0) {
        return;
      }

      if (lastIndex === 0) {
        SiteSearch.setListItemInactive($currentActive);
        this.activateInput();
        return;
      }

      SiteSearch.setListItemInactive($currentActive);
      const newIndex = lastIndex - 1;
      SiteSearch.setListItemActive($items.eq(newIndex));
      this.updateState();
    }

    private getElementAndBounds($el: JQuery<HTMLElement>): IQSElementBounds[] {
      const result: IQSElementBounds[] = [];
      $el.each((_idx: number, el: HTMLElement): void => {
        const bounds: DOMRect = el.getBoundingClientRect();
        result.push({
          top: bounds.top,
          left: bounds.left,
          right: bounds.right,
          el: $(el)
        });
      });
      return result;
    }

    private selectLeftItem(): void {
      const $currentActive = this.getActiveItem();
      const currentBounds: IQSElementBounds | undefined = this.getElementAndBounds($currentActive).pop();
      const elementBounds: IQSElementBounds[] = this.getElementAndBounds(this.getItems());

      if (!!currentBounds) {
        const $nextNeighbors: IQSElementBounds[] = elementBounds
            .filter(e => e.left < currentBounds.left && e.right < currentBounds.right);
        const $nextNeighbor: IQSElementBounds | undefined = $nextNeighbors
            .filter(e => e.top >= currentBounds.top).shift();
        const $result: JQuery<HTMLElement> | undefined = $nextNeighbor?.el ?? $nextNeighbors.pop()?.el;

        if (!!$result) {
          SiteSearch.setListItemInactive($currentActive);
          SiteSearch.setListItemActive($result);
        }
      }
    }

    private selectRightItem(): void {
      const $currentActive = this.getActiveItem();
      const currentBounds: IQSElementBounds | undefined = this.getElementAndBounds($currentActive).pop();
      const elementBounds: IQSElementBounds[] = this.getElementAndBounds(this.getItems());

      if (!!currentBounds) {
        const $nextNeighbors: IQSElementBounds[] = elementBounds
            .filter(e => e.left > currentBounds.left && e.right > currentBounds.right);
        const $nextNeighbor: IQSElementBounds | undefined = $nextNeighbors
            .filter(e => e.top >= currentBounds.top).shift();
        const $result: JQuery<HTMLElement> | undefined = $nextNeighbor?.el ?? $nextNeighbors.pop()?.el;

        if (!!$result) {
          SiteSearch.setListItemInactive($currentActive);
          SiteSearch.setListItemActive($result);
        }
      }
    }
  
    private invokeActive(byKey: boolean): string | boolean {
      //console.log('invokeActive');
      const $currentActive = this.getActiveItem();
      if ($currentActive.length === 1) {
        return this.invokeElement($currentActive, byKey);
      }
      return false;
    }
    
    private getActiveItem(): JQuery<HTMLElement> {
      return $(SiteSearch.SELECTOR__RESULT_LIST_ITEM, this.$activeItemContainer)
        .filter((_index, el) => SiteSearch.isListItemActive($(el)));
    }
    
    private invokeElement($el: JQuery<HTMLElement>, _byKey: boolean): string | boolean {
      //console.log('invokeElement', $el);
      const $link = $el.selfOrFind('a[href]');
      if ($link.length > 0 && $link.attr('href') !== '#') {
        //console.log('at link');
        this.closePanel();
        return true;// allow default click handling
      }
      if($el.data('fulltext')) {
        return '' + $el.data('fulltext');
      }
      return $el.data('fulltext');
    }
    
    private prepareHistoryTerms(): void {
      //console.log('prepareHistoryTerms');
      this.$historyList.children().remove();
      const terms = this.history.getTerms();
      terms.forEach((historyTerm) => {
        const $item = this.$historyListItemTemplate.clone();
        $item.removeClass([SiteSearch.CLASS__HIDDEN, SiteSearch.SELECTOR__HISTORY_LIST_ITEM_TEMPLATE.substr(1)].join(' '))
            .attr('data-fulltext', historyTerm)
            .find(SiteSearch.SELECTOR__SEARCH_TERM).text(historyTerm);
        this.$historyList.append($item);
        this.addItemHandlers($item);
      });
    }
    
    private addItemHandlers($item: JQuery<HTMLElement>) {
      //console.log('addItemHandlers');
      $item.on('click', ($event) => {
        const $currentTarget = $($event.currentTarget);
        //console.log('item click', $currentTarget);
        const $target = $currentTarget.selfOrClosest(SiteSearch.SELECTOR__RESULT_LIST_ITEM);
        const term = this.invokeElement($target, false);
        //console.log($target, term);
        if (term === true) {
          // default link behaviour
        }
        else {
          $event.preventDefault();
          if (term) {
            this.setTerm(term);
          }
          this.$form.trigger('submit');
        }
      });
      $item.on('mouseenter', ($event) => {
        const $currentTarget = $($event.currentTarget);
        //console.log('item mouseenter', $currentTarget);
        const item = $currentTarget.hasClass(SiteSearch.SELECTOR__RESULT_LIST_ITEM.replace(/\./, '')) ? $currentTarget : $currentTarget.find(SiteSearch.SELECTOR__RESULT_LIST_ITEM);
        SiteSearch.setListItemInactive(this.getActiveItem());
        SiteSearch.setListItemActive(item);
      });
    }
    
    public static isTermValid(term: string): boolean {
      const minLength = 2;
      const splitChars = ' ';
      return term.split(new RegExp(splitChars + '+', 'g')).some(subTerm => {
        return subTerm.length >= minLength;
      });
    }
  
    private static isListItemActive($el: JQuery<HTMLElement>): boolean {
      return $el.hasClass(SiteSearch.CLASS__ACTIVE);
    }
  
    private static setListItemActive($el: JQuery<HTMLElement>, transient: boolean = false): void {
      $el.addClass(SiteSearch.CLASS__ACTIVE);
      if (transient) {
        return;
      }
      const anchor: JQuery<HTMLElement> = $el.is('a') ? $el : $el.find('a[href]');
      anchor.prop('tabIndex', 100).trigger('focus');
    }
  
    private static setListItemInactive($el: JQuery<HTMLElement>): void {
      $el.find('a[href]').prop('tabIndex', -1);
      $el.removeClass(SiteSearch.CLASS__ACTIVE);
    }
    
    private static notifyReplaced($el: JQuery<HTMLElement>): void {
      const event = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
      event.replacedTarget = $el;
      $(':root').trigger(event);
    }
    
  }
  
  /** Local storage handler to provide x-last search terms sort by date.
   * And allow storing terms.   */
  class HistoryStorage {
    static readonly LOCAL_STORAGE_KEY__HISTORY: string = 'eh.QuickSearch.history';
    static readonly HISTORY_VERSION: number = 1;
    static readonly HISTORY_MAX_ITEMS: number = 5;
    static readonly HISTORY_MAX_AGE_MS: number = 6 * 30 * 24 * 60 * 60 * 1000;
    
    public readonly isAvail:boolean;
    private readonly keyVersion: string = 'v';
    private readonly keyItems: string = 'i';
    private readonly keyTerm: string = 't';
    private readonly keyDate: string = 'd';
    
    private items: any[] = [];
    
    constructor() {
      try {
        this.isAvail = typeof(window.localStorage) === 'object';
      }
      catch (e) {
        this.isAvail = false;
      }
    }
    
    private init(): void {
      if (this.isAvail && this.items.length === 0) {
        const h = window.localStorage.getItem(HistoryStorage.LOCAL_STORAGE_KEY__HISTORY);
        if (h) {
          const history = JSON.parse(h);
          const version = parseInt(history[this.keyVersion], 10);
          if (version === HistoryStorage.HISTORY_VERSION) {
            let maxAge = new Date(Date.now() - HistoryStorage.HISTORY_MAX_AGE_MS);
            this.items = history[this.keyItems].map((item: any) => {
              if(item[this.keyDate] && item[this.keyTerm]) {
                let idate = new Date(item[this.keyDate]);
                if(idate >= maxAge) {
                  item[this.keyDate] = idate;
                  return item;
                }
              }
            }).filter((item:any) => {
              return !!item;
            });
            this.sortItems();
          }
        }
      }
    }
    
    public addTerm(term: string | null) {
      //console.log('adding history term', term);
      if (!term) {
        return;
      }
      term = term.trim();
      if (term.length === 0) {
        return;
      }
      this.init();
      let idx = -1;
      const lTerm = term.toLowerCase();
      this.items.forEach((element, index) => {
        if (element[this.keyTerm].toLowerCase() === lTerm) {
          idx = index;
        }
      });
      if (idx === -1) {
        const item:any = {};
        item[this.keyDate] = new Date();
        item[this.keyTerm] = term;
        this.items.unshift(item);
      }
      else {
        this.items[idx][this.keyDate] = new Date();
        this.items[idx][this.keyTerm] = term;
      }
      this.sortItems();
      this.resizeItems();
      this.store();
    }
    
    public getTerms(): string[] {
      this.init();
      const res: string[] = [];
      this.items.forEach(it => {
        res.push(it[this.keyTerm]);
      });
      return res;
    }
    
    private store() {
      if (this.isAvail) {
        const history:any = {};
        history[this.keyVersion] = HistoryStorage.HISTORY_VERSION;
        history[this.keyItems] = this.items;
        window.localStorage.setItem(HistoryStorage.LOCAL_STORAGE_KEY__HISTORY, JSON.stringify(history));
      }
    }
    
    private sortItems(): void {
      this.items = this.items.sort((a, b) => {
        const dateA = a[this.keyDate], dateB = b[this.keyDate];
        if (dateA > dateB) {
          return -1;
        }
        else if (dateA < dateB) {
          return 1;
        }
        const termA = a[this.keyTerm], termB = b[this.keyTerm];
        if (termA > termB) {
          return 1;
        }
        else if (termA < termB) {
          return -1;
        }
        return 0;
      });
    }
    
    private resizeItems() {
      this.items = this.items.filter((value, index) => {
        return index < HistoryStorage.HISTORY_MAX_ITEMS;
      });
    }
    
  }

  interface IQSElementBounds {
    top: number;
    left: number;
    right: number;
    el: JQuery<HTMLElement>;
  }
}
