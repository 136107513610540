namespace eh {

  export class SeamlessIFrame implements IMessageHandler {
    
    static init($base: JQuery<HTMLElement>): void {
      $('.external.seamless-applicable iframe, .external.seamless iframe', $base).each((index, ifra) => {
        new SeamlessIFrame((<HTMLIFrameElement> ifra));
      });
    }
    
    private $wrapper: JQuery<HTMLElement>;
    private $iframe: JQuery<HTMLElement>;
    private iWindow: Window;
    public id : string;
    private legacy : boolean;
    private topMargin = 145 - 32; //wild guess -> not accurate: $('.eh-header--wrapper').height() || 0;

    constructor(private ifra: HTMLIFrameElement) {
      this.$iframe = $(ifra);
      this.iWindow = ifra.contentWindow || window;
      this.$wrapper = this.$iframe.closest('.external');
      this.id = this.$iframe.attr("id") || ('iframe-' + (new Date()).getTime() + '-' + Math.random()).replace(/[^a-z0-9\-]/g, '');
      this.$iframe.attr('id', this.id);
      this.legacy = this.$wrapper.hasClass('legacy-message');// event.data as string not message


      eh.MessageNexus.setListener(this.iWindow, this);
      const iframeTop = this.iWindow.top;
      if (iframeTop) {
        $(iframeTop).on('load', () => { this.sendStateReq() });
      }
      this.sendStateReq();
    }

    private sendMsgLocal(m: Message) {
      let data = this.legacy? m.asLegacyParam() : m;
      this.iWindow.postMessage(data, '*');                
    }
    private sendStateReq() {
      if(this.legacy) {
        let m: Message = new Message('id');
        m.putParam("id", this.id);
        this.sendMsgLocal(m);
      } else {
        let m: Message = new Message('eho:state');
        this.sendMsgLocal(m);
      }
    }
    private sendACK(org: Message) {
      let m: Message = new Message('eho:applied');
      if(this.legacy) {
        m.putParam("id", this.id);
      }
      this.sendMsgLocal(m);
    }
    // use MessageTunnel
    public onMessage(message : Message) {
      if(message.params['$$legacy'] !== undefined) {
        this.legacy = true;
      }
      switch(message.method) {
        case 'eho:refresh':
        case 'refresh': {
          this.sendStateReq();
          break;
        }
        case 'eho:height': 
        case 'height': {
          let val = message.params['height'];
          if(val) {
            let n: number = parseInt(val);
            this.setHeight(n);
          }
          this.sendACK(message);
          break;
        }
        case 'eho:scroll': 
        case 'scroll': {
          let val = message.params['pos'];
          if(val) {
            let n: number = parseInt(val);
            this.setScroll(n);
          } else {
            let n: number = 0;
            this.setScroll(0);
          }
          this.sendACK(message);
          break;
        }
        case 'eho:open-url': {
          let title = message.params['title'];
          let ref = message.params['url'];
          if(ref) {
            this.openUrl(ref, title);
          }
          this.sendACK(message);
          break;
        }
      }
    }

    private openUrl(url:string, title?: string) {
      let m: Message = new Message('*:open-url');
      m.putParam('title', title || '');
      m.putParam('url', url);
      window.parent.postMessage(m, '*');
    }

    private setHeight(value: number) {
      if(this.$iframe.is(':visible')) {
        if(this.$wrapper.hasClass('seamless-applicable')) {
          this.$wrapper.removeClass('seamless-applicable').addClass('seamless');
          this.$iframe.attr('width','').css('visibility','visible');
        }
        if(this.$wrapper.hasClass('seamless')) {
          let sto = this.$iframe.closest('.toggle');
          this.$iframe.finish().animate({
            'height': value + 32
          });
          this.scrollToToggle(sto);
        }
      }
    }

    private setScroll(value: number) {
      if(this.$iframe.is(':visible')) {
        let offs =  this.$iframe.offset() || null;
        let offst = offs ? offs.top : 0;
        if(value > 0) {
          var h = this.$iframe.height() || 0;
          offst  = offst + (h  * value / 100);
        }
        $('html,body').animate({
          'scrollTop': Math.round(offst - this.topMargin)
        }, 400);
        // }
      }
    }

    private scrollToToggle($elem: JQuery<Element>) {
      if ($elem.length > 0 && !$elem.hasClass('searchhighlight-autoOpen')) {
        let offs =  $elem.offset() || null;
        let offst = offs ? offs.top : 0;
        $('html,body').animate({
          'scrollTop': Math.round(offst)
        }, 400);
      }
    };

  }  
}
