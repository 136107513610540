interface ISelectOptionWrapper {
    disabled: boolean;
    loading: boolean;
    id: string;
    text: string;
    element?: HTMLOptionElement;
}

namespace eh {

    export class LanguageChooser {

        static init($base: JQuery<HTMLElement>): void {
            //// For Microsites
            //$('.eh-language-chooser-inline', $base).select2({
            //  theme: 'endress-inline',
            //  containerCssClass: 'eh-link--01',
            //  dropdownAutoWidth: true,
            //  width: 'auto',
            //  minimumResultsForSearch: -1
            //});

            function goToLanguageChooseLinkFormatter(optionEl: ISelectOptionWrapper) {
                if (!optionEl.id
                    || optionEl.element && $(optionEl.element).data('eh-key') !== 'LANGUAGE_CHOOSER_LINK_OPTION'
                ) {
                    return optionEl.text;
                }
                const $optionEl = $('<span class="eh-select--language-chooser-link ehel-language-link">' + optionEl.text + '</span>');
                return $optionEl;
            };

            Breakpoints.getInstance().registerChangeListener((_old: Breakpoint, _current: Breakpoint): void => {
                $('.eh-language-chooser-inline', $base).select2('close');
            });

            document.addEventListener('scroll', function() {
              if ($('.eh-language-chooser-inline').select2) {
                  $('.eh-language-chooser-inline').select2('close');
              }
            }, {
              passive: true,
            });

            $('.eh-language-chooser-inline', $base)
                .select2({
                    theme: 'endress-inline',
                    containerCssClass: 'eh-link--dropdown-arrow', // add custom flavor
                    dropdownAutoWidth: true,
                    dropdownCssClass: 'eh-language-chooser-inline--dropdown',
                    width: 'auto',
                    minimumResultsForSearch: -1,
                    templateResult: goToLanguageChooseLinkFormatter,
                    templateSelection: goToLanguageChooseLinkFormatter
                })
                .show().on('change', (event) => {
                if (!event.currentTarget.dataset.notLanguageChooser) {
                    if ($(event.currentTarget).val()) {
                        window.location.href = '' + $(event.currentTarget).val();
                    }
                }
            });
        }
    }
}
