namespace eh {

    export interface IScrollChangeDispatcherDescriptor {
        scrollPanelClassName?: string | undefined;
        scrollPanel?: HTMLElement | null;
        scrollContentClassName?: string | undefined;
        scrollContent?: HTMLElement | null;
        contextElement: HTMLElement | null;
    }

    export class ScrollChangeDispatcher {

        private isAtBottom: boolean;
        private isAtTop: boolean;
        private readonly scrollPanel: HTMLElement | null | undefined;
        private readonly scrollContent: HTMLElement | null | undefined;
        private scrollPanelHeight: number;
        private scrollContentHeight: number;
        private topListeners: {(isAtTop: boolean): void}[] = [];
        private bottomListeners: {(isAtBottom: boolean): void}[] = [];

        constructor(
            private descriptor: IScrollChangeDispatcherDescriptor
        ) {
            this.scrollPanel = descriptor.scrollPanel ?? descriptor.contextElement?.querySelector(descriptor.scrollPanelClassName || '');
            this.scrollPanel?.addEventListener('scroll', this.onScrollChange, this.scrollParams);
            this.scrollContent = descriptor.scrollContent ?? this.scrollPanel?.querySelector(descriptor.scrollContentClassName || '');

            if (!descriptor.contextElement || !this.scrollPanel || !this.scrollContent) return;

            Breakpoints.getInstance().registerResizeListener(debounce(this.init, 100));
            this.init();
        }

        public registerTopListener: (listener: (isAtTop: boolean) => void) => void = (listener: (isAtTop: boolean) => void): void => {
            this.topListeners.push(listener);
        };

        public registerTopListenerAndFire: (listener: (isAtTop: boolean) => void) => void = (listener: (isAtTop: boolean) => void): void => {
            this.registerTopListener(listener);
            this.dispatchTopListener();
        };

        public unregisterTopListener: (listener: (isAtTop: boolean) => void) => void = (listener: (isAtTop: boolean) => void): void => {
            this.topListeners.splice(this.topListeners.indexOf(listener));
        };

        public registerBottomListener: (listener: (isAtBottom: boolean) => void) => void = (listener: (isAtBottom: boolean) => void): void => {
            this.bottomListeners.push(listener);
        };

        public registerBottomListenerAndFire: (listener: (isAtBottom: boolean) => void) => void = (listener: (isAtBottom: boolean) => void): void => {
            this.registerBottomListener(listener);
            this.dispatchBottomListener();
        };

        public unregisterBottomListener: (listener: (isAtBottom: boolean) => void) => void = (listener: (isAtBottom: boolean) => void): void => {
            this.bottomListeners.splice(this.bottomListeners.indexOf(listener));
        };

        private dispatchTopListener(): void {
            this.topListeners.forEach((listener: (isAtTop: boolean) => void): void => {
                listener(this.isAtTop);
            });
        }

        private dispatchBottomListener(): void {
            this.bottomListeners.forEach((listener: (isAtBottom: boolean) => void): void => {
                listener(this.isAtBottom);
            });
        }

        private onScrollChange: () => void = (): void => {
            // invalidate flags
            this.invalidate();
        };

        public init: () => void = (): void => {
            this.updateMetrics();
            this.invalidate();
        };

        private updateMetrics(): void {
            this.scrollPanelHeight = this.scrollPanel?.offsetHeight ?? NaN;
            this.scrollContentHeight = this.scrollContent?.offsetHeight ?? NaN;
        }

        private invalidate: () => void = (): void => {
            // determine scroll position
            const currentScrollPos: number = this.scrollPanel?.scrollTop ?? NaN;
            const maxScrollPos: number = this.scrollContentHeight - this.scrollPanelHeight;

            if (maxScrollPos <= 0) {
                if (!this.isAtTop || !this.isAtBottom) {
                    this.isAtTop = true;
                    this.isAtBottom = true;
                    this.dispatchTopListener();
                    this.dispatchBottomListener();
                }
                return;
            }

            if (!this.isAtTop && currentScrollPos <= 0 || this.isAtTop && currentScrollPos > 0) {
                this.isAtTop = currentScrollPos <= 0;
                this.dispatchTopListener();
            }
            if (!this.isAtBottom && currentScrollPos >= maxScrollPos || this.isAtBottom && currentScrollPos <= maxScrollPos) {
                this.isAtBottom = currentScrollPos >= maxScrollPos;
                this.dispatchBottomListener();
            }

        };

        private get scrollParams(): any {
            return !!eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
        }

        public dispose(): void {
            this.scrollPanel?.removeEventListener('scroll', this.onScrollChange);
            this.topListeners.forEach(l => this.unregisterTopListener(l));
            this.bottomListeners.forEach(l => this.unregisterBottomListener(l));
        }

    }

}