namespace eh {
  
  export class DatePicker {
    
    static init($base: JQuery<HTMLElement>): void {
      DatePicker.initializeDatePicker($base);
    }
    
    private static initializeDatePicker($base: JQuery<HTMLElement>): void {
      $(`.${DatePicker.CLS_MARKER_DATEPICKER}`, $base).each((_index, el) => {
        new DatePicker($(el));
      });
      // hide picker on scroll events
      eh.ScrollPage.getScrollRoot().on('scroll', () => {
        if (document.activeElement === null) {
          return;
        }
        const $el = $(document.activeElement);
        if ($el.hasClass('hasDatepicker')) {
          $el.trigger('blur');
          $.datepicker._hideDatepicker();
        }
      });
    }
    
    public static readonly CLS_MARKER_DATEPICKER = 'marker-dp';
    public static readonly CLS_MARKER_DATEPICKER_CONTROL = 'marker-dp-control';
    private static readonly CLS_MARKER_DATEPICKER_DISPLAY = 'marker-dp-display';
    private static readonly CLS_MARKER_DATEPICKER_PARENT = 'marker-dp-parent';
    private static readonly CLS_TRIGGER_DATEPICKER_RESET = 'trigger-dp-reset';
    
    private readonly $parent: JQuery<HTMLElement>;
    private readonly $inputField: JQuery<HTMLElement>;
    private readonly $resetButton: JQuery<HTMLElement>;
    
    constructor(private readonly $dp: JQuery<HTMLElement>) {
      this.$parent = $dp.closest(`.${DatePicker.CLS_MARKER_DATEPICKER_PARENT}`);
      this.$inputField = this.findInputField($dp.data('backingFieldName'));
      $('.trigger-dp-open', this.$parent).on('click', $event => {
        $event.preventDefault();
        this.$inputField.trigger('focus');
      });
      this.$resetButton = this.connectResetButton();
      let opts = $.extend({
        'maxDate': $dp.data('last'),
        'minDate': $dp.data('first'),
        'dateFormat': $dp.data('dateFormat'),
        'onSelect': (newValue: string) => {
          this.$inputField.val(newValue);
          $(`.${DatePicker.CLS_MARKER_DATEPICKER_DISPLAY}`, this.$parent).text(newValue);
          this.$resetButton.toggle(!!newValue);
          this.$inputField.trigger('change');
        },
        'beforeShow': this.handleLayout,
        'showOtherMonths': true,
        'selectOtherMonths': true,
        'showAnim': false,
        'firstDay': 1,
      }, $dp.data('datePickerLocalization') || {})
      ;
      if (this.$inputField.length === 1) {
        opts.defaultDate = this.$inputField.val();
      }
      else {
        opts.defaultDate = $dp.val();
        this.$resetButton.toggleClass('eh--hide', !!$dp.val());
      }
      $dp.datepicker(opts);
    }
  
    private findInputField(fieldName: string | undefined) {
      let $result: JQuery<HTMLElement> = this.$dp;
      this.$parent.find('input').each((_index, el) => {
        const $input = $(el), name = $input.prop('name') || '', i = name.indexOf(fieldName);
        if (i === 0 || i === name.indexOf('.') + 1) {
          $result = $input;
          return false;
        }
      });
      return $result;
    };
    
    private connectResetButton() {
      return $(`.${DatePicker.CLS_TRIGGER_DATEPICKER_RESET}`, this.$parent)
        .on('click', () => {
          this.$inputField.val('');
          this.$inputField.trigger('change');
      });
    }

    private handleLayout = (input: HTMLInputElement, inst: {dpDiv: JQuery<HTMLElement>}): void => {
      requestAnimationFrame((): void => {
        const gutter: number = 16;
        let pageOffset: number = ScrollPage.getScrollRoot().hasClass('eh-no-scroll') ? 0 : ScrollPage.getScrollPosition();
        const inputBounds: DOMRect = input.getBoundingClientRect();
        const widgetElement: HTMLElement | undefined = inst.dpDiv.get(0);
        if (!widgetElement) {
          return;
        }
        const widgetBounds: DOMRect = widgetElement.getBoundingClientRect();
        const spaceBelow: number = innerHeight - (inputBounds.top + inputBounds.height);
        const placement: {top: number, left: number} = {
          top: 0,
          left: inputBounds.left
        };
        if (spaceBelow < widgetBounds.height) {
          // place above input
          placement.top = inputBounds.top + pageOffset - (widgetBounds.height + gutter);
        } else {
          // place below input
          placement.top = inputBounds.top + inputBounds.height + pageOffset + gutter;
        }
        inst.dpDiv.css(placement);
      });
    };
  
  }
}
