namespace eh {

    /* e.g.:
      <div class="eh-popover-button eh-layout--pos-rel">
        <a href="#" class="eh-popover-button--button eh-link eh-icon-button--static-xs horizontal eh-icon-button eh-button--02">
          <i class="eh-icon icon-eh-share"></i>
          <span class="eh-label">Share</span>
        </a>
        <div class="eh-popover-button--popover eh--hide">
          <ul class="eh-link--list eh-block-theme--02-shadow vertical">
            <li class="eh-link--list-item eh-p-h">
              <a href="." class="eh-link eh-link--02 eh-ws-no-wrap">Share A</a>
            </li>
            <li class="eh-link--list-item eh-p-h">
              <a href="." class="eh-link eh-link--02 eh-ws-no-wrap">Share B</a>
            </li>
          </ul>
        </div>
      </div>
    */

    export class PopoverButton {

        static POPOVER_BUTTON_SELECTOR: string = '.eh-popover-button';

        static init($base: JQuery<HTMLElement>): void {
            $(PopoverButton.POPOVER_BUTTON_SELECTOR, $base).each((idx: number, popOverButton: HTMLElement): void => {
                new PopoverButtonCtrl(popOverButton);
            });
        }
    }

    class PopoverButtonCtrl {

        static POPOVER_BUTTON_BUTTON_SELECTOR: string = '.eh-popover-button--button';
        static POPOVER_BUTTON_POPOVER_SELECTOR: string = '.eh-popover-button--popover';

        private _popover: VisibleItem;
        private _button: HTMLElement;

        constructor(popoverButton: HTMLElement) {
            const popoverElement: HTMLElement | null = popoverButton.querySelector(PopoverButtonCtrl.POPOVER_BUTTON_POPOVER_SELECTOR);
            const buttonElement: HTMLElement | null = popoverButton.querySelector(PopoverButtonCtrl.POPOVER_BUTTON_BUTTON_SELECTOR);
            if (popoverElement && buttonElement) {
                this._popover = new VisibleItem(popoverElement);
                this._button = buttonElement;
                this.init();
            } else {
                throw new Error('PopoverButtonCtrl is missing required elements');
            }
        }

        private init(): void {
            this._button.addEventListener('click', this.onButtonClick);
        }

        private registerDocumentClick(): void {
            document.addEventListener('click', this.onDocumentClick);
        }

        private unregisterDocumentClick(): void {
            document.removeEventListener('click', this.onDocumentClick);
        }

        private onButtonClick = (e: Event): void => {
            e.preventDefault();
            this._popover.show();
            setTimeout(() => this.registerDocumentClick(), 0); // register after current event was handled
        };

        private onDocumentClick = (): void => {
            this._popover.hide();
            this.unregisterDocumentClick();
        };

    }

}
