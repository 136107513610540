namespace eh {
  
  export class CountryDetector {
    $countrySelect : JQuery<HTMLElement>;
    $languageSelect : JQuery<HTMLElement>;
    $redirectButton : JQuery<HTMLElement>;
    selectedCountry : string = '';
    selectedLanguage : string = '';

    static init($base: JQuery<HTMLElement>): void {
      $('.eh-market-chooser', $base).each((_index, element) => {
        const $marketChooser = $(element),
          $select = $('.go-to-country-select', $marketChooser),
          $button = $('.go-to-country-bt', $marketChooser),
          handleCountrySelection = () => {
            const $selectOption = $select.find('option:selected');
            $button.attr('href', $selectOption.data('href') || '#');
            $button.attr('data-cs-tracking-subtype', $selectOption.data('csTrackingSubtype'));
          }
        ;
        handleCountrySelection();
        $select.on('change', handleCountrySelection);
      });
      
      // handle detector      
      if ($base.find('#countrySiteSwitch').length === 1) {
        const prefix = $base.find('#countrySiteSwitch').data('csPrefix') || 'ii';
        if (getCookie(prefix + '-countrychooser-shown') !== '') {
          return;
        }
        if ($base.find('.eh-country-detection')) {
          // on overlay open init logic
          new CountryDetector($base, prefix);
        }
        // on pageload trigger open in overlay
        const msg = new Message('*:open-id');
        msg.putParam('title', $base.find('#countrySiteSwitch').data('heading'));
        msg.putParam('id', '#countrySiteSwitch');
        window.postMessage(msg, '*');
      } 
    }

    constructor(readonly $base: JQuery<HTMLElement>, readonly prefix: string) {
      const $countryDetection: JQuery<HTMLElement> = $base.find('.eh-country-detection');
      this.$countrySelect = $countryDetection.find('select#country');
      this.$languageSelect = $countryDetection.find('select#language');
      this.$redirectButton = $countryDetection.find('.js-go-country-redirect');

      this.$countrySelect.on('change', () => {
        const t = this.selectedCountry;
        this.selectedCountry = CountryDetector.getVal(this.$countrySelect);
        if (t !== this.selectedCountry) {
          this.updateState(true);
        }
      });

      this.$languageSelect.on('change', () => {
        const t = this.selectedLanguage;
        this.selectedLanguage = CountryDetector.getVal(this.$languageSelect);
        if(t !== this.selectedLanguage) {
          this.updateState(false);
        }
      });

      this.$redirectButton.on('click', () => {
        if (this.selectedCountry) {
          const parts = [this.selectedCountry]
          if (this.selectedLanguage) {
            parts.unshift(this.selectedLanguage);
          }
          setCookie('ii-country-redirect', parts.join('_'), 90, false, 'Lax');
          this.rememberCountryChooserShown();
        }
      });
  
      $countryDetection.one(eh.Overlay.EventIdPreClose, () => {
        this.rememberCountryChooserShown();
      });
      
      this.$countrySelect.trigger('change');
    }
    
    private rememberCountryChooserShown() {
      setCookie(this.prefix + '-countrychooser-shown', '1', 0, false, 'Lax');
    }
    
    private updateState(reinit:boolean): void {
      const countryOption = this.$countrySelect.find('option[value="'+this.selectedCountry+'"]');
      const locales = countryOption.data('locales') || '';
      const ls = locales.split(',');
      if(ls.length == 1) {
        this.selectedLanguage = ls[0];
      } else {
        if(ls.indexOf(this.selectedLanguage) < 0) {
          this.selectedLanguage = '';
        }
      }
      if(this.selectedLanguage.length === 0) {
        let doit = true;
        if(this.$languageSelect.data('locales')) {
          let prefLs = this.$languageSelect.data('locales').split(',');
          if(prefLs) {
            prefLs.forEach((elP:string) => {
              ls.forEach((elL:string) => {
                if(doit && elL === elP) {
                  this.selectedLanguage = elL;
                  doit = false;
                }
              });
            });
          }
        }
        if(doit) {
          this.selectedLanguage = ls[0];
        }
      }
      this.$languageSelect.parent('.eh-form-row').removeClass('eh--hide');
      let count = 0;
      this.$languageSelect.find('option').each((idx, el) => {
        let $el = $(el);
        if(ls.indexOf($el.val()) > -1) {
          $el.removeClass('eh--hide');
          $el.removeAttr('disabled').removeProp('disabled');
          count++;
        } else {
          $el.addClass('eh--hide');
          $el.prop('disabled', true);
        }
        $el.prop('selected', this.selectedLanguage === $el.val())
      });
      if(count < 2) {
        this.$languageSelect.addClass("eh--hide");
        this.$languageSelect.parents(".eh-form-row").addClass("eh--hide");
      } else {
        this.$languageSelect.removeClass("eh--hide");
        this.$languageSelect.parents(".eh-form-row").removeClass("eh--hide");
      }
      if(reinit) {
        this.$languageSelect.trigger('select2:csReinit');
      }
      let languageOption = this.$languageSelect.find('option[value="'+this.selectedLanguage+'"]');

      let link = languageOption.data('href') || countryOption.data('link') || window.location.href;
      const qps = URLHelper.buildQueryParamMap(URLHelper.getQueryString(link));
      const MAGIC_LOCALE_URL_PLACEHOLDER = 'LOCL';
      if (link.indexOf(MAGIC_LOCALE_URL_PLACEHOLDER) !== -1) {
        link = link.replace(new RegExp(MAGIC_LOCALE_URL_PLACEHOLDER, 'g'), encodeURI(this.selectedLanguage));
      }
      else {
        if (!countryOption.data('link') && this.selectedCountry) {
          qps['ii-country'] = this.selectedCountry;
        }
      }
      if (this.selectedLanguage) {
        qps['locale'] = this.selectedLanguage;
      }
      link = URLHelper.buildUrl(link, URLHelper.buildQueryString(qps));
      this.$redirectButton.attr('href', link);
  }


    private static getVal(sel: JQuery<HTMLElement>):string {
      if(sel.val()) {
        return ''+sel.val();
      }
      return '';
    }
  }
}