(function() {
  'use strict';

  var getOptionTree = function($option) {
    var selectedLevel = $option.data('level'),
      doBreak = false;
    if (selectedLevel === undefined) {
      return $option;
    }
    return $option.nextAll('option').filter(function(idx, el) {
      doBreak = doBreak || ($(el).data('level') || 0) <= selectedLevel;
      return !doBreak;
    }).add($option);
  };

    /**
     * override due to synthetic events bug in IE
     * since jquery 3.4.1
     */
    var requireObj = $.fn.select2.amd.require([], function() {});
    var csSearch = requireObj._defined['select2/dropdown/search'];
    if (!!csSearch && !!csSearch.prototype.bind) {
        csSearch.prototype.bind = function (decorated, container, $container) {
            var self = this;

            var resultsId = container.id + '-results';

            decorated.call(this, container, $container);

            this.$search.on('keydown', function (evt) {
                self.trigger('keypress', evt);
                self._keyUpPrevented = evt.isDefaultPrevented();
            });

            // Workaround for browsers which do not support the `input` event
            // This will prevent double-triggering of events for browsers which support
            // both the `keyup` and `input` events.
            this.$search.on('input', function (evt) {
                // Unbind the duplicated `keyup` event
                $(this).off('keyup');
            });

            this.$search.on('keyup input', function (evt) {
                self.handleSearch(evt);
            });

            container.on('open', function () {
                self.$search.attr('tabindex', 0);
                self.$search.attr('aria-controls', resultsId);


                // prevent dispatch focus events in IE11
                // self.$search.trigger('focus');

                window.setTimeout(function () {
                    self.$search.trigger('focus');
                }, 0);
            });

            container.on('close', function () {
                self.$search.attr('tabindex', -1);
                self.$search.removeAttr('aria-controls');
                self.$search.removeAttr('aria-activedescendant');

                self.$search.val('');
                self.$search.trigger('blur');
            });

            container.on('focus', function () {
                if (!container.isOpen()) {
                    self.$search.trigger('focus');
                }
            });

            container.on('results:all', function (params) {
                if (params.query.term == null || params.query.term === '') {
                    var showSearch = self.showSearch(params);

                    if (showSearch) {
                        self.$searchContainer.removeClass('select2-search--hide');
                    } else {
                        self.$searchContainer.addClass('select2-search--hide');
                    }
                }
            });

            container.on('results:focus', function (params) {
                if (params.data._resultId) {
                    self.$search.attr('aria-activedescendant', params.data._resultId);
                } else {
                    self.$search.removeAttr('aria-activedescendant');
                }
            });
        };
    }




  // // Filter Lists
  $.fn.select2.amd.define('cs.FilterMultiSelectionDecorator', [], function() {

    var FilterMultiSelectionDecorator = function(decoratedFn, $element, options) {
        decoratedFn.call(this, $element, options);
        this.defaultHeight = 28;
      };

    FilterMultiSelectionDecorator.prototype.render = function(decoratedFn) {
      var $rendered = decoratedFn.call(this);
      $rendered.append('<span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>');
      return $rendered;
    };

    FilterMultiSelectionDecorator.prototype.update = function(decoratedFn, data) {
      decoratedFn.call(this, data);
      $('.select2-selection__choice__remove', this.$selection).remove();
      if (this.$selection.height() > this.defaultHeight) {
        var label = eh.formatString(this.options.get('labelSelectionCondensed') || '', data.length, $('option[value!=""]', this.$element).length);
        $('.select2-selection__rendered .select2-selection__choice', this.$selection).first().html(label).siblings('.select2-selection__choice').remove();
      }
    };

    return FilterMultiSelectionDecorator;
  });
  
  
  $.fn.select2.amd.define('cs.NoOpSelectionAdapter', ['select2/utils'], function(S2Utils) {
    
    var NoOpSelection = function ($element, options) {
      this.$element = $element;
      this.options = options;
    }
  
    S2Utils.Extend(NoOpSelection, S2Utils.Observable);
  
    NoOpSelection.prototype.render = function () {
      var $selection = $();
      this.$selection = $selection;
      return $selection;
    };
  
    NoOpSelection.prototype.bind = function (container, $container) {
    };
  
    NoOpSelection.prototype.position = function ($selection, $container) {
    };
  
    NoOpSelection.prototype.destroy = function () {
    };
  
    NoOpSelection.prototype.update = function (data) {
    };
  
    return NoOpSelection;
  });
  
  
  $.fn.select2.amd.define('cs.FilterMultiSelectResultDecorator', [], function() {
    var FilterMultiSelectResultDecorator = function(decoratedFn, $element, options, data) {
      decoratedFn.call(this, $element, options, data);
      this.options = options;
    };
  
    FilterMultiSelectResultDecorator.prototype.bind = function(decoratedFn, select2, $container) {
      var self = this;
      decoratedFn.call(self, select2, $container);
      
      // watch change of select element
      select2.$element.on('change', function($event) {
        var $select = $($event.currentTarget);
        var selectedValues = Array.isArray($select.val()) ? $select.val() : [$select.val()];
        $('input[type=checkbox]', $container).each(function(_index, el) {
          var $cb = $(el);
          var checked = selectedValues.indexOf($cb.val()) !== -1;
          $cb.prop('checked', checked);
        });
      });
    };
    
    FilterMultiSelectResultDecorator.prototype.template = function(decoratedFn, result, $container) {
      var self = this;
      decoratedFn.call(self, result, $container);
      
      $(self.$results).addClass('eh-product-filter--items eh-p-b');
      $($container).addClass('eh-product-filter--item');
      
      // prevent native checkbox behaviour, selection is handled by select2
      $('input[type=checkbox]', $container).on('click', function($event) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
      });

    };

    return FilterMultiSelectResultDecorator;
  });


  $.fn.select2.amd.define('cs.FilterMultiSelectCloseOnSelectDecorator', [], function() {
    var dataKey = 'cs.FilterMultiSelectCloseOnSelectDecorator_restoreOpenState';

    var FilterMultiSelectCloseOnSelectDecorator = function(decoratedFn, $element, options) {
      decoratedFn.call(this, $element, options);
    };

    FilterMultiSelectCloseOnSelectDecorator.prototype.bind = function(decoratedFn, container, $container) {
      var self = this;

      decoratedFn.call(self, container, $container);

      container.on('select', function(evt) {
        self._selectTriggered(evt);
      });

      container.on('unselect', function(evt) {
        self._selectTriggered(evt);
      });

      if (!this.options.options.closeOnSelect) {
        var openState = $(window).data(dataKey);
        if (openState && openState[self.$element.attr('id')] === true) {
          delete openState[self.$element.attr('id')];
          $(window).data(dataKey, openState);
            window.setTimeout(function() {
              self.trigger('query', {});
            }, 0);
          }
      }
    };

    FilterMultiSelectCloseOnSelectDecorator.prototype._selectTriggered = function() {
      var openState = $(window).data(dataKey) || {};
      openState[this.$element.attr('id')] = true;
      $(window).data(dataKey, openState);
      if (this.options.options.closeOnSelect) {
        this.trigger('close', {});
      }
    };

    return FilterMultiSelectCloseOnSelectDecorator;
  });


  $.fn.select2.amd.define('cs.FilterMultiSelectHierarchicalDecorator', [], function() {

    var FilterMultiSelectHierarchicalDecorator = function(decoratedFn, $element, options) {
      decoratedFn.call(this, $element, options);
    };

    FilterMultiSelectHierarchicalDecorator.prototype.bind = function(decoratedFn, container, $container) {
      var self = this;

      decoratedFn.call(self, container, $container);

      container.on('selecting', function(evt) {
        self._clickOnCaret(evt);
      });

      container.on('unselecting', function(evt) {
        self._clickOnCaret(evt);
      });
    };

    FilterMultiSelectHierarchicalDecorator.prototype._clickOnCaret = function(event) {
      if (event.params.args.originalEvent) {
        var $target = $(event.params.args.originalEvent.target);
        if ($target.hasClass('caret')) {
          event.preventDefault();
          var currentId = event.params.args.data.id;
          var $option = self.$element.find('option[value="' + currentId + '"]'),
            selectedLevel = $option.data('level');

          var show = !$target.closest('li').toggleClass('collapsed').hasClass('collapsed');
          getOptionTree($option).not($option).each(function() {
            var $o = $(this),
              level = $o.data('level');
            self.$results.find('input[value="' + $o.val() + '"]').closest('li').toggle(level === selectedLevel + 1 ? show : false).toggleClass('collapsed', true);
          });

          self.dropdown._positionDropdown();
        }
      }
    };

    return FilterMultiSelectHierarchicalDecorator;
  });
  
  
  $.fn.select2.amd.define('cs.DropDownSearchPromptDecorator', [], function() {
    
    var DropDownSearchPromptDecorator = function(decoratedFn, $element, options) {
      decoratedFn.call(this, $element, options);
    };
  
    DropDownSearchPromptDecorator.prototype.render = function(decorated) {
      var $rendered = decorated.call(this);
      return $rendered;
    }
    
    return DropDownSearchPromptDecorator;
  });
  
})();
