namespace eh {

  interface ScrollPanelVO {
    doc: HTMLDocument;
    maxWidth: number;
  }

  export class STICKY_EVENTS {
    static DOM_MUTATION: string = 'eh-stickymanager-on-dom-mutation';
    static STICKY_CHANGE: string = 'eh-sticky-scroller-on-change';
  }

  export class StickyManager {

    static instance: StickyManager;

    static SELECTOR: string = '.eh-quickselector--footer';
    static STICKY_SELECTOR = '.eh-inject-sticky';
    static SCROLL_PANELS: ScrollPanelVO[] = [
      {doc: document, maxWidth: -1}
    ];

    // needed tp update global offset
    static COOKY_DISCLAIMER_BODY: string = '.marker-cookie-disclaimer';
    static QUICKSELECT_OPTIONS_CONTAINER: string = '.eh-quickselector--options';

    static CONTEXT_PREFIX: string = 'ehScrollContext-';
    static CONTEXT_INC: number = 0;

    private winHeight: number = window.innerHeight;
    private globalOffset: number = 0;
    private cookieDisclaimer: JQuery<HTMLElement>;
    private quickselectOptionsContainer: JQuery<HTMLElement>;

    // map of all  possible scrolling areas
    private scrollContextMap: {[p: string]: ScrollContext} = {};
    private scrollContextList: ScrollContext[] = [];
    private scroller: StickyScroller;
    private currentContextId: string;
    private useSorting: boolean = true;

    private lastTimeout: number;
    private scrollerTicking: boolean = false;

    static timeout: number;

    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {

        if (isSnippetRequest) {
          return;
        }

        if (!!StickyManager.instance) {
          return;
        }

        StickyManager.instance = StickyManager.instance || new StickyManager();

        const instance: StickyManager = StickyManager.instance;
        const stickies: HTMLElement[] = [];

        $(StickyManager.STICKY_SELECTOR).each((_idx, el: HTMLElement) => {
          stickies.push(el);
        });

        if (!stickies.length) {
          // disabled on pages without stickies
          return;
        }

        instance.cookieDisclaimer = $(StickyManager.COOKY_DISCLAIMER_BODY);
        instance.quickselectOptionsContainer = $(StickyManager.QUICKSELECT_OPTIONS_CONTAINER);

        instance.observeMovement();
        instance.observeResize();
        instance.observeDomMuations();
        instance.updateGlobalOffset();
        instance.updateContextByWidth(window.innerWidth);

        const initialContext: ScrollContext = instance.getCurrentContext();

        instance.scroller = new StickyScroller(
          initialContext.scrollingElement,
          stickies,
          true,
          instance.globalOffset,
          instance.useSorting
        );

        instance.scroller.update(instance.globalOffset, instance.winHeight, null);

    }

    private updateGlobalOffset(): void {
      this.globalOffset = this.cookieDisclaimer.get(0)?.offsetHeight ?? 0;
    }

    private observeMovement(): void {
      // currently only support on y
      StickyManager.SCROLL_PANELS.forEach((scrollPanelVO: ScrollPanelVO) => {
        const scrollContext: ScrollContext = this.initContext(scrollPanelVO);
        const eventParams: any = !!eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
        this.scrollContextList.push(scrollContext);
        $(scrollContext.el).get(0)?.addEventListener('scroll', this.onMoveY, eventParams);
      });
    }

    private observeResize(): void {
      window.onresize = () => {
        clearTimeout(this.lastTimeout);
        this.winHeight = window.innerHeight;
        this.updateContextByWidth(window.innerWidth);
        this.lastTimeout = window.setTimeout(this.init, 100, true);
      };
    }

    private observeDomMuations(): void {
      $(':root').on(STICKY_EVENTS.DOM_MUTATION, (e: JQuery.TriggeredEvent, el: {element: JQuery<HTMLElement>}) => {
        // init before transforming elements
        this.init(false);
        if (el && el.element) {
            this.scroller.scrollElementIntoView(el.element, this.quickselectOptionsContainer);
        }
      });
    }

    public init = (windowChanged: boolean): void => {
      this.updateGlobalOffset();
      this.scroller.setElement(this.getCurrentContext().scrollingElement);
      this.scroller.update(this.globalOffset, this.winHeight, windowChanged);
    };

    private getCurrentContext(): ScrollContext {
      return this.scrollContextMap[this.currentContextId];
    }

    private updateContextByWidth(width: number): void {
      let targetContext: ScrollContext | undefined;
      this.scrollContextList.forEach((sc: ScrollContext): void => {
        if (sc.maxWidth >= width && !targetContext) {
          targetContext = sc;
        }
      });
      targetContext = targetContext || this.scrollContextList[0];

      if (this.currentContextId !== targetContext.el.scrollContextId) {
        // context has changed
        this.currentContextId = targetContext.el.scrollContextId;
      }
    }

    private initContext = (spVO: ScrollPanelVO): ScrollContext => {
      const scrollContextId: string = StickyManager.CONTEXT_PREFIX + (++StickyManager.CONTEXT_INC);
      const el: ScrollContextEl = spVO.doc as ScrollContextEl;
      if (!this.currentContextId) {
        this.currentContextId = scrollContextId;
      }
      // store ref for fast mapping
      el.scrollContextId = scrollContextId;
      return this.scrollContextMap[scrollContextId] = new ScrollContext(el, spVO.maxWidth);
    };

    private getContextByElement(el: ScrollContextEl): ScrollContext | undefined {
      return this.scrollContextMap[el.scrollContextId];
    }

    private onMoveY = (e: Event): void => {
      if (!this.scrollerTicking) {
        requestAnimationFrame(() => {
          const context: ScrollContext | undefined = this.getContextByElement(e.target as ScrollContextEl);
          if (context) {
            this.scroller.onScroll(context.getScrollValue() + this.winHeight);
          }
          this.scrollerTicking = false;
        });
      }
      this.scrollerTicking = true;

    }

  }

  /**
   * Wrap available scrollable areas.
   */
  class ScrollContext {

    public offsetY: number;
    public scrollingElement: HTMLElement | null;
    private possibleScrollTargets: HTMLElement[];

    constructor(
      public el: ScrollContextEl,
      public maxWidth: number
    ) {
      this.possibleScrollTargets = [
          $('html').get(0)!,
          $('body').get(0)!
      ];
    }

    public isContextOf(el: HTMLDocument): boolean {
      return this.el === el;
    }

    public getScrollValue(): number {
      if (!this.scrollingElement) {
        this.possibleScrollTargets.forEach(t => {
          if (t.scrollTop > 0) {
            this.scrollingElement = t;
          }
        });
      }
      return this.scrollingElement?.scrollTop ?? 0;
    }

  }

  interface ScrollContextEl extends HTMLDocument {
    scrollContextId: string;
  }





  class Sticky {

    static ZERO_STICKY: { elHeight:  number, absPosition: number,
      documentOrder: number } = { elHeight: 0, absPosition: 0, documentOrder: -1 };

    public elHeight: number = NaN;
    public previousSibling: HTMLElement;

    public offY: number;
    public absPosition: number;
    public storedOffsets: number;
    public globalOffset: number = 0;

    // buffer
    public segmentOffsets: number[];

    private lastStickyState: boolean = false;
    private preClassNames: string[];
    private storedStickyHeight: number;

    constructor (
      public el: HTMLElement,
      public documentOrder: number,
      protected additionalClassNames: string[]
    ) {
      this.previousSibling = document.createElement('div');
      (el.parentElement as HTMLElement).insertBefore(this.previousSibling, el);
      const preClassNames: string | null = el.getAttribute('data-sticky-pre-classes');
      this.preClassNames = !!preClassNames ? preClassNames.split(', ') : [];
      this.initBaseMetrics();
    }

    public initBaseMetrics(): void {
      this.onBeforeFreeze();
      requestAnimationFrame(() => {
        this.storedStickyHeight = this.el.offsetHeight;
        this.onAfterFreeze();
      });
    }

    public applyOffsetByIdx (idx: number): void {
      this.storedOffsets = this.segmentOffsets[idx + 1];
      this.el.style.bottom = this.offY + this.storedOffsets + 'px';
    }

    public freezeTransforms (): void {
      const ps: HTMLElement = this.previousSibling;
      this.elHeight = this.storedStickyHeight || this.el.offsetHeight;
      this.absPosition = offsetTopToBody(ps) + ps.offsetHeight;
    }

    public reset (): void {
      this.storedOffsets = 0;
      this.stick(false);
    }

    private onBeforeFreeze: () => void = (): void => {
      this.preClassNames.forEach((className: string): void => this.el.classList.add(className));
    };

    private onAfterFreeze: () => void = (): void => {
      this.preClassNames.forEach((className: string): void => this.el.classList.remove(className));
    };

    public stick (v: boolean): void {
      if (this.lastStickyState != v) {
        const classes: string[] = ['scrolling-sticky'].concat(this.additionalClassNames);
        classes.concat(this.additionalClassNames);
        if (v) {
          classes.forEach(c => this.el.classList.add(c));
        } else {
          classes.forEach(c => this.el.classList.remove(c));
        }
        this.lastStickyState = v;
      }
    }

    public transitionEnabled (v: boolean): void {
      const clazz = 'transition';
      if (v) {
        this.el.classList.add(clazz);

      } else {
        this.el.classList.remove(clazz);
      }
    }

  }

  class SortedSticky extends Sticky {

    public sortingNormalized: number;

    constructor (
      public el: HTMLElement,
      public sorting: number,
      public docOrder: number,
      protected additionalClassNames: string[]
    ) {
      super(el, docOrder, additionalClassNames);
    }

    public isReordered (): boolean {
      return this.sortingNormalized !== this.documentOrder;
    }

    public isBelow (sticky: SortedSticky): boolean {
      return sticky.sortingNormalized < this.sortingNormalized
        && sticky.documentOrder > this.documentOrder;
    }

    public isAbove (sticky: SortedSticky): boolean {
      return sticky.sortingNormalized > this.sortingNormalized
        && sticky.documentOrder < this.documentOrder;
    }

    public isDocOrder (): boolean {
      return this.documentOrder === this.sortingNormalized;
    }

  }

  class StickyScroller {

    static SORT_ATTR_KEY: string = 'data-sorting';
    static ADDITIONAL_CLASS_NAMES: string = 'data-sticky-classes';

    public currentSegment: ScrollerSegment;

    private segments: ScrollerSegment[];
    private winHeight: number;
    private stickiesUnsorted: SortedSticky[];
    private stickiesSorted: SortedSticky[];
    private lastResizeEvent: number;

    private heightMapSorted: number[];
    private hasScrollListener: boolean = false;
    private hasResizeListener: boolean = false;

    constructor (
      private el: HTMLElement | null,
      private stickies: Element[],
      private isManaged: boolean = false,
      private globalOffset: number = 0,
      private useSorting: boolean = false
    ) {
      this.winHeight = window.innerHeight;
      this.generateStickies();
    }

    public setElement(newElement: HTMLElement | null): void {
      this.el = newElement;
    }

    private getTotalPosOf(el: HTMLElement): number {
      return offsetTopToBody(el) + el.offsetHeight + this.globalOffset;
    }

    public scrollElementIntoView(el: JQuery<HTMLElement>, parent: JQuery<HTMLElement>): void {

      if (!el || !el.length) {
        return;
      }

      const parentEl = parent.get(0);
      const absPos: number = parentEl ? this.getTotalPosOf(parentEl) : 0;

      const lastSegmentInRange: ScrollerSegment | undefined = this.segments
        .filter(s => s.minY > absPos)
        .reverse()
        .pop();

      if (!lastSegmentInRange) {
        return;
      }

      let heightActiveStickies: number = 0;
        for (let i = 0; i < this.stickiesUnsorted.length; i++) {
          if (i > lastSegmentInRange.idx - 1) {
            heightActiveStickies += this.stickiesUnsorted[i].elHeight;
          }
        }

      const lastScrollTop: number = this.el?.scrollTop ?? 0;
      const feedbackLabelsHeight: number = $('.eh-quickselector--selection-options-feedback').get(0)?.offsetHeight ?? 0;
      const keepStickyActivePixel: number = 1;
      const targetScrollPos: number = feedbackLabelsHeight + absPos - this.winHeight + heightActiveStickies - keepStickyActivePixel;
      const maxTargetScrollPos: number = Math.max(lastScrollTop, targetScrollPos);

      const beginTimer: number = performance.now();
      const duration: number = 150;

      let currentProgress: number;
      const tween: NodeJS.Timeout = setInterval(() => {
        currentProgress = (performance.now() - beginTimer) / duration;
        if (currentProgress < 1) {
          if (this.el) {
            this.el.scrollTop = lastScrollTop + this.lerp(lastScrollTop, maxTargetScrollPos, currentProgress);
          }
        } else {
          if (this.el) {
            this.el.scrollTop = maxTargetScrollPos;
          }
          clearInterval(tween);
        }
      }, 1);

    }

    private lerp(from: number, to: number, progress: number): number {
      return Math.ceil((to - from) * progress);
    }

    private generateStickies(): void {

      let docOrderInc: number = 0;
      this.stickiesUnsorted = this.stickies.map((_el: HTMLElement): SortedSticky => {
        const sorting: number = this.useSorting ?
          parseInt(_el.getAttribute(StickyScroller.SORT_ATTR_KEY) || '', 10) || -1 : -1;
        const addClassData: string = _el.getAttribute(StickyScroller.ADDITIONAL_CLASS_NAMES) || '';
        const additionalClasses: string[] = !!addClassData ? addClassData.split(', ') : [];
        return new SortedSticky(_el, sorting, docOrderInc++, additionalClasses);
      });
      this.stickiesUnsorted.forEach(_s => _s.freezeTransforms());
      this.stickiesSorted = this.stickiesUnsorted.concat();

      if (this.useSorting) {
        this.stickiesSorted.sort((a: SortedSticky, b: SortedSticky): number => (a.sorting > b.sorting) ? -1 : 1 );
      }
    }

    private buildHeightMapFrom(_stickies: SortedSticky[]): number[] {
      const heights: number[] = _stickies.map(s => s.elHeight);
      const offsetMap: number[] = heights
        .reverse()
        .reduce((heightMap: number[], height: number, i: number) => {
          heightMap.push(i > 0 ? height + heightMap[i-1] : height);
          return heightMap;
        }, [])
        .reverse();

      return offsetMap;
    }

    /**
     * external manager might call updates (e.g. resize | dom mutations)
     * @param {number | null} globalOffset
     */
    public update: (v: number | null, w: number | null, wc: boolean | null) => void = (
      globalOffset: number | null,
      winHeight: number | null,
      windowChanged: boolean | null
    ): void => {
      if (!isNaN(globalOffset as any)) {
        this.globalOffset = globalOffset || 0;
      }
      if (!isNaN(winHeight as any)) {
        this.winHeight = winHeight || 0;
      }
      if (windowChanged) {
        this.stickiesUnsorted.forEach(_s => _s.initBaseMetrics());
      }
      this.initStickies();
    };

    private init(): void {
      this.buildSegments();
      this.setSegmentsTriggerAtTop();
      this.initSegments();
      this.generateStickyTransformBuffers();
      this.updateCurrentSegment(this.getScrollerTotalPos());
      if (!this.isManaged) {
        this.registerScrolling();
        this.registerResize();
      }
    }

    private initStickies(): void {

      // get initial bottom position
      // based on sorting

      this.stickiesSorted.forEach(_s => {
        _s.transitionEnabled(false);
        _s.reset();
        _s.freezeTransforms();
      });

      this.heightMapSorted = this.buildHeightMapFrom(this.stickiesSorted);

      for (let i: number = 0; i < this.stickiesSorted.length; i++) {
        const _s: SortedSticky = this.stickiesSorted[i];
        _s.storedOffsets = 0;
        _s.offY = this.heightMapSorted[i] - _s.elHeight + this.globalOffset;
        _s.sortingNormalized = this.useSorting ? this.stickiesSorted.length - _s.sorting : _s.documentOrder;
        _s.freezeTransforms();
        _s.transitionEnabled(true);
      }

      this.init();
      this.doScroll();

    }

    private buildSegments (): void {
      this.segments =
        /* full-sweep ZeroIndex */
        [Sticky.ZERO_STICKY].concat(this.stickiesUnsorted)
          .map( (sticky: Sticky) => {
            return new ScrollerSegment(
              sticky.documentOrder,
              sticky.absPosition + this.globalOffset);
          });

    }

    private setSegmentsTriggerAtTop(): void {
      // shift segments to triggering when the top edge is reached
      const heightMapUnsorted: number[] = this.buildHeightMapFrom(this.stickiesUnsorted);
      const heightMap: number[] = heightMapUnsorted;
      for (let i = 0; i < heightMap.length; i++) {
        this.segments[i + 1].offset(heightMap[i]);
      }
    }

    /**
     * manager might call scroll updates.
     * @param {number} scrollerTotalPos
     */
    public onScroll (scrollerTotalPos: number): void {
      if (!this.currentSegment.isInRange(scrollerTotalPos)) {
        this.updateCurrentSegment(scrollerTotalPos);
      }
    }

    private doScroll: () => void = (): void => {
      this.onScroll(this.getScrollerTotalPos());
    };

    private registerScrolling(): void {
      if (this.hasScrollListener) {
        return;
      }
      this.hasScrollListener = true;
      let ticking: boolean = false;
      const eventParams: any = !!eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
      this.el?.addEventListener('scroll', () => {
        if (!ticking) {
          requestAnimationFrame(() => {
            this.doScroll();
            ticking = false;
          });
          ticking = true;
        }
      }, eventParams);
    }

    private registerResize(): void {
      if (this.hasResizeListener) {
        return;
      }
      this.hasResizeListener = true;
      window.addEventListener('resize', (): void => {
        clearTimeout(this.lastResizeEvent);
        this.winHeight = window.innerHeight;
        this.lastResizeEvent = window.setTimeout(this.update, 50);
      });
    }

    private getScrollerTotalPos (): number {
      return (this.el?.scrollTop ?? 0) + this.winHeight;
    }

    private initSegments(): void {
      // apply maxY
      for (let j = 0; j < this.segments.length; j++) {
        const next = this.segments[j + 1],
          // negative 1 means infinity
          nextMax = !!next ? next.minY : -1;
        this.segments[j].maxY = nextMax;
      }
      if (!this.currentSegment) {
        this.currentSegment = this.segments[0];
      }
    }

    private generateStickyTransformBuffers(): void {
      // add transformation buffer to items
      const zeroOffsets = this.segments.map((s) => 0);
      this.stickiesSorted.forEach(i => i.segmentOffsets = zeroOffsets.concat());

      for (let sIndex = 0; sIndex < this.segments.length -1; sIndex++) {
        let sticky: SortedSticky = this.stickiesUnsorted[sIndex];
        // the executing segment idx
        let totalOffset = 0;
        const nextIndex: number = sIndex + 1;
        this.stickiesUnsorted.forEach((s: SortedSticky): void => {
          if (sIndex) {
            // copy buffer to init next segment
            s.segmentOffsets[nextIndex] = s.segmentOffsets[sIndex];
            // subtract delta of nested elements
            if (s.isBelow(sticky)) {
              s.segmentOffsets[nextIndex] -= sticky.elHeight;
              sticky.segmentOffsets[nextIndex] -= totalOffset;
            }
          }
          if (s.isAbove(sticky)) {
            totalOffset += s.elHeight;
            s.segmentOffsets[nextIndex] -= sticky.elHeight;
          }
        });
        sticky.segmentOffsets[nextIndex] += totalOffset;
      }

    }

    private updateCurrentSegment(scrollerTotalPos: number): void {
      this.segments.forEach((_i: ScrollerSegment) => {
        if (_i.isInRange(scrollerTotalPos)) {
          this.currentSegment = _i;
          this.onCurrentSegmentChange();
          return;
        }
      });
    }

    private onCurrentSegmentChange (): void {
      $(':root').trigger(STICKY_EVENTS.STICKY_CHANGE, {segment: this.currentSegment});
      this.stickiesUnsorted.forEach(_s => {
        _s.applyOffsetByIdx(this.currentSegment.idx);
        _s.stick(_s.documentOrder > this.currentSegment.idx);
      });
    }

  }

  export class ScrollerSegment {

    private _offset: number = 0;

    constructor (
      public idx: number,
      public minY: number,
      public maxY: number = 0
    ) {}

    public offset (v: number): void {
      this._offset += v;
      this.minY += v;
    }

    public get length (): number {
      return this.minY - this._offset;
    }

    public isInRange (v: number): boolean {
      return v >= this.minY && (v < this.maxY || this.maxY === -1);
    }

    public toString(): string {
      return `\nScrollerSegment: IDX[${this.idx}] min[${this.minY}] max[${this.maxY}] offset[${this._offset}]`;
    }

  }





}











