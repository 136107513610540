namespace eh {
  
  /*
   - show or hide language selector
   - update button link with target url
   - execute link and close overlay
   - execute close / goto-www link and close overlay
   - open overlay on startup
   - set cookie before execution
   */
  export class MarketChooser {
    
    public static readonly SELECTOR = '.marker-market-chooser-www';
    public static readonly DATA_KEY = 'ehMarketChooser';
    public static readonly HOLDER_ID = 'marketChooserHolder';
    
    private static readonly CLASS_HIDDEN = 'eh--hide';
    
    static init($base: JQuery<HTMLElement>): void {
      
      $(MarketChooser.SELECTOR, $base).each((_index, el) => {
        const $marketChooserRoot = $(el);
  
        const mc = new MarketChooser($marketChooserRoot);
        $marketChooserRoot.data(MarketChooser.DATA_KEY, mc);
        $marketChooserRoot.one(eh.Overlay.EventIdPreClose, () => {
          mc.rememberCountryChooserShown();
        });
        
        const $holder = $marketChooserRoot.selfOrClosest('#' + MarketChooser.HOLDER_ID);
        // replaced by merketselector header
        // if ($holder.length === 1) {
        //   // on pageload trigger open in overlay
        //   const msg = new Message('*:open-id');
        //   msg.putParam('title', $holder.data('heading'));
        //   msg.putParam('id', MarketChooser.HOLDER_ID);
        //   window.postMessage(msg, '*');
        // }
        
        $marketChooserRoot.selfOrClosest('.marker-market-chooser-www').each((_index, el) => {
          const $el = $(el);
          $el.selfOrFind('.js-overlay-close, .eh-overlay--close').removeClass('disabled');
          $el.selfOrFind('.js-overlay-close:not(.disabled, [disabled]), .eh-overlay--close:not(.disabled, [disabled])')
            .on('click', $event => {
            $event.preventDefault();
            mc.rememberCountryChooserShown();
            $el.replaceWith('');
          });
          // this was .selfOrFind('.disabled'), which also found a disabled link in NeBP Maintenance mode
          $el.selfOrFind('.trigger-choose-market').removeClass('disabled');
        });
        
      });
      
    }
    
    private readonly $buttonChoose: JQuery<HTMLElement>;
    private readonly $languageSection: JQuery<HTMLElement>;
    private readonly $selectCountry: JQuery<HTMLElement>;
    private readonly $selectLanguage: JQuery<HTMLElement>;
    private readonly context;
    
    constructor(public readonly $elem: JQuery<HTMLElement>) {
      this.$selectCountry = $('#country', this.$elem);
      if (this.$selectCountry.length === 0) {
        this.$selectCountry = $('#country2', this.$elem);
      }
      this.$selectLanguage = $('#language', this.$elem);
      this.$buttonChoose = $('.trigger-choose-market', this.$elem);
      this.$languageSection = $('.marker-language-section', this.$elem);
      this.context = this.$elem.data('marketChooserContext') ?? '';
      
      this.$selectCountry.on('change', this.handleCountryChange);
      this.$selectLanguage.on('change', this.handleLanguageChange);
      this.$buttonChoose.on('click', this.handleChooseButtonClick);
  
      this.handleCountryChange();
    }
    
    private manageLanguageVisibility() {
      if (this.$selectLanguage.length === 0) {
        return;
      }
      const $selectedCountry = $('option:selected', this.$selectCountry);
      const countryCode = $selectedCountry.val();
      const $languageOptions = $(`option[data-country-code="${countryCode}"]`, this.$selectLanguage);
      const showLanguages = $languageOptions.length > 1;
      let selectNextOption = $languageOptions.filter(':selected').length === 0;
      $('option', this.$selectLanguage).each((_index, el) => {
        const $option = $(el);
        const showOption = $option.data('countryCode') === countryCode;
        if (showOption && selectNextOption) {
          $option.prop('selected', true);
          selectNextOption = false;
        }
        $option.prop('disabled', !showOption).toggleClass(MarketChooser.CLASS_HIDDEN, !showOption);
      });
      this.$languageSection.toggleClass(MarketChooser.CLASS_HIDDEN, !showLanguages);
      this.$selectLanguage.trigger('change');
    }
    
    private handleCountryChange = () => {
      const $selected = $('option:selected', this.$selectCountry);
      this.$buttonChoose.attr('href', $selected.data('link')).data('chosenTarget', $selected.data('chosenTarget'));
      this.manageLanguageVisibility();
    };
    
    private handleLanguageChange = () => {
      const $selected = $('option:selected', this.$selectLanguage);
      this.$buttonChoose.attr('href', $selected.data('link')).data('chosenTarget', $selected.data('chosenTarget'));
    };
    
    private handleChooseButtonClick = () => {
      const marketChooserTarget = this.$buttonChoose.data('chosenTarget');
      eh.setCookie('ii-country-redirect', marketChooserTarget, 90, false, 'Lax');
      this.rememberCountryChooserShown();
    };
  
    public rememberCountryChooserShown() {
      if (!this.context) {
        return;
      }
      eh.setCookie(this.context + '-countrychooser-shown', '1', 0, false, 'Lax');
    }
    
  }
}