namespace eh {

  export class QuickSelect {
    
    public static readonly SELECTOR = '.eh-quickselect';
    public static readonly DATA_KEY = 'ehQuickSelect';
    
    private static readonly CLASS_DISABLED = 'disabled';
    private static readonly CLASS_HIDDEN = 'eh--hide';
    private static readonly CLASS_TOGGLE_IS_OPEN = 'is-open';
  
    public static readonly FEATURE_CODING_CHILD_PRODUCT_SELECTION = '000';
    
    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {
      if (isSnippetRequest) {
        return;
      }

      $(QuickSelect.SELECTOR, $base).each((_index, el) => {
        const $quickSelectRoot = $(el);
        
        const orderCodeLabels: QSOrderCodeLabel[] = [];
        $(QSOrderCodeLabel.CONTAINER_CLASS_NAME, $base).each((_idx: number, el: HTMLElement): void => {
          orderCodeLabels.push(new QSOrderCodeLabel($(el)));
        });
        const $quickSelectHeader = $('.marker-quickselect-header', $quickSelectRoot);
        $quickSelectRoot.one(QS_EVENTS.INIT, (_$event: JQuery.TriggeredEvent, orderCode: OrderCodeVO) => {
          orderCodeLabels.forEach(orderCodeLabel => orderCodeLabel.update(orderCode));
          $quickSelectHeader.toggleClass(QuickSelect.CLASS_HIDDEN, false);
        });
        
        const $addToCartButtons = $('.marker-add-to-cart', $quickSelectRoot).add('.marker-add-to-cart.marker-quickselect-mirror');
        const amountSettingsConfig: EhNumericStepperOptions = {
          min: 1,
          max: 99,
          onChange: (event: JQuery.TriggeredEvent | null, value: string) => {
            $addToCartButtons.attr('data-nebp-add2cart-amount', value);
          }
        };
        $('.eh-numeric-stepper', $quickSelectRoot)
            .ehNumericStepper(amountSettingsConfig)
            .ehNumericStepper('toggleDisabled', $addToCartButtons.hasClass(QuickSelect.CLASS_DISABLED));
  
        const $orderCodeLegendBackButton = $('.eh-quickselector--product-result-details-back-button', $quickSelectRoot);
        const $orderCodeLegendToggleButton = $('.eh-quickselector--toggle-button .eh-link', $quickSelectRoot);
        const $orderCodeLegendToggleRow = $('.eh-quickselector--toggle-row', $quickSelectRoot);
        const $orderCodeResults: JQuery<HTMLElement> = $('.eh-quickselector--product-result-details-data', $quickSelectRoot);
        $orderCodeLegendToggleButton.add($orderCodeLegendToggleRow).add($orderCodeLegendBackButton)
          .on( 'click', ($event) => {
            $event.preventDefault();
            $orderCodeLegendToggleButton.toggleClass(QuickSelect.CLASS_TOGGLE_IS_OPEN);
            $orderCodeResults.toggleClass(QuickSelect.CLASS_TOGGLE_IS_OPEN);
            const isOpen: boolean = $orderCodeResults.hasClass(QuickSelect.CLASS_TOGGLE_IS_OPEN);
            eh.ScrollPage.setScrollEnabled(!isOpen);
            $(':root').trigger(STICKY_EVENTS.DOM_MUTATION);
          });
        
        const $configureButtonsGlobal = $('.marker-configure-action', $quickSelectRoot).add('.marker-configure-action.marker-quickselect-mirror');
        const $configureLinkGlobal = $('.marker-configure-link-global', $quickSelectRoot);
        const $extOrderCodeReceiver = $('.marker-quickselect-ordercode-mirror');
        QSViewModel.copyAttributes($configureButtonsGlobal, $configureLinkGlobal,
            ['class', 'href', 'data-cs-tracking-goals', 'data-cs-tracking-ps-name',
              'data-cs-tracking-subtype', 'data-cs-tracking-title']);
        QSViewModel.notifyReplaced($configureLinkGlobal.parent());
        
        const predefinedOrderCode = $quickSelectRoot.data('quickSelectPredefinedOrderCode');
        if (predefinedOrderCode) {
          window.setTimeout(() => {
            eh.Tracking.injectTrackingEvent({
              'event_configuration_step': 'fully configured',
              'event_linkname': 'product is fully configured',
              'event_order_code': predefinedOrderCode,
              'event_subtype': 'quick_select_fully_configured_generated'
            }, $quickSelectRoot, 'content_link');
          }, 0);
  
          $quickSelectRoot.data('quickSelectTrackingInfo', {
            'fullyConfigured': true,
            'orderCode': predefinedOrderCode
          });
        }
        
        const config: QuickSelectConfiguration = $quickSelectRoot.data('quickSelectConfig');
        if (!config || Object.keys(config).length === 0) {
          QuickSelect.markAsDisabled($quickSelectRoot);
          return;
        }
        
        const qs = new QuickSelect($quickSelectRoot, config);
        $quickSelectRoot.data(QuickSelect.DATA_KEY, qs);
        
        const $additionalOptionsRow = $('.marker-additional-options', $quickSelectRoot);

        $quickSelectRoot.on(QS_EVENTS.ORDER_CODE_CHANGE, (_$event: JQuery.TriggeredEvent, orderCodeVO: OrderCodeVO) => {
          orderCodeLabels.forEach(orderCodeLabel => orderCodeLabel.update(orderCodeVO));
          qs.updateTrackingInfo();
          
          if (orderCodeVO.pattern) {
            const orderCodeExternalized = QuickSelect.prepareOrderCodeForExternals(orderCodeVO.pattern);
            $('.eh-quickselector--selection-option-description-details a', $quickSelectRoot)
              .add($configureButtonsGlobal).add($configureLinkGlobal).add($addToCartButtons).add($extOrderCodeReceiver)
              .attr('data-nebp-product-ordercode', orderCodeExternalized)
              .attr('data-nebp-product-root', orderCodeVO.orderCode.productRoot)
              .attr('data-nebp-product-matnr', qs.getSelectedChildProduct()?.materialNumber ?? qs.config.productMaterialNumber)
            ;
          }
          $additionalOptionsRow.toggleClass(QuickSelect.CLASS_HIDDEN, true);
          $addToCartButtons.attr('data-nebp-activate-config-complete-check', 'false');
        });

        $quickSelectRoot.on(QS_EVENTS.ORDER_CODE_HIGHLIGHT, (_$event: JQuery.TriggeredEvent, idx: number) =>
          orderCodeLabels.forEach(orderCodeLabel => orderCodeLabel.highlightSegment(idx)));

        const $orderCodeLegendButtonGroup = $('.eh-quickselector--product-result-details-slot-2', $quickSelectRoot);
        const closeOrderCodeLegend: (e: JQuery.TriggeredEvent, s: {segment: ScrollerSegment}) => void = (e: JQuery.TriggeredEvent, s: {segment: ScrollerSegment}): void => {

          // viewConfigurationFlag is a workaround to fullfill special site structure requirements.
          // the current scenario matches only when moving through upper-elements-threshold.
          // the task is: prevent closing when scrolling down the site beneath the OrderCodeLegend-View.
          const viewConfigurationFlag: boolean = s.segment.idx === -1;

          if (viewConfigurationFlag && $orderCodeResults.hasClass(QuickSelect.CLASS_TOGGLE_IS_OPEN)) {
            $orderCodeLegendToggleButton.toggleClass(QuickSelect.CLASS_TOGGLE_IS_OPEN);
            $orderCodeResults.toggleClass(QuickSelect.CLASS_TOGGLE_IS_OPEN);
            eh.ScrollPage.setScrollEnabled(true);
            $(':root').trigger(STICKY_EVENTS.DOM_MUTATION);
          }
        };

        const browser = window.browserDetect();
        const isMobileSafari = browser.name === 'safari' && browser.mobile;
        // view resize events trigger initialization of stickies.
        // when the stickies initialize we discard the order-code-legend
        // because of its view dependant layout ordering and parent-child-relationship.
        // but if the user is surfing on his iphone its not needed to
        // throw the legend away because of the iphones small screen size
        // and the layout structure.
        // @TODO: refactor the layout - split the parts to prevent layout glitches in stickies
        if (!isMobileSafari) {
          $(':root').on(STICKY_EVENTS.STICKY_CHANGE, closeOrderCodeLegend);
        }

        $quickSelectRoot.on(QS_EVENTS.ORDER_CODE_LEGEND_CHANGE, (_$event: JQuery.TriggeredEvent,
                                                                 data: {legend: IProductSegment[]}) => {
          qs.orderCodeLegend.update(data.legend);
          if (data.legend.length === 0) {
            $orderCodeLegendButtonGroup.toggleClass(QuickSelect.CLASS_HIDDEN, true);
            $orderCodeLegendBackButton.toggleClass(QuickSelect.CLASS_HIDDEN, true);
            $orderCodeLegendToggleButton.toggleClass(QuickSelect.CLASS_TOGGLE_IS_OPEN, false);
            $orderCodeResults.toggleClass(QuickSelect.CLASS_TOGGLE_IS_OPEN, false);
          }
          else {
            $orderCodeLegendButtonGroup.toggleClass(QuickSelect.CLASS_HIDDEN, false);
            $orderCodeLegendBackButton.toggleClass(QuickSelect.CLASS_HIDDEN, false);
          }
        });
        
        $quickSelectRoot.on(QS_EVENTS.ORDER_CODE_COMPLETE, () => {
          orderCodeLabels.forEach(orderCodeLabel => orderCodeLabel.highlightSegment(-1));
          qs.updateTrackingInfo();
          
          const orderCode = qs.getCurrentStep()?.orderCode;
          if (orderCode?.pattern) {
            const orderCodeExternalized = QuickSelect.prepareOrderCodeForExternals(orderCode.pattern);
            $('.eh-quickselector--selection-option-description-details a', $quickSelectRoot)
                .add($configureButtonsGlobal).add($configureLinkGlobal).add($addToCartButtons)
                .attr('data-nebp-product-ordercode', orderCodeExternalized)
                .attr('data-nebp-product-root', orderCode.productRoot)
                .attr('data-nebp-product-matnr', qs.getSelectedChildProduct()?.materialNumber ?? qs.config.productMaterialNumber)
            ;
          }
          $additionalOptionsRow.toggleClass(QuickSelect.CLASS_HIDDEN, false);
          $addToCartButtons.attr('data-nebp-activate-config-complete-check', 'true');
        });

      });

    }
    
    static markAsDisabled($elem: JQuery<HTMLElement>): void {
      $elem.toggleClass('eh-quickselect-disabled', true);
    }

    static isDisabled($elem: JQuery<HTMLElement>): boolean {
      return $elem.hasClass('eh-quickselect-disabled');
    }
    
    public view: QSViewModel;
    
    private initialized = false;
    private readonly quickSelectService: QuickSelectService;
    private state: State;
    private readonly steps: Step[] = [];
  
    public readonly orderCodeLegend: QSOrderCodeLegend;
    public readonly priceScaleLegend: QSPriceScaleLegend;
    
    constructor(public readonly $elem: JQuery<HTMLElement>,
                public readonly config: QuickSelectConfiguration) {
      this.quickSelectService = new QuickSelectService(config.serviceUrl)
          .withStaticHeader('X-Api-Key', config.apiKey)
          .withStaticQueryParam('vkorg', config.salesOrganization)
          .withStaticQueryParam('language', config.language)
      ;
      this.view = new QSViewModel(this, this.$elem);
      this.orderCodeLegend = new QSOrderCodeLegend($(QSOrderCodeLegend.CONTAINER_CLASS_NAME, this.$elem));
      this.priceScaleLegend = new QSPriceScaleLegend($(QSPriceScaleLegend.CONTAINER_CLASS_NAME, this.$elem));
      
      if (config.childProducts.length > 0) {
        const orderCodeCheck = new OrderCodeCheckState(this, this.$elem, this.quickSelectService,
            config.productRoot, config.childProducts);
        this.changeState(orderCodeCheck);
        orderCodeCheck.observeLoading(orderCodeCheck.check());
      }
      else {
        this.changeState(new InitialState(this, this.$elem, this.quickSelectService, config.productRoot, config.childProducts));
      }
      
      this.updateTrackingInfo();
    }

    public changeState(newState: State): void {
      this.state = newState;
    }
    
    public getCurrentStep(): Step | undefined {
      return this.steps[this.steps.length - 1];
    }
    
    public getStepIndex1(featureCoding?: string): number {
      let idx = -1;
      this.steps.some((v, i) => {
        if (v.featureCoding === featureCoding) {
          idx = i;
          return true;
        }
        return false;
      });
      return idx + 1;
    }
    
    public storeStep(step: Step): void {
      let idx = -1;
      this.steps.some((v, i) => {
        if (v.featureCoding === step.featureCoding) {
          idx = i;
          return true;
        }
        return false;
      });
      this.steps.push(step);
    }
  
    /**
     * Rollback the already made steps up to the one with the given featureCoding, then "finish" that again with the
     * given option value
     */
    public rewindSteps(featureCoding: string, coding: string) {
      let idx = -1;
      this.steps.some((v, i) => {
        if (v.featureCoding === featureCoding) {
          idx = i;
          return true;
        }
        return false;
      });
      if (idx === -1 || idx >= this.steps.length) {
        return;
      }
      this.steps.splice(idx + 1);
      this.getCurrentStep()?.finish(coding);
    }
    
    public getSelectedChildProduct(): ChildProduct | undefined {
      return this.steps?.[0]?.childProduct;
    }
    
    public setDisabled(): void {
      QuickSelect.markAsDisabled(this.$elem);
    }

    public isDisabled(): boolean {
      return QuickSelect.isDisabled(this.$elem);
    }
    
    public setInitialized(): void {
      this.initialized = true;
    }
  
    public isInitialized(): boolean {
      return this.initialized;
    }
    
    updateTrackingInfo() {
      const info = this.createTrackingInfo();
      if (info.orderCode) {
        this.$elem.data('quickSelectTrackingInfo', info);
      }
    }
    
    createTrackingInfo(): QuickSelectTrackingInfo {
      const step = this.getCurrentStep();
      const ocs = step?.orderCodeString;
      return {
        orderCode: ocs ? QuickSelect.prepareOrderCodeForExternals(ocs) : undefined,
        fullyConfigured: step?.orderCode?.getOpenPositions() === 0,
        currentStepIndex: this.getStepIndex1(step?.featureCoding),
        currentStepFeature: step?.featureCoding
      };
    }
    
    public getSelectedOptions(): string[] {
      const selectedOptions = this.steps.map((step) => {
        return step.serializeOptions();
      }).reduce((accumulated, val) => accumulated.concat(val), []);
      selectedOptions.sort();
      return selectedOptions;
    }
    
    public static prepareOrderCodeForExternals(orderCodeString: string): string {
      const pos = orderCodeString.indexOf('-');
      return orderCodeString.substring(0, pos + 1) +  orderCodeString.substring(pos + 1)
          .replace(/-/g, '');
    }
    
    public static injectURLParam(url: string, paramName: string, paramValue: string): string {
      const params = eh.URLHelper.buildQueryParamMap(eh.URLHelper.getQueryString(url));
      params[paramName] = paramValue;
      return eh.URLHelper.buildUrl(url, eh.URLHelper.buildQueryString(params));
    }
    
    public static serialize(productRoot: string, featureCoding: string, optionCoding: string) {
      return [productRoot, QuickSelect.ensureCodingWidth(featureCoding), optionCoding].join('_');
    }
    
    public static ensureCodingWidth(coding: string) {
      // feature coding is always 3 characters long, left padded with zeroes
      return ('000' + coding).slice(-3);
    }
    
  }

export abstract class State {

  protected _orderCode: OrderCode;

  public get orderCode(): OrderCode {
    return this._orderCode;
  }

  static ID_ITERATOR: number = 1;
  public id: number;

  protected constructor(protected stateManager: eh.QuickSelect) {
    this.id = ++State.ID_ITERATOR;
  }

}
  
export class LoaderState extends State {

  protected _templateData: IProductSegment;

  private _isLoading: boolean = false;
  private _loadingListener: () => void;
  private _latestRequest: JQuery.jqXHR;

  constructor(protected stateManager: eh.QuickSelect) {
    super(stateManager);
  }

  public observeLoading(request: JQuery.jqXHR): void {
    this._isLoading = true;
    this._latestRequest = request;
    this.stateManager.view.currentLoader = this;

    const finallyHelper = (): void => {
      if (this.isCurrentLoader(request)()) {
        this._isLoading = false;
        if (this._loadingListener) {
          this._loadingListener();
        }
      }
    };
    request
      .then(finallyHelper)
      .catch(finallyHelper);
  }

  public get templateData(): IProductSegment {
    return this._templateData;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  registerLoadingListener(l: () => void): void {
    this._loadingListener = l;
  }

  protected onResponse = (
    fn: any,
    guard: (...args: any[]) => boolean = (): boolean => { return true }
  ) => {
    return (...args: any[]): any => {
      if (guard()) {
        fn.apply(null, args);
      }
    }
  };

  public isCurrentLoader = (request: JQuery.jqXHR): () => boolean => {
    return (): boolean => {
      const sameLoader: boolean = this.stateManager.view.currentLoader === this;
      const isLatestRequest: boolean = request === this._latestRequest;
      return sameLoader && isLatestRequest
    };
  };

  public observedSelect = (options: IProductSegment): void => {
    const opt: string = options.root || options.coding || '';
    this.observeLoading(this.select(opt));
  };

  public select(option: string): JQuery.jqXHR {
    throw new Error('invalid call to abstract member');
  }

}

class InitialState extends LoaderState {

  constructor(protected stateManager: eh.QuickSelect, protected $elem: JQuery<HTMLElement>,
              private service: QuickSelectService, productRoot: string, private childProducts: ChildProduct[]) {
    super(stateManager);
  
    this._orderCode = OrderCode.createFromRoot(productRoot, this.childProducts.length > 0);
    const isLastLayer = this._orderCode.getOpenPositions() === 1;
    this._templateData = {
      coding: undefined,
      root: productRoot,
      index: 0,
      label: this.stateManager.config.labelProductPreselection,
      feature: 'UNUSED',
      featureCoding: QuickSelect.FEATURE_CODING_CHILD_PRODUCT_SELECTION,
      orderCode: this._orderCode,
      isLastLayer: isLastLayer,
      options: (childProducts.map(c => {
        return {
          coding: c.coding,
          root: c.root,
          label: c.label,
          feature: 'UNUSED',
          featureCoding: 'UNUSED',
          index: 0,
          materialNumber: c.materialNumber,
          options: undefined,
          isLastLayer: isLastLayer
        };
      }) as IProductSegment[])
    };

    if (this.childProducts.length > 0) {
      this.stateManager.storeStep(new Step(this._orderCode, this.id, null));
      this.stateManager.view.setSelection(this, 0);
    }
    else {
      const EMPTY_OPTIONS: IProductSegment  = {
        coding: '',
        feature: '',
        featureCoding: '',
        index: 0,
        isLastLayer: false,
        label: '',
        root: ''
      };
      this.observedSelect(EMPTY_OPTIONS);
    }
  }

  public select(option: string): JQuery.jqXHR {
    if (option) {
      const currentStep = this.stateManager.getCurrentStep();
      if (currentStep) {
        currentStep.selectChildProduct(this.childProducts.filter(p => p.root === option)[0]);
        currentStep.finish(option);
      }
    }
    const request: JQuery.jqXHR = this.service.call({'ordercode': this._orderCode.fillPosition(option)});
    const successHandler = (result: QuickSelectResponse, _textStatus: string, response: JQuery.jqXHR) => {
      if (response.status === 204) {
        this.stateManager.changeState(new NoopState(this.stateManager));
        this.stateManager.setDisabled();
      }
      else if (result['recommendation'] && result['recommendation'].length === 1) {
        this.stateManager.changeState(new RecommendationState(this.stateManager, this.$elem, this.service, result));
      } else if (result['product-feature']) {
        this.stateManager.changeState(new SelectionState(this.stateManager, this.$elem, this.service, result, 1));
      } else {
        this.stateManager.changeState(new ErrorState(this.stateManager, this.$elem, this.stateManager.config.globalErrorMessage));
        this.stateManager.setDisabled();
      }
    };
    const failHandler = (_response: JQuery.jqXHR) => {
      //console.log('webservice failed', _response);
      if (this.childProducts.length > 0) {
        this.stateManager.changeState(new ErrorState(this.stateManager, this.$elem, this.stateManager.config.globalErrorMessage));
      }
      else {
        // hide error if it happens on the very first request
        this.stateManager.setDisabled();
      }
    };
    request.then(
      this.onResponse(successHandler, this.isCurrentLoader(request)),
      this.onResponse(failHandler, this.isCurrentLoader(request))
    );
    return request;
  }
}

class OrderCodeCheckState extends LoaderState {

  constructor(protected stateManager: eh.QuickSelect, protected $elem: JQuery<HTMLElement>,
              private service: QuickSelectService, private productRoot: string, private childProducts: ChildProduct[]) {
    super(stateManager);
  }
  
  public check(): JQuery.jqXHR {
    const request: JQuery.jqXHR = this.service.callOrderCodeCheck(
        {'ordercodecheck': this.childProducts.map(c => c.root).join(',')});
    const successHandler = (result: QuickSelectResponse_AvailableOrderCodes, _textStatus: string, response: JQuery.jqXHR) => {
      //console.log('webservice returned result', result);
      if (response.status === 200) {
        this.stateManager.changeState(new InitialState(this.stateManager, this.$elem, this.service, this.productRoot,
            this.childProducts.filter(c => result['available-order-codes'].indexOf(c.root ?? '') !== -1)));
      } else {
        this.stateManager.changeState(new ErrorState(this.stateManager, this.$elem, this.stateManager.config.globalErrorMessage));
      }
    };
    const failHandler = (_response: JQuery.jqXHR) => {
      //console.log('webservice failed', _response);
      // hide error, it's the very first request
      this.stateManager.setDisabled();
    };
    request.then(
        this.onResponse(successHandler, this.isCurrentLoader(request)),
        this.onResponse(failHandler, this.isCurrentLoader(request))
    );
    return request;
  }
  
}

class SelectionState extends LoaderState {

  private length: number;
  private kept: number;
  
  constructor(protected stateManager: eh.QuickSelect, protected $elem: JQuery<HTMLElement>,
              private service: QuickSelectService, protected result: QuickSelectResponse, public index: number) {
    super(stateManager);

    if (!result['product-feature']) {
      throw new Error('product-feature');
    }
    const productFeature: QuickSelectResponse_ProductFeature = result['product-feature'];
    this._orderCode = OrderCode.createPartly(productFeature['order-code'], stateManager.config.productRoot);
    this.length = result.length;
    this.kept = result.kept;
    const step = new Step(this._orderCode, this.id, this.result);
    this.stateManager.storeStep(step);
    
    const isLastLayer = this._orderCode.getOpenPositions() === 1;
    this._templateData = {
      ...productFeature,
      index: this.index,
      feature: 'UNUSED',
      featureCoding: QuickSelect.ensureCodingWidth(productFeature.coding),
      orderCode: this._orderCode,
      isLastLayer: isLastLayer,
      options: productFeature.options
          .filter(option => !option.exit)
          .map((option, index) => {
        return {
          coding: option.coding,
          label: option.label,
          feature: 'UNUSED',
          featureCoding: 'UNUSED',
          isLastLayer: isLastLayer,
          index: index,
          option: QuickSelect.serialize(this._orderCode.productRoot, productFeature.coding, option.coding)
        };
      })
    };
    this.stateManager.view.setSelection(this, this.index);

  }
  
  public select(option: string): JQuery.jqXHR {
    this.stateManager.getCurrentStep()?.finish(option);
    const orderCode = OrderCode.createFromOrderCode(this._orderCode, option);
    const orderCodeString = orderCode.pattern;
    if (orderCode.getOpenPositions() === 1) { // this is the last step, we (should) already have the
      // appropriate recommendation
      const selectedRecommendation = this.result.recommendation?.filter(r => r['order-code'].pattern === orderCodeString);
      if (selectedRecommendation?.length === 1) {
        const artificialResult = $.extend(true, {}, this.result);
        artificialResult.recommendation = [selectedRecommendation[0]];
        this.stateManager.changeState(new RecommendationState(this.stateManager, this.$elem, this.service, artificialResult));
        return ($.Deferred().resolve() as unknown) as JQuery.jqXHR;
      }
    }
    const request = this.service.call({'ordercode': orderCodeString,
      'length': this.length.toString(), 'kept': this.kept.toString()});
    this.observeLoading(request);
    const successHandler = (result: QuickSelectResponse, _textStatus: string, response: JQuery.jqXHR) => {
      if (response.status === 204) {
        this.stateManager.changeState(new NoopState(this.stateManager));
      }
      else if (result['recommendation'] && result['recommendation'].length === 1) {
        this.stateManager.changeState(new RecommendationState(this.stateManager, this.$elem, this.service, result));
      }
      else if (result['product-feature']) {
        this.stateManager.changeState(new SelectionState(this.stateManager, this.$elem, this.service, result, this.index + 1));
      }
      else {
        this.stateManager.changeState(new ErrorState(this.stateManager, this.$elem, this.stateManager.config.globalErrorMessage));
      }
    };
    const failHandler: (response: JQuery.jqXHR) => void = (_response: JQuery.jqXHR) => {
      //console.log('webservice failed', _response);
      this.stateManager.changeState(new ErrorState(this.stateManager, this.$elem, this.stateManager.config.globalErrorMessage));
    };

    request.then(
      this.onResponse(successHandler, this.isCurrentLoader(request)),
      this.onResponse(failHandler, this.isCurrentLoader(request))
    );

    return request;
  }
  
}

class RecommendationState extends State {
  
  constructor(protected stateManager: eh.QuickSelect, protected $elem: JQuery<HTMLElement>,
              _service: QuickSelectService, protected result: QuickSelectResponse) {
    super(stateManager);
    if (!result['recommendation']) {
      throw new Error('recommendation');
    }
    const recommendation = result['recommendation'][0];
    const productData: QuickSelectResponse_ProductFeature[] = recommendation['product-features'];
    this._orderCode = OrderCode.create(recommendation['order-code'], stateManager.config.productRoot);
    let inc: number = (this.stateManager.getCurrentStep() as Step).id;
    const step = new Step(this._orderCode, this.id, this.result);
    this.stateManager.storeStep(step);
    
    const normalizedTemplateData: IProductSegment[] = productData.map(productFeature => {
      const orderCode = OrderCode.createPartly(productFeature['order-code'], this.stateManager.config.productRoot);
      return {
        index: ++inc,
        feature: productFeature.label,
        featureCoding: QuickSelect.ensureCodingWidth(productFeature.coding),
        label: productFeature.options[0].label,
        coding: productFeature.options[0].coding,
        orderCode: orderCode,
        isLastLayer: orderCode.getOpenPositions() === 1,
        root: '',
        options: [],
        option: QuickSelect.serialize(orderCode.productRoot, productFeature.coding, productFeature.options[0].coding)
      };
    });
    stateManager.view.setRecommendations(this, normalizedTemplateData);
  }
  
}

class ErrorState extends State {
  
  constructor(protected stateManager: eh.QuickSelect, private $elem: JQuery<HTMLElement>, msg: string) {
    super(stateManager);
    this.stateManager.view.setError(this, {message: msg});
  }
  
}

class NoopState extends State {
  
  constructor(protected stateManager: eh.QuickSelect) {
    super(stateManager);
  }
  
}
  
  /**
   * In order to get a fully configured product, several steps are required to choose configuration options.
   * Common flow:
   * 1) Step is loaded with name (featureCoding) and list of values to choose from (serivceResult)
   * 2) Selection step is finished by choosing a value (selectedOption)
   * Step might also contain one single recommendation with a list of further configuration options.
   * If a product belongs to a family, one specific child product is selected in the first step.
   */
  export class Step {
  
  private selectedOption: string | undefined;
  public readonly featureCoding: string;
  private _childProduct?: ChildProduct;
  
  constructor(
    private readonly _orderCode: OrderCode,
    public readonly id: number,
    private readonly serviceResult: QuickSelectResponse | null
  ) {
    this.featureCoding = serviceResult?.['product-feature']?.coding ?? QuickSelect.FEATURE_CODING_CHILD_PRODUCT_SELECTION;
  }
  
  public get orderCode(): OrderCode {
    return this._orderCode;
  }
  
  public get orderCodeString(): string {
    return this.selectedOption ? this._orderCode.fillPosition(this.selectedOption) : this._orderCode.pattern;
  }
  
  public selectChildProduct(childProduct: ChildProduct | undefined) {
    this._childProduct = childProduct;
  }
  
  public get childProduct(): ChildProduct | undefined {
    return this._childProduct;
  }
  
  public finish(selectedOption: string): void {
    this.selectedOption = selectedOption;
  }
  
  public serializeOptions(): string[] {
    if (this.serviceResult?.recommendation?.length === 1) {
      // exactly one recommendation means final step and might contain multiple options
      return this.serviceResult.recommendation[0]['product-features'].filter(pf => pf.options?.[0].coding)
        .map(pf => QuickSelect.serialize(this._orderCode.productRoot, pf.coding, pf.options?.[0].coding));
    }
    return this.featureCoding === QuickSelect.FEATURE_CODING_CHILD_PRODUCT_SELECTION || !this.selectedOption ? []
        : [QuickSelect.serialize(this._orderCode.productRoot, this.featureCoding, this.selectedOption)];
  }
  
}


/**
 * PRODUCTROOT-*-*-*-*
 * Options represented by *, separated by -
 * Position is one-based, family product info on pos 0 before first separator
 */
export class OrderCode {
  
  private static SEPARATOR = '-';
  private readonly _productRoot: string;
  private _productRootIncludingChild: string | undefined;
  private _segmentValue: string | undefined;
  
  private constructor(
    private readonly sourcePattern: string,
    private readonly _currentSegmentIndex: number | undefined = undefined
  ) {
    const separatorIndex = this.sourcePattern.indexOf(OrderCode.SEPARATOR);
    this._productRoot = separatorIndex === -1 ? this.sourcePattern : this.sourcePattern.substring(0, separatorIndex);
  }
  
  public static createFromRoot(productRoot: string, productHasChildren: boolean): OrderCode {
    return new OrderCode(productRoot, productHasChildren ? 0 : undefined);
  }
  
  public static create(o: QuickSelectResponse_OrderCode, productRoot: string): OrderCode {
    return new OrderCode(o.pattern);
  }
  
  public static createPartly(o: QuickSelectResponse_OrderCodePartly, productRoot: string): OrderCode {
    return new OrderCode(o.pattern, o.position);
  }
  
  public static createFromOrderCode(o: OrderCode, value: string) {
   return new OrderCode(o.fillPosition(value));
  }
  
  get pattern(): string {
    return this._segmentValue ? this.fillPosition(this._segmentValue) : this.sourcePattern;
  }
  
  get currentSegmentIndex(): number | undefined {
    return this._currentSegmentIndex;
  }
  
  get productRoot(): string {
    return this._productRootIncludingChild ?? this._productRoot;
  }
  
  get productRootChildSuffix(): string | undefined {
    return this._productRootIncludingChild ? this._productRootIncludingChild.substring(this._productRoot.length) : undefined;
  }
  
  public isFamilyProduct() {
    return this.sourcePattern.indexOf(OrderCode.SEPARATOR) === -1;
  }
  
  public fillPosition(value: string): string {
    if (this._currentSegmentIndex === undefined) {
      return this.sourcePattern;
    }
    if (this._currentSegmentIndex === 0) {
      this._productRootIncludingChild = value;
      return value;
    }
    this._segmentValue = value;
    const parts = this.sourcePattern.split(OrderCode.SEPARATOR);
    parts[this._currentSegmentIndex] = this._segmentValue;
    return parts.join(OrderCode.SEPARATOR);
  }
  
  public getOpenPositions(): number {
    return this.sourcePattern.indexOf(OrderCode.SEPARATOR) === -1 ? -1 : this.sourcePattern.match(/\*/g)?.length ?? 0;
  }
  
}

class QuickSelectService {
  
  private staticHeaders: {[headerName: string]: string} = {};
  private staticQueryParams: eh.QueryParams = {};
  
  constructor(private baseUrl: string) {
  }
  
  public withStaticHeader(name: string, value: string): QuickSelectService {
    this.staticHeaders[name] = value;
    return this;
  }
  
  public withStaticQueryParam(name: string, value: string): QuickSelectService {
    this.staticQueryParams[name] = value;
    return this;
  }
  
  public call(params: eh.QueryParams): JQuery.jqXHR {
    $.extend(params, this.staticQueryParams);
    let url = eh.URLHelper.buildUrl(this.baseUrl, eh.URLHelper.buildQueryString(params));
    //console.log('QuickSelectService.call()', params, url);
    const settings: JQuery.AjaxSettings = {
      'url': url,
      'dataType': 'json'
    };
    if (this.staticHeaders) {
      settings.headers = this.staticHeaders;
    }
    return $.ajax(settings);
  }
  
  public callOrderCodeCheck(params: eh.QueryParams): JQuery.jqXHR {
    $.extend(params, this.staticQueryParams);
    let url = eh.URLHelper.buildUrl(this.baseUrl + '/ordercodecheck', eh.URLHelper.buildQueryString(params));
    //console.log('QuickSelectService.callOrderCodeCheck()', params, url);
    const settings: JQuery.AjaxSettings = {
      'url': url,
      'dataType': 'json'
    };
    if (this.staticHeaders) {
      settings.headers = this.staticHeaders;
    }
    return $.ajax(settings);
  }
  
}

type QuickSelectConfiguration = {
  'apiKey': string,
  'childProducts': ChildProduct[],
  //'configuratorUrl': string,
  'globalErrorMessage': string,
  'labelProductPreselection': string,
  'language': string,
  'productMaterialNumber': string,
  'productRoot': string,
  'salesOrganization': string,
  'serviceUrl': string
};

type QuickSelectResponse = {
  'language': string,
  'kept': number,
  'length': number,
  'vkorg': string,
  'order-code': QuickSelectResponse_OrderCode,
  'product-feature'?: QuickSelectResponse_ProductFeature,
  'recommendation'?: QuickSelectResponse_Recommendation[]
};

type QuickSelectResponse_AvailableOrderCodes = {
  'vkorg': string,
  'available-order-codes': string[]
};

type QuickSelectResponse_OrderCode = {
  'pattern': string,
};

type QuickSelectResponse_OrderCodePartly = QuickSelectResponse_OrderCode & {
  'position': number
};

type QuickSelectResponse_ProductFeature = {
  'coding': string,
  'label': string,
  'options': QuickSelectResponse_Option[],
  'order-code': QuickSelectResponse_OrderCodePartly
};

type QuickSelectResponse_Recommendation = {
  'product-features': QuickSelectResponse_ProductFeature[],
  'order-code': QuickSelectResponse_OrderCode
};

type QuickSelectResponse_Option = {
  'coding': string,
  'label': string,
  'exit'?: boolean
};

type QuickSelectTrackingInfo = {
  orderCode: string | undefined,
  fullyConfigured: boolean,
  currentStepIndex: number,
  currentStepFeature: string | undefined
}

interface ChildProduct {
  coding: string;
  label: string;
  materialNumber: string;
  root: string;
}

export interface IProductSegment {
  coding: string | undefined;
  feature: string;
  featureCoding: string;
  index: number;
  isLastLayer: boolean;
  label: string;
  options?: IProductSegment[] | undefined;
  orderCode?: OrderCode,
  root?: string | undefined;
  option?: string | undefined;
}

}
